<template>
  <v-container class="fill-height" fluid id="containerDataTable">
    <v-row justify="center" style="height: 100%" no-gutters>
      <v-col cols="12" xs="12">
        <v-card outlined elevation="10" style="height: 100%" class="card-container">
          <!--          <v-container style="height: 100%">-->
          <v-card-title v-if="!noTitle">
            <v-row justify="space-between">
              <v-col>
                <v-toolbar-title>{{ $t(myName + "." + myName) }}</v-toolbar-title>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text style="height: 100%">
            <v-row justify="space-between" no-gutters>
              <v-col md="6">
                <mytextfield
                  v-model="search"
                  :label="$t('MyDataTable.search')"
                  append-icon="mdi-magnify"
                  :append-outer-icon="
                    btnClearFilterShow == true ? 'mdi-filter-off' : 'mdi-filter-outline'
                  "
                  @clickAppendOuter="clearFilter"
                ></mytextfield>
              </v-col>
              <!-- <v-col md="2">
                <mybtn
                  v-if="btnClearFilterShow"
                  @click="clearFilter"
                  :tooltiptext="$t('MyDataTable.clearFilter')"
                  iconname="mdi-filter-remove-outline"
                  large
                ></mybtn>
              </v-col> -->
              <v-col md="6">
                <v-row justify="end" class="mr-1">
                  <mybtn
                    class="my-3"
                    v-if="openActionMainChip"
                    :label="$t('MyDataTable.actionMainBtn')"
                    :tooltiptext="$t('MyDataTable.actionMainBtn')"
                    color="deep-orange accent-3"
                    iconname="mdi-cog-outline"
                    large
                    @click="openActionMainChip = false"
                  ></mybtn>
                  <v-chip
                    v-if="!openActionMainChip"
                    class="my-2 py-2"
                    large
                    color="blue lighten-4"
                  >
                    <div class="chip-container">
                      <span v-for="item in actionMainBtnArray" :key="item.id">
                        <mybtn
                          :label="item.textValue"
                          @click="item.method()"
                          :color="item.color"
                          :iconname="item.iconName"
                          :tooltiptext="$t(item.textValue)"
                          :disabled="item.disabled"
                          large
                        ></mybtn>
                      </span>
                      <mybtn
                        class="mt-1"
                        v-if="!openActionMainChip"
                        :label="$t('close')"
                        :tooltiptext="$t('close')"
                        color="deep-orange accent-3"
                        iconname="mdi-close-circle-outline"
                        @click="openActionMainChip = true"
                      ></mybtn>
                    </div>
                  </v-chip>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center" style="max-height: 100%" class="custom-row ma-1 pa-3">
              <v-row style="width: 100%; height: 100%">
                <aggridvue
                  style="width: 100%; height: 100%"
                  class="ag-theme-alpine"
                  :columnDefs="headers"
                  :rowData="getItems"
                  rowSelection="multiple"
                  @grid-ready="onGridReady"
                  :gridOptions="gridOptions"
                  :defaultColDef="defaultColDef"
                  @selection-changed="onSelectionChanged"
                  @filterChanged="onFilterChanged"
                  @cellDoubleClicked="onCellDoubleClicked"
                  @cellClicked="onCellClicked"
                  :modules="modules"
                  :tooltipShowDelay="tooltipShowDelay"
                  :tooltipHideDelay="tooltipHideDelay"
                >
                </aggridvue>
              </v-row>
              <v-row class="mb-2">
                {{ displaydRows }} {{ $t("MyDataTable.rows") }}
                <span v-if="selectedItem.length > 0">
                  {{ selectedRows }} {{ $t("MyDataTable.selectedRows") }}
                </span>
              </v-row>
            </v-row>

            <v-row justify="space-between" v-if="showExcelBtn">
              <v-col>
                <v-row justify="start">
                  <mybtn
                    @click="exportToExcel"
                    :tooltiptext="$t('MyDataTable.exportToExcel')"
                    iconname="mdi-microsoft-excel"
                    :disabled="btnExportToExcelDisabled"
                    v-if="showExcelBtn"
                    large
                  ></mybtn>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="end">
                  <!-- <mybtn
                    :tooltiptext="$t('MyDataTable.newItem')"
                    iconname="mdi-plus-thick"
                    @click="addNewItem"
                    v-if="showAddBtn"
                    large
                  ></mybtn> -->

                  <!-- <mybtn @click="mytest()" text="mytest"></mybtn> -->
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <mydialog v-model="dialogSave" :title="formTitle">
      <slot></slot>
    </mydialog>

    <mywarningdialog
      v-model="warningDialog"
      :text="textWarningDialog"
      @cancel="cancelWarningDialog"
      @ok="okWarningDialog"
      :cancelbutton="cancelButtonWarningDialog"
      :persistent="false"
    ></mywarningdialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
//import Helper from "../../res/js/MyHelper";
// import {AgGridVue} from "ag-grid-vue";
import { AgGridVue } from "@ag-grid-community/vue";

import XLSX from "xlsx";
// eslint-disable-next-line no-unused-vars

//import {agRichSelectCellEditor} from '@ag-grid-enterprise/rich-select';

// eslint-disable-next-line no-unused-vars
import TotalValueRenderer from "./myDataTable/totalValueRenderer.vue";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

// import {AgGridVue} from '@ag-grid-community/vue';

import cellRendererEditBtnVue from "./myDataTable/cellRendererEditBtnVue.vue";
import actionBtnRendererVue from "./myDataTable/actionBtnRenderer.vue";
// import myCustomTooltip from './myDataTable/customTooltip.vue';

export default {
  name: "MyDataTable",
  components: {
    aggridvue: AgGridVue,

    // eslint-disable-next-line vue/no-unused-components
    totalValueRenderer: TotalValueRenderer,
    // eslint-disable-next-line vue/no-unused-components
    cellRendererEditBtn: cellRendererEditBtnVue,
    // eslint-disable-next-line vue/no-unused-components
    actionBtnRenderer: actionBtnRendererVue,

    // eslint-disable-next-line vue/no-unused-components
    // CustomTooltip: myCustomTooltip,
  },
  data() {
    return {
      myName: this.name,
      dialogSave: false,
      openActionMainChip: false,
      //WarningDialog
      dialogDelete: false,
      warningDialog: false,
      cancelButtonWarningDialog: true,
      textWarningDialog: "",
      funcWarninkDialog: "",

      selectedItem: [],

      columnDefs: null,
      rowData: null,
      gridApi: [],
      columnApi: [],

      gridOptions: null,
      defaultColGroupDef: null,
      columnTypes: null,

      context: null,
      frameworkComponents: null,
      defaultColDef: null,

      btnDeleteDisabled: true,
      btnDeleteGroupDisabled: true,
      btnEditDisabled: true,
      btnExportToExcelDisabled: true,
      btnClearFilterShow: false,

      autoSizeColumnsModes: ["sizeToFit", "autoSizeAll"],
      btnAutSizeColumn: "sizeToFit",

      search: "",
      modules: AllCommunityModules,

      columnsItems: [],
      selectedColumnsItems: [],
      keyId: 0,

      displaydRows: 0,
      selectedRows: 0,

      tooltipShowDelay: 0,
      tooltipHideDelay: 20000,
    };
  },

  props: {
    name: {
      type: String,
      default: "",
    },
    savedata: {
      type: Boolean,
      default: false,
    },
    newpage: {
      type: String,
      default: "",
    },
    updatepage: {
      type: String,
      default: "",
    },
    showAddBtn: {
      type: Boolean,
      default: false,
    },
    showGroupEditBtn: {
      type: Boolean,
      default: false,
    },
    showGroupDeleteBtn: {
      type: Boolean,
      default: false,
    },
    showExcelBtn: {
      type: Boolean,
      default: false,
    },
    showEditBtn: {
      type: Boolean,
      default: false,
    },
    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    selectItemsFunName: {
      type: String,
      default: "getVueTable",
      // default: "selectItems",
    },
    btnAction: {
      type: Array,
      default: () => {
        return [];
      },
    },

    btnMainAction: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("MyDataTable.newItem")
        : this.$t("MyDataTable.editItem");
    },
    ...mapGetters({
      formActive: "Language/getFormActive",
      adjustment: "PageAdjustment/getAdjustment",
    }),
    getItems() {
      let mydata = this.$store.getters[`${this.myName}/getItems`];
      // console.log('MyDataTable getItems mydataa:', mydataa);
      return mydata;
    },

    getFields() {
      return this.$store.getters[`${this.myName}/getFields`];
    },

    getHeaderFilter() {
      return this.$store.getters[`${this.myName}/getHeaderFilter`];
    },

    editedItem() {
      return this.$store.getters[`${this.myName}/getEditedItem`];
    },

    defaultItem() {
      return this.$store.getters[`${this.myName}/getDefaultItem`];
    },

    editedIndex() {
      return this.$store.getters[`${this.myName}/getEditedIndex`];
    },

    headers() {
      // let tmpHeaders = this.$store.getters[`${this.myName}/getHeaders`];
      let tmpHeaders = this.makeAgGridHeader();

      return tmpHeaders;
    },

    btnActionInfoArray() {
      let btns = [];

      if (this.showEditBtn) {
        btns.push({
          id: btns.length + 1,
        });
      }
      if (this.showDeleteBtn) {
        btns.push({
          id: btns.length + 1,
        });
      }

      return btns;
    },

    actionMainBtnArray() {
      let btns = [];

      if (this.showAddBtn) {
        btns.push({
          id: btns.length + 1,
          textValue: "MyDataTable.newItem",
          iconName: "mdi-plus-thick",
          disabled: false,
          color: "primary",
          method: () => {
            // console.log(" add new item");
            this.addNewItem();
          },
        });
      }
      if (this.showGroupEditBtn) {
        btns.push({
          id: btns.length + 1,
          textValue: "MyDataTable.editItem",
          disabled: !!this.btnDeleteDisabled,
          iconName: "mdi-pencil",
          color: "green accent-3",
          method: () => {
            // console.log("editItem ");
            this.editItem();
          },
        });
      }
      if (this.showGroupDeleteBtn) {
        btns.push({
          id: btns.length + 1,
          textValue: "MyDataTable.deleteItem",
          disabled: this.btnDeleteGroupDisabled,
          iconName: "mdi-trash-can-outline",
          color: "deep-orange accent-3",
          method: () => {
            // console.log("deleteItem ");
            this.deleteItem();
          },
        });
      }

      if (this.btnMainAction.length > 0) {
        for (let i = 0; i < this.btnMainAction.length; i++) {
          let myMetod;
          if (typeof this.btnMainAction[i].method == "string") {
            myMetod = eval(this.btnMainAction[i].method);
          } else {
            myMetod = this.btnMainAction[i].method;
          }
          btns.push({
            id: btns.length + 1,
            iconName: this.btnMainAction[i].iconName,
            color: this.btnMainAction[i].color,
            disabled: this.btnMainAction[i].disabled,
            textValue: this.btnMainAction[i].textValue,
            // method: this.btnMainAction[i].method,
            method: myMetod,
          });
        }
      }

      btns.push({
        id: btns.length + 1,
        textValue: this.btnAutSizeColumn,
        iconName: "mdi-arrow-split-vertical",
        disabled: false,
        color: "primary",
        method: () => {
          this.autoSizeColumns();
        },
      });

      return btns;
    },
  },

  beforeMount() {
    this.gridOptions = {
      sideBar: true,
    };

    this.context = { componentParent: this };

    this.defaultColDef = {
      editable: false,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      flex: 1,
      width: 120,
      minWidth: 120,
      suppressSizeToFit: false,
      // tooltipComponent: 'CustomTooltip',
      filterParams: {
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.getItems.length === 0) {
        // this.getItemsFromServer();
      }
      this.getSelectedColumnsItemsFromPageAdjustment();
      this.displaydRows = this.getItems.length;
    },

    setWarningDialog(
      funcWarninkDialog,
      textWarningDialog = "",
      cancelButtonWarningDialog = true
    ) {
      this.funcWarninkDialog = funcWarninkDialog;
      this.textWarningDialog = textWarningDialog;
      this.cancelButtonWarningDialog = cancelButtonWarningDialog;
      this.warningDialog = true;
    },

    okWarningDialog() {
      this[this.funcWarninkDialog]();
    },

    cancelWarningDialog() {
      this.cancelButtonWarningDialog = false;
      this.textWarningDialog = "";
      this.funcWarninkDialog = "";
      this.close();
    },

    makeAgGridHeader() {
      let tableName = this.myName;
      let headerField = this.getFields;

      let headerFilter = this.getHeaderFilter;

      if (typeof headerFilter != "undefined") {
        // let isDeveloper = Helper.isDeveloper();
        let isDeveloper = this.isDeveloper();
        let withId = isDeveloper;
        var filterDateParams = {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            let dateAsString = cellValue;
            if (dateAsString == null && dateAsString == "") return -1;
            let dateParts = dateAsString.split("-");
            let cellDate = new Date(
              Number(dateParts[0]), //year
              Number(dateParts[1]) - 1, //month
              Number(dateParts[2]) //day
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          browserDatePicker: true,
          minValidYear: 1950,
        };

        let headerFilterItems = [
          "agNumberColumnFilter",
          "agTextColumnFilter",
          "agDateColumnFilter",
          // "agSetColumnFilter",
        ];
        let header = [];
        header.push({
          headerName: this.$t("row"),
          field: "row",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: "agNumberColumnFilter",
          pinned: "left",
          suppressMovable: true,
          cellClass: "suppress-movable-col",

          tooltipField: "row",
          headerTooltip: this.$t("row"),
        });

        for (let i = 0; i < headerField.length; i++) {
          let tempHeaderName = this.$t(`${tableName}.${headerField[i]}`);

          if (tempHeaderName.indexOf(tableName + ".") >= 0) {
            tempHeaderName = headerField[i];
          }

          if (headerField[i].toLowerCase() !== "id") {
            if (headerFilterItems[headerFilter[i]] === "agDateColumnFilter") {
              header.push({
                headerName: tempHeaderName,
                // field: headerField[i],
                filter: headerFilterItems[headerFilter[i]],
                filterParams: filterDateParams,
                cellRenderer: (params) => {
                  if (params.value !== "" && params.value !== null)
                    return this.$moment(params.value).format("DD.MM.YYYY");
                  // return Helper.setDate(params.value, ".", "dd-mm-yyyy");
                  else return "";
                },
                editable: isDeveloper,
                headerTooltip: tempHeaderName,
                tooltipField: headerField[i],
                valueGetter: (params) => {
                  if (headerFilterItems[headerFilter[i]] == "agNumberColumnFilter") {
                    return +params.data[headerField[i]];
                  }
                  return params.data[headerField[i]];
                  // return 'dd';
                },
                valueSetter: (params) => {
                  params.data[headerField[i]] = params.newValue;

                  // console.log(" headerField[i]", headerField[i]);
                  // console.log(" tessss", params.newValue, " <> ", params);
                  this.editOneItem(params.data.id, headerField[i], params.newValue);
                  // return true;
                  return params.data[headerField[i]];
                },
              });
            } else {
              header.push({
                headerName: tempHeaderName,
                // field: headerField[i],
                filter: headerFilterItems[headerFilter[i]],
                editable: isDeveloper,
                tooltipField: headerField[i],
                headerTooltip: tempHeaderName,

                valueGetter: (params) => {
                  // return params.data.name;
                  // console.log(' params :' , params.data);
                  // let hF = headerField[i];
                  // console.log('params.data[headerField[i]] ', params.data[headerField[i]]);
                  // if(params.data[headerField[i]] == 'asd'){
                  //   return 'dana';
                  // }
                  if (headerFilterItems[headerFilter[i]] == "agNumberColumnFilter") {
                    return +params.data[headerField[i]];
                  }
                  return params.data[headerField[i]];
                  // return 'dd';
                },
                valueSetter: (params) => {
                  params.data[headerField[i]] = params.newValue;

                  console.log(" headerField[i]", headerField[i]);
                  console.log(" tessss", params.newValue, " <> ", params);
                  this.editOneItem(params.data.id, headerField[i], params.newValue);
                  // return true;
                  return params.data[headerField[i]];
                },
                // valueParser: (params) => Number(params.newValue),
                //cellRenderer: "cellRendererEditBtn",

                // cellRenderer: 'genderCellRenderer',
                // cellEditor: 'agRichSelectCellEditor',
                // cellEditorParams: {
                //   cellRenderer: 'genderCellRenderer',
                //   values: ['Male', 'Female'],
                // },
              });
            }
          } else if (withId) {
            header.push({
              headerName: tempHeaderName,
              field: headerField[i],
              filter: headerFilterItems[headerFilter[i]],
              pinned: "left",
              editable: false,
            });
          }
        }

        if (this.btnActionInfoArray.length > 0) {
          header.push({
            headerName: this.$t(`MyDataTable.action`),
            headerTooltip: this.$t(`MyDataTable.action`),
            field: "actionss",
            filter: false,
            editable: false,
            pinned: "right",
            cellRendererFramework: "actionBtnRenderer",
            cellRendererParams: (params) => {
              // console.log(" action btn");
              return this.cellRendererBtnActionInfoArray(params);
            },
          });
        }

        return header;
      }
      return [];
    },

    cellRendererBtnActionInfoArray(params) {
      let btns = [];
      if (this.showEditBtn) {
        btns.push({
          id: btns.length + 1,
          iconName: "mdi-pencil",
          color: "green accent-3",
          textValue: `MyDataTable.editItem`,
          method: () => {
            // console.log(" edit test", params);
            this.editItem(params.data.id);
          },
        });
      }
      if (this.showDeleteBtn) {
        btns.push({
          id: btns.length + 1,
          iconName: "mdi-trash-can-outline",
          color: "deep-orange accent-3",
          textValue: `MyDataTable.deleteItem`,
          tooltipText: `MyDataTable.deleteItem`,
          method: () => {
            this.deleteItem(params.data.id);
          },
        });
      }

      if (this.btnAction.length > 0) {
        for (let i = 0; i < this.btnAction.length; i++) {
          let myMetod;
          if (typeof this.btnAction[i].method == "string") {
            myMetod = eval(this.btnAction[i].method);
          } else {
            myMetod = this.btnAction[i].method;
          }
          btns.push({
            id: btns.length + 1,
            iconName: this.btnAction[i].iconName,
            color: this.btnAction[i].color,
            textValue: this.btnAction[i].textValue,
            // method: this.btnAction[i].method,
            method: myMetod,
          });
        }
      }

      let res = {
        value: "action",
        iconName: "mdi-cog-outline",
        color: "deep-orange accent-3",
        data: params.data,
        myName: this.myName,
        // tooltipText: "Aktionn",
        // btnsInfoArray: this.btnActionInfoArray,
        btnsInfoArray: btns,
      };

      return res;
    },

    getItemsFromServer() {
      this.$store.dispatch(`${this.myName}/${this.selectItemsFunName}`);
    },

    addNewItem() {
      this.close();
      this.$store.dispatch(`${this.myName}/setEditedItem`, this.defaultItem);
      if (this.newpage === "") {
        this.dialogSave = true;
      } else {
        // this.gotoPage(this.newpage);
        this.goToPage(this.newpage);
      }
    },

    setEditedIndex(myId = -1) {
      this.$store.dispatch(`${this.myName}/setEditedIndex`, parseInt(myId));
    },

    editItem(myId = -1) {
      let item;
      if (myId === -1) {
        item = this.selectedItem[0];
      } else {
        item = this.getItems.find(({ id }) => id === myId);
      }
      this.setEditedIndex(parseInt(item.id));
      this.$store.dispatch(
        `${this.myName}/setEditedItem`,
        JSON.parse(JSON.stringify(item))
      );
      if (this.updatepage === "") {
        this.dialogSave = true;
      } else {
        // this.gotoPage(this.updatepage);
        this.goToPage(this.updatepage);
      }
    },

    editOneItem(id, column, value) {
      console.log("editOneItem ");
      let editedItem = {};
      editedItem.id = id;
      editedItem[column] = value;
      this.$store
        .dispatch(`${this.myName}/saveItem`, editedItem)
        .then((res) => {
          console.log("ExamTypesave Submit: ", res);
          this.$store.dispatch(`${this.myName}/selectItems`);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async deleteItem(myId = -1) {
      let textWarning = "";
      let tempId = -1;
      if (myId === -1) {
        let countSelected = this.selectedItem.length;
        console.log(" countSelected : ", countSelected);
        if (countSelected > 1) {
          let ids = this.selectedItem.map((a) => a.id);
          tempId = ids;
          textWarning = this.$t("MyDataTable.warningDeleteGroupText").replace(
            "${0}",
            countSelected
          );
        }
      } else if (myId >= 0) {
        textWarning = this.$t("MyDataTable.warningDeleteText");
        tempId = myId;
      }

      let deleteConfirm = await this.myConfirm(textWarning);
      if (deleteConfirm) {
        if (tempId != -1) {
          this.$store
            .dispatch(`${this.myName}/deleteItem`, tempId)
            .then(() => {
              this.refreshItems();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },

    refreshItems() {
      this.getItemsFromServer();
      this.close();
    },

    closeDialogAfterUpdate() {
      if (this.editedIndex >= 0) {
        this.close();
      }
    },

    close() {
      this.dialogSave = false;
      this.dialogDelete = false;
      this.gridApi.deselectAll();
      this.onSelectionChanged();
      // this.getItems = this.$store.getters[`${this.myName}/getItems`];
      this.$nextTick(() => {
        this.$store.dispatch(`${this.myName}/setEditedItem`, this.defaultItem);
        this.setEditedIndex(-1);
        // this.$store.dispatch(`${this.myName}/setEditedIndex`, -1);
      });
    },

    // gotoPage(path) {
    //   this.$router.push({ path: path });
    // },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    onSelectionChanged() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      // console.log('gridApi ',this.columnApi.getAllDisplayedColumns());
      this.selectedItem = selectedNodes.map((node) => node.data);
      if (this.selectedItem.length === 1) {
        this.btnDeleteGroupDisabled = true;
        this.btnDeleteDisabled = false;
        this.btnEditDisabled = false;
        this.btnExportToExcelDisabled = false;
      } else if (this.selectedItem.length > 1) {
        this.btnDeleteGroupDisabled = false;
        this.btnDeleteDisabled = true;
        this.btnEditDisabled = true;
        this.btnExportToExcelDisabled = false;
      } else {
        this.btnDeleteGroupDisabled = true;
        this.btnDeleteDisabled = true;
        this.btnEditDisabled = true;
        this.btnExportToExcelDisabled = true;
      }
    },

    clearFilter() {
      this.gridOptions.api.setFilterModel(null);
      this.search = "";
      this.onFilterChanged();
    },

    exportToExcel() {
      let objToExport = this.getAllDisplayedColumns();
      // Find max Width of Columns
      const jsonKeys = Object.keys(objToExport[0]);
      let objectMaxLength = [];
      for (let i = 0; i < objToExport.length; i++) {
        let value = objToExport[i];
        for (let j = 0; j < jsonKeys.length; j++) {
          if (typeof value[jsonKeys[j]] == "number") {
            objectMaxLength[j] = 10;
          } else {
            const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;
            objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
          }
        }
        let key = jsonKeys;
        for (let j = 0; j < key.length; j++) {
          objectMaxLength[j] =
            objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length;
        }
      }
      const wscols = objectMaxLength.map((w) => {
        return { width: w };
      });
      // Create a new Worksheet
      let binaryWS = XLSX.utils.json_to_sheet(objToExport);
      binaryWS["!cols"] = wscols;
      // Create a new Workbook
      let wb = XLSX.utils.book_new();
      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, this.myName);
      // export your excel
      XLSX.writeFile(
        wb,
        this.myName + this.$moment().format("YYYY-MM-DD HH-mm") + ".xlsx",
        // this.myName +
        //   Helper.setDate("", "-") +
        //   " " +
        //   Helper.getTimeFormat("", "-") +
        //   ".xlsx",
        {
          bookType: "xlsx",
          type: "buffer",
        }
      );
    },

    onCellClicked(event) {
      // console.log("onCellClicked : ", event);
      this.setEditedIndex(event.data.id);
      // console.log(' evetn ', event);
      if (event.colDef.headerName.toLowerCase() === "edit") {
        this.editItem(event.data.id);
        // console.log(" edit edit ");
      }
      if (event.colDef.headerName.toLowerCase() === "delete") {
        console.log(" event.data.id ", event.data.id);
        this.deleteItem(event.data.id);
        // console.log(" delete delete  ", event.data.id);
      }
    },

    onCellDoubleClicked(event) {
      // console.log('test this.gridOptions ', this.gridOptions);
      // console.log(' on cell doubleClick gridoption.coumnsdef: ', this.gridOptions.columnDefs);
      // console.log(' on cell doubleClick gridoption.coumnsdef: ', this.columnsItems);

      if (event.colDef.field === "row") {
        let objToExport = this.getAllDisplayedColumns();
        this.copyToClipboard(JSON.stringify(objToExport));
      } else {
        this.copyToClipboard(event.value);
      }
    },

    getAllDisplayedColumns() {
      let allDisplayedColumns = this.columnApi.getAllDisplayedColumns();

      if (this.selectedItem.length > 0) {
        let objToExport = this.selectedItem.map((obj) => {
          let rObj = {};
          for (let i = 0; i < allDisplayedColumns.length; i++) {
            rObj[allDisplayedColumns[i].colDef.headerName] =
              this.isEmpty(obj[allDisplayedColumns[i].colId]) == false
                ? obj[allDisplayedColumns[i].colId]
                : obj[allDisplayedColumns[i].colDef.tooltipField];

            // obj[allDisplayedColumns[i].colId];
          }
          return rObj;
        });
        return objToExport;
      } else {
        return {};
      }
    },

    onFilterChanged() {
      if (this.getItems.length > 0) {
        this.btnClearFilterShow = !(
          this.gridApi.getFilterModel() &&
          Object.keys(this.gridApi.getFilterModel()).length === 0 &&
          this.gridApi.getFilterModel().constructor === Object &&
          (this.search === "" || this.search === null)
        );
      } else {
        this.btnClearFilterShow = false;
      }
      if (this.btnClearFilterShow) {
        this.displaydRows = this.gridOptions.api.getDisplayedRowCount();
      } else {
        this.displaydRows = this.getItems.length;
      }
    },

    autoSizeColumns() {
      let indexMode = this.autoSizeColumnsModes.indexOf(this.btnAutSizeColumn) + 1;
      let mode = this.autoSizeColumnsModes[indexMode % this.autoSizeColumnsModes.length];
      this.btnAutSizeColumn = mode;
      if (mode === "sizeToFit") {
        this.gridOptions.api.sizeColumnsToFit();
      } else if (mode === "autoSizeAll") {
        this.autoSizeAllColumns(false);
      } else {
        this.autoSizeAllColumns(true);
      }
    },

    autoSizeAllColumns(skipHeader) {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    },

    setColumn(columns) {
      // console.log('myDataTable  setColumn  culumn', columns);
      // console.log('myDataTable  setColumn typeof culumn', typeof columns);
      let myColumns = [];
      if (typeof this.gridOptions.columnDefs != "undefined") {
        let temp = this.gridOptions.columnDefs;
        // console.log(" temp1 ", temp);
        // console.log("length temp ", temp.length);
        if (temp.length > 0) {
          for (let i = 0; i < columns.length; i++) {
            let find = temp.find(function (tmp) {
              if (tmp.field === columns[i].field) {
                return tmp;
              }
            });
            myColumns.push(find);
          }
          console.log(" temp2", myColumns);
          console.log(" gettype ", typeof myColumns);

          this.gridOptions.api.setColumnDefs(myColumns);
        }
      }
    },

    getColumnsItems() {
      let tmpColumnsItems = [];
      let tmpSelectedColumnsItems = [];
      if (typeof this.gridOptions.columnDefs != "undefined") {
        let temp = this.gridOptions.columnDefs;
        for (let i = 0; i < temp.length; i++) {
          tmpColumnsItems.push({
            id: i,
            text: temp[i].headerName,
            field: temp[i].field,
          });
        }
        // tmpColumnsItems = this.gridOptions.columnDefs;
      }
      this.columnsItems = tmpColumnsItems;

      if (typeof this.columnApi.getAllDisplayedColumns() != "undefined") {
        let temp = this.columnApi.getAllDisplayedColumns();
        for (let i = 0; i < temp.length; i++) {
          let find = tmpColumnsItems.find(function (tmp) {
            if (tmp.field == temp[i].colId) {
              return tmp;
            }
          });
          tmpSelectedColumnsItems.push(find);
        }
      }
      // console.log(' columnsItems: ', this.columnApi.getAllDisplayedColumns());
      this.selectedColumnsItems = tmpSelectedColumnsItems;
    },

    insertToPageAdjustment(myAdjustment) {
      let item = {
        // myFunction: 'saveAdjustment',
        page: this.name,
        adjustment: JSON.stringify(myAdjustment),
      };
      this.$store
        .dispatch("PageAdjustment/saveAdjustment", item)
        .then((res) => {
          console.log(" res page saveAdjustment", res);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getSelectedColumnsItemsFromPageAdjustment() {
      // console.log(' tet  tet');
      this.$store.dispatch("PageAdjustment/selectAllAdjustmentByUser");

      // let item = {
      //   myFunction: 'selectByUser',
      //   page: this.name,
      // };
      // this.$store.dispatch('PageAdjustment/myFunction',item)
      //   .then((res) => {
      //
      //     console.log(' res page saveAdjustment',res);
      //      this.selectedColumnsItems = res[0].adjustment;
      //
      //     console.log(' res page his.selectedColumnsItems',this.selectedColumnsItems);
      //     console.log(' get type', typeof this.selectedColumnsItems);
      //      this.setColumn(this.selectedColumnsItems)
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });
    },

    setPageAdjustment() {
      for (let i = 0; i < this.adjustment.length; i++) {
        if (this.adjustment[i].page == this.name) {
          // console.log(
          //   "show adjustment of this page",
          //   this.adjustment[i].adjustment
          // );
          // this.setColumn(JSON.parse(this.adjustment[i].adjustment));
        }
      }
    },

    mytest() {
      console.log(" mytest ");
      // let data = {
      //   myFunction: 'test',
      //   myTest : 'tt',
      // }
      this.$store
        // .dispatch(`${this.myName}/myFunction`, data)
        .dispatch(`${this.myName}/getVueTable`)
        .then((res) => {
          console.log(" res test :", res);
          // this.refreshItems();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },

  watch: {
    dialogSave(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.close();
    },

    savedata() {
      this.closeDialogAfterUpdate();
    },

    search() {
      //this.onFilterChanged();
      this.gridOptions.api.setQuickFilter(this.search);
    },

    selectedColumnsItems() {
      // console.log(' selectedColumnsItems :>', this.selectedColumnsItems);
      this.setColumn(this.selectedColumnsItems);
      //todo insert to dateb bank
      this.getSelectedColumnsItemsFromPageAdjustment();
      this.insertToPageAdjustment(this.selectedColumnsItems);
    },

    adjustment() {
      // console.log(" mapgetter in watch adjustmnt", this.adjustment);
      this.setPageAdjustment();
    },

    "gridOptions.columnDefs"() {
      // console.log('Watch gridOptions.columnDefs', this.gridOptions.columnDefs);
      this.setPageAdjustment();
    },

    // editedIndex() {
    //   console.log('editedindex in myTable:', this.editedIndex)
    // },

    selectedItem() {
      this.selectedRows = this.selectedItem.length;
    },

    getItems() {
      this.displaydRows = this.getItems.length;
    },
  },
};

// access:
// excell
// select
// add
// edit
// delete
// deleteGsroupe
</script>

<style scoped lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

// .custom-date-filter a {
//   position: absolute;
//   right: 20px;
//   color: rgba(0, 0, 0, 0.54);
//   cursor: pointer;
// }

// .custom-date-filter:after {
//   position: absolute;
//   content: "\f073";
//   display: block;
//   font-weight: 400;
//   font-family: "Font Awesome 5 Free";
//   right: 5px;
//   pointer-events: none;
//   color: rgba(0, 0, 0, 0.54);
// }

// #myGrid {
//   height: 100%;
// }

.chip-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
}

.card-container {
  display: flex;
  flex-direction: column;
}

.footer-row {
  margin-top: auto; /* Push the footer row to the bottom */
}

.custom-row {
  height: calc(
    100vh - 160px - 62px - 66px - 44px
  ); /* Calculate height based on viewport height minus header and footer height */
  overflow: hidden; /* Hide overflow content */
}
</style>

<!--https://github.com/ag-grid/ag-grid-vue-example/blob/master/src/rich-grid-example/RichGridExample.vue-->
