<template>
  <v-row class="my-0 py-0" justify="space-between">
    <v-col cols="auto"   class="my-1 py-0">
      <mybtn
        @click="clear"
        :label="cleartext"
        :tooltiptext="cleartooltiptext"
        color="deep-orange accent-3"
      ></mybtn>
    </v-col>
    <v-col  cols="auto"   align="end"  class="my-1 py-0">
      <mybtn
        :disabled="disabled"
        @click="submit"
        :label="savetext"
        :tooltiptext="savetooltiptext"
        color="green lighten-1"
      ></mybtn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "mySaveBtn",
    data() {
      return {

      }
    },
    props: {
      disabled:{
        type: Boolean,
        default: true,
      },
      savetext:{
        type: String,
        default() {
          return this.$t('save');
        },
      },
      savetooltiptext:{
        type: String,
        default() {
          return this.$t('save');
        },
      },
      cleartext:{
        type: String,
        default() {
          return this.$t('reset');
        },
      },
      cleartooltiptext:{
        type: String,
        default() {
          return this.$t('reset');
        },
      },

    },
    methods: {
      submit() {
        this.$emit("submit");
      },
      clear() {
        this.$emit("clear");
      },
    },

  }
</script>

<style scoped>

</style>