<template>
  <span></span>
  <!-- <div></div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MyAlert",
  data() {
    return {
      snackbar: false,
    };
  },

  props: {},
  computed: {
    ...mapGetters({
      alertShow: "MyAlert/getAlertShow",
      text: "MyAlert/getText",
      type: "MyAlert/getType",
      color: "MyAlert/getColor",
      timeout: "MyAlert/getTimeout",
    }),
  },
  methods: {},

  watch: {
    text() {
      // this.openAlert(this.alertShow);
      if (this.text != "") {
        if (this.type == "success") {
          this.$toast.success(this.text, {
            timeout: this.timeout,
          });
        } else if (this.type == "error") {
          this.$toast.error(this.text, {
            timeout: this.timeout,
          });
        } else if (this.type == "warning") {
          this.$toast.warning(this.text, {
            timeout: this.timeout,
          });
        } else {
          this.$toast.info(this.text, {
            timeout: this.timeout,
          });
        }
      }
      let alert = {
        text: "",
        color: "red",
        timeout: -1,
        alertShow: true,
      };
      this.$store.dispatch("MyAlert/setAlert", alert);
      // this.$toast.success(this.text, {
      //   timeout: 10000,
      // });
    },
  },
};
</script>

<style scoped></style>
