<template>
  <v-card>
    <v-card outlined elevation="10" style="height: 100%">
      <v-card-title>
        <v-row justify="space-between">test titel </v-row>
      </v-card-title>
      <v-card-text justify="center">
        <v-row justify="center">
          <v-tabs v-model="tab1" class="mx-5">
            <v-tabs-slider color="red"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item.id" @click="test">
              {{ $t(myName + "." + item.name) }}
              <!-- {{ item.name }} -->
            </v-tab>
          </v-tabs>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs-items v-model="tab1">
      <v-tab-item v-for="item in items" :key="item.id">
        <!-- settings -->

        <v-card v-if="item.id == 1" flat>
          <v-card-text>
            <v-card outlined elevation="10" style="height: 100%">
              <v-card-title>
                <v-row justify="space-between">{{ $t(myName + ".settings") }}</v-row>
              </v-card-title>
              <v-card-text justify="center">
                <v-row justify="center">
                  <v-tabs v-model="tab2" class="mx-5">
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab v-for="settingsItem in settingsItems" :key="settingsItem.id">
                      {{ $t(myName + "." + settingsItem.name) }}
                      <!-- {{ settingsItem.name }} -->
                    </v-tab>
                  </v-tabs>
                </v-row>
              </v-card-text>
            </v-card>

            <v-tabs-items v-model="tab2">
              <v-tab-item v-for="settingsItem in settingsItems" :key="settingsItem.id">
                <!-- settings -->
                <!-- add language -->
                <v-card v-if="settingsItem.id == 1" flat>
                  <v-card-text>
                    <v-row justify="center" style="height: 100%" no-gutters>
                      <v-col cols="12" xs="12">
                        <v-card outlined elevation="10" style="height: 100%">
                          <v-card-title>
                            <v-row justify="space-between">
                              <v-col>
                                <v-toolbar-title>{{
                                  $t(myName + "." + settingsItem.name)
                                }}</v-toolbar-title>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text style="height: 100%">
                            <v-row>
                              <v-col md="4">
                                <mytextfield
                                  v-model="language"
                                  label="language"
                                ></mytextfield>
                                <!-- :label="$t(myName + '.postCode')" -->
                              </v-col>
                            </v-row>
                            <v-row justify="space-between" style>
                              <v-col md="4">
                                <mytextarea
                                  v-model="jsonLanguage"
                                  append-icon="mdi-magnify"
                                  label="Language"
                                  single-line
                                  hide-details
                                  clearable
                                  outlined
                                ></mytextarea>
                              </v-col>
                              <v-col md="2"> </v-col>
                              <v-col md="2" class="ml-auto"> </v-col>
                            </v-row>

                            <v-row justify="space-between" style>
                              <v-col>
                                <v-row justify="start">
                                  <mybtn
                                    @click="uploadLanguage"
                                    :tooltiptext="$t(myName + '.uploadLanguage')"
                                    iconname="mdi-upload"
                                    :disabled="false"
                                    large
                                  ></mybtn>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- get language -->
                <v-card v-if="settingsItem.id == 2" flat>
                  <v-card-text>
                    <v-row justify="center" style="height: 100%" no-gutters>
                      <v-col cols="12" xs="12">
                        <v-card outlined elevation="10" style="height: 100%">
                          <v-card-title>
                            <v-row justify="space-between">
                              <v-col>
                                <v-toolbar-title
                                  >{{ $t(myName + "." + settingsItem.name) }}
                                </v-toolbar-title>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text style="height: 100%">
                            <v-row>
                              <v-col md="4">
                                <v-select
                                  v-model="languageTitel"
                                  :items="languageTitels"
                                  item-text="title"
                                  item-value="value"
                                  :label="$t('language_picker_helper')"
                                  outlined
                                  @change="changeLanguage(languageTitel)"
                                ></v-select>

                                <!-- :label="$t(myName + '.postCode')" -->
                              </v-col>
                            </v-row>
                            <v-row justify="space-between" style>
                              <v-col>
                                <v-row justify="start">
                                  <mybtn
                                    @click="downloadLanguage"
                                    :tooltiptext="$t(myName + '.uploadLanguage')"
                                    iconname="mdi-download"
                                    :disabled="false"
                                    large
                                  ></mybtn>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- delete language  -->
                <v-card v-if="settingsItem.id == 3" flat>
                  <v-card-text>
                    <v-row justify="center" style="height: 100%" no-gutters>
                      <v-col cols="12" xs="12">
                        <v-card outlined elevation="10" style="height: 100%">
                          <v-card-title>
                            <v-row justify="space-between">
                              <v-col>
                                <v-toolbar-title
                                  >{{ $t(myName + "." + settingsItem.name) }}
                                </v-toolbar-title>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text style="height: 100%">
                            <v-row>
                              <v-col md="4">
                                <v-combobox
                                  v-model="languageTitel"
                                  :items="languageTitels"
                                  :label="$t('language_picker_helper')"
                                  item-text="title"
                                  item-value="value"
                                  outlined
                                  clearable
                                  dense
                                  @change="changeLanguage(languageTitel)"
                                ></v-combobox>

                                <v-checkbox
                                  v-model="chbCompleteDeleteLanguage"
                                  :label="$t(myName + '.chbCompleteDeleteLanguage')"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            <v-row justify="space-between" style>
                              <v-col>
                                <v-row justify="start">
                                  <mybtn
                                    @click="deleteLanguage"
                                    :tooltiptext="$t(myName + '.uploadLanguage')"
                                    iconname="mdi-delete"
                                    color="red"
                                    :disabled="false"
                                    large
                                  ></mybtn>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <!-- table -->
        <v-card v-if="item.id == 2" flat>
          <v-card-text>
            <mydatatable
              :name="myName"
              :savedata="saveData"
              show-add-btn
              show-edit-btn
              show-delete-btn
              show-excel-btn
            >
              <languagesave @change="save"></languagesave>
            </mydatatable>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import languagesave from "./languageSave.vue";
// import savedialog from '../users/usersSave'
export default {
  name: "Language",
  meta: {
    requiresAuth: true,
    is_developer: true,
  },

  components: {
    languagesave,
    // savedialog,
  },

  data() {
    return {
      myName: "Language",
      saveData: false,

      tab1: null,
      items: [
        { id: 1, name: "settings" },
        { id: 2, name: "table" },
      ],
      tab2: null,
      settingsItems: [
        { id: 1, name: "add" },
        { id: 2, name: "get" },
        { id: 3, name: "delete" },
      ],

      jsonLanguage: "",
      language: "",
      languageTitel: "",
      chbCompleteDeleteLanguage: false,
    };
  },

  computed: {
    ...mapGetters({
      languageTitels: "Language/getLanguagesTitel",
      formActive: "Language/getFormActive",
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch(`${this.myName}/getVueTable`);
    },

    save() {
      this.saveData = !this.saveData;
    },

    uploadLanguage() {
      console.log(" uploadLanguage");
      // console.log(" uploadLanguage", JSON.parse(JSON.stringify(this.jsonLanguage)));

      let params = new Function(
        `return JSON.parse(JSON.stringify(${this.jsonLanguage}));`
      )();
      console.log(" params", params);
      // console.log(" uploadLanguage", (JSON.stringify(this.jsonLanguage)));
      // this.jsonLanguage = params;
      let data = {
        myFunctionName: "setLanguageInDB",
        language: this.language,
        jsonLanguage: JSON.stringify(params),
      };
      this.$store.dispatch(`${this.myName}/myFunction`, data).then((res) => {
        console.log("setLanguageInDB resss: ", res);
      });
    },

    downloadLanguage() {
      console.log(" downloadLanguage");
      this.$store
        .dispatch(`${this.myName}/myGetLanguageFile`, this.languageTitel)
        .then((res) => {
          console.log(" download my get lang : ", res);
          const textContent = JSON.stringify(res.data, null, 2);
          const fileName = "language " + this.languageTitel + ".txt";
          this.downloadTextFile(textContent, fileName);
        });
    },

    downloadTextFile(content, fileName) {
      const element = document.createElement("a");
      const file = new Blob([content], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element); // Optional, but helps with compatibility
      element.click();
      document.body.removeChild(element); // Optional, clean up the element
    },

    deleteLanguage() {
      let data = {
        myFunctionName: "deleteLanguage",
        language: this.languageTitel,
        completeDeleteLanguage: this.chbCompleteDeleteLanguage,
        // jsonLanguage: JSON.stringify(params),
      };
      this.$store.dispatch(`${this.myName}/myFunction`, data).then((res) => {
        console.log("deleteLanguage resss: ", res);
      });
    },

    changeLanguage(selected) {
      console.log(" selected :> ", selected);
    },

    test() {
      console.log(" test ");
    },
  },
};
</script>

<style scoped></style>
