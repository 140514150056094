import Api from './API'
import Helper from "../../res/js/MyHelper.js";
import store from "../../store/index.js";
import router from '../../res/router';


export default {

  send(credentials) {//  '@/server/command.php' 
    return new Promise((resolve, reject) => {
      const formData = credentials;

      // console.log('send Post to php command', formData.get('command'));

      formData.append('Authorization', localStorage.getItem('token'));
      if (formData.get('captcha') === 'true') {
        formData.append('captchaCode', store.getters['captcha/getCaptchaCode']);
        formData.append('captchaEncrypt', store.getters['captcha/getCaptchaEncrypt']);
      }
      let development = false;
      if (process.env.NODE_ENV === "development") {
        formData.append('development', 'true');

        // formData.append('development', 'false'); 
        development = true;
      }

      // return Api().post('/index.php', formData,{ withCredentials: true }) 
      return Api().post('/index.php', formData,)
        .then(res => {

          // console.log('get sPost to command ' ,  formData.get('command') ,   '  res.data', res);
          // console.log('send Post to php server', res.data.Authorization);
          if (res.data.Authorization != undefined) {
            if (res.data.Authorization.isLogin) {
              delete res.data.Authorization.userObject;
              store.dispatch('Login/setLocalStorage', res.data.Authorization);
              let doFunc = () => {
                store.dispatch('Login/logout');

                if (development) {
                  console.log(' goooooo to page register ');
                } else {
                  Helper.goToPage('register');
                }
                // store.dispatch("Login/logout");
                // Helper.goToPage('login');
              }
              Helper.setIntervalJob('logout', +localStorage.getItem('expirationPeriod'), doFunc);
            }
          } else {
            console.log(' current path : ', router.history.current.path);

            // Helper.goToPage('login');

            // Helper.goToPage('register');
          }

          if (res.data.msg.msg != '') {
            // console.log(' res.data.msg  ', res.data.msg );
            store.dispatch('MyAlert/setShowAlert', false);
            let alert = {
              text: res.data.msg.msg,
              type: res.data.msg.type,
              color: 'red',
              timeout: 5000,
              alertShow: true,
            }
            store.dispatch('MyAlert/setAlert', alert);
          }

          // console.log('send Post to php server type', typeof resp.data); 
          // if (resp.status === 200) { 
          //   if(typeof resp.data == 'object'){ 
          //     if (resp.data.type == 'success') { 
          //       // console.log('sucsses message:', resp.data.message); 
          //     } 
          //   } 
          //   if (formData.get('command') === 'updateTelcMember' ) 
          //     // console.log('post to php server: ', formData.get('command') , resp); 
          // 
          //   resolve(resp); 
          // } else { 
          //   reject('error Status: ' + resp.status); 
          // } 

          // resolve(JSON.parse(res.data)); 

          // resolve((res.data)); 
          if (res.data.data != null) {
            if (res.data.data.isLogin) {
              resolve((res.data));
            }
            else {
              // res.data.data = JSON.parse(res.data.data);
              let resultJson = Helper.isJson(res.data.data);
              if (resultJson == false) {
                resultJson = res.data.data;
              }
              res.data.data = resultJson;
              resolve((res.data));
            }
          } else {
            resolve((res.data));
          }
        })
        .catch(err => {
          console.error(' send Error');
          console.log(' send Error command: ', formData.get('command'));

          // Helper.goToPage('login');
          Helper.goToPage('register');
          reject('post Send API: ' + err);
        });
    });
  },

  verifyCaptcha(dataj) {

    const formData = this.fillFormatData("verifyCaptcha", dataj);
    return this.send(formData);
  },

  loginVerify() {
    return new Promise((resolve, reject) => {
      store.dispatch('Login/loginVerify')
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  myFunction(tableName, dataj) {
    let myFunctionName = dataj.myFunctionName;
    delete dataj.myFunctionName;
    const formData = this.fillFormatData(myFunctionName + tableName, dataj);
    return this.send(formData);
  },

  fillFormatData(comand, dataj) {
    const formData = new FormData();
    formData.append('command', comand);

    for (var prop in dataj) {
      formData.append(prop, dataj[prop]);
    }
    return formData;
  }


}
