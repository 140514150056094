<template>
  <v-tooltip bottom :color="color" :disabled="disabled">
    <template slot="activator" slot-scope="{ on }">
      <div v-on="on">
        <slot></slot>
      </div>
    </template>
    <span class="black--text">{{ text }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: "MyTooltip",

    data: () => ({}),

    props: {
      bind: {},
      on: {},
      color: {
        type: String,
        default: "primary",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: "",
      },
    },
    methods: {},
  };
</script>

<style scoped>
</style>