import PHPServer from '../../res/services/postToPHPServer';
// import Helper from "../../res/js/Helper.js";

const myFunctions = {
  myName: 'TelcMember',
  myTableName: 'tbl_telcMember',
  myState() {
    return {
      // formatedItems: [],
    }
  },
  myGetter() {
    return {
      // formatedItems: state => state.formatedItems,
    }
  },
  myAction() {
    return {
      updateItem({ dispatch, state }, dataj) {
        // console.log('telc member store update: ', dataj);
        if (state.fields.length === 0) {
          dispatch('fieldsItems');
        }
        const formData = PHPServer.fillFormatData("update" + state.tableName, dataj);
        return PHPServer.send(formData);
      },

      saveForm({ state }, dataj) {
        dataj.myFunctionName = 'saveForm';
        return PHPServer.myFunction(state.tableName, dataj);
      },

      selectMarburg({ state }) {
        let data = {
          myFunctionName: 'selectMarburg',
        }
        return PHPServer.myFunction(state.tableName, data)
          .then((res) => {
            // console.log('selectMarburg: ', res);
            let items = res.data.data;
            if (items.length > 0) {
              state.fields = Object.keys(items[0]);
              // console.log('getVueTable  state.fields: ', state.fields);
              for (let i = 0; i < items.length; i++) {
                items[i].row = i + 1;
              }
              state.items = items;
              //set headerFilter 
              state.tableInfo = res.data.header;
              let headerFilter = [];
              for (let i = 0; i < state.fields.length; i++) {
                let temp = state.tableInfo.find(x => x.Field == state.fields[i]);
                let strType = temp.Type;
                if (strType == 'date') {
                  headerFilter[i] = '2';
                } else if (strType.indexOf('char') >= 0 || strType.indexOf('text') >= 0 || strType.indexOf('enum') >= 0) {
                  headerFilter[i] = '1';
                } else {
                  headerFilter[i] = '0';
                }
              }
              state.headerFilter = headerFilter;
            }
            else {
              state.items = items;
            }
          });
      },

      selectBonn({ state }) {
        let data = {
          myFunctionName: 'selectBonn',
        }
        return PHPServer.myFunction(state.tableName, data)
          .then((res) => {
            // console.log('selectBonn: ', res);
            let items = res.data.data;
            if (items.length > 0) {
              state.fields = Object.keys(items[0]);
              // console.log('getVueTable  state.fields: ', state.fields);
              for (let i = 0; i < items.length; i++) {
                items[i].row = i + 1;
              }
              state.items = items;
              //set headerFilter 
              state.tableInfo = res.data.header;
              let headerFilter = [];
              for (let i = 0; i < state.fields.length; i++) {
                let temp = state.tableInfo.find(x => x.Field == state.fields[i]);
                let strType = temp.Type;
                if (strType == 'date') {
                  headerFilter[i] = '2';
                } else if (strType.indexOf('char') >= 0 || strType.indexOf('text') >= 0 || strType.indexOf('enum') >= 0) {
                  headerFilter[i] = '1';
                } else {
                  headerFilter[i] = '0';
                }
              }
              state.headerFilter = headerFilter;
            }
            else {
              state.items = items;
            }
          });
      },


      // eslint-disable-next-line no-unused-vars
      sendInvitationEmail({ state }, id) {
        let data = {
          myFunctionName: 'sendInvitationEmail',
          ids: id
        }
        return PHPServer.myFunction(state.tableName, data);
        // .then((res) => {
        //   console.log('sendInvitationEmail: ', res);
        // }
        // );

      },

    }
  },
  myMutation() {
    return {
    }
  }
};

export default myFunctions;