<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-row align="center">
        <v-col cols="1">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-col>

        <v-col cols="3">
          <div class="d-flex align-center">
            <!-- src="./res/img/img.jpeg" -->
            <v-img
              alt="ttt"
              class="shrink mt-1 hidden-sm-and-down ma-2"
              contain
              min-width="100"
              :src="headerImage"
              width="100"
              @click="logoClick"
              style="cursor: pointer"
            />
            <div @click="logoClick" style="cursor: pointer">
              {{ $t("logoName") }}
            </div>
          </div>
        </v-col>
        <v-col cols="4" align="center">
          <div v-if="isLogin">
            {{ $t("App.wellcome") }}
            {{ getUser.firstName + " " + getUser.lastName }}
          </div>
        </v-col>
        <v-col cols="3" justify="end">
          <languagepicker class="mr-1" style="maxwidth: 150px"></languagepicker>
        </v-col>
        <!-- btn logout -->
        <v-col cols="1" justify="end">
          <v-row justify="end">
            <mybtn
              v-if="isLogin"
              :disabled="!isLogin"
              @click="logout"
              :label="$t('App.logout')"
              :tooltiptext="$t('App.logout')"
              color="red"
              iconname="mdi-logout"
              large
            ></mybtn>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-for="(x, index) in toLink">
            <div v-if="x.name == 'divider'" :key="index">
              <v-divider></v-divider>
              <div>{{ x.path }}</div>
            </div>
            <v-list-item :key="index" v-if="x.name !== 'divider'" :to="x.path">
              <router-link :to="x.path">{{ x.name }}</router-link>
            </v-list-item>
          </template>

          <!--          <v-list-item>-->
          <!--            <v-list-item-title>-->
          <!--              <router-link to="/menu">menue</router-link>-->
          <!--            </v-list-item-title>-->
          <!--          </v-list-item>-->

          <!-- <v-list-item v-for=" x in toLink" :key="x.name">
            <router-link :to="x.path">{{x.name}}</router-link>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container style="height: 100%" fluid class="ma-0 pa-0">
        <v-row no-gutters style="height: 100%">
          <v-col cols="12" style="height: 100%">
            <router-view style="max-height: 100%; max-width: 100%"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <mysnackbar></mysnackbar>

    <myalert></myalert>

    <myfooter></myfooter>
  </v-app>
</template>

<script>
// import LanguagePicker from "./res/language/component/LanguagePicker";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    // LanguagePicker,
  },

  data: () => ({
    drawer: false,
    group: null,
    toLinkLogout: [
      { name: "register form", path: "/register" },
      { name: "login", path: "/login" },
    ],
    toLinkLogin: [
      { name: "Register form", path: "/register" },
      { name: "Menü", path: "/menu" },
      // { name: "telc-Teilnehmer", path: "/telcmember/all" },
      // { name: "telc-Teilnehmer Marburg", path: "/telcmember/marburg" },
      // { name: "telc-Teilnehmer Bonn", path: "/telcmember/bonn" },
      { name: "Prüfungsarten", path: "/examtype" },
      { name: "Prüfungstermine", path: "/examdate" },
      // this.$t(this.myName + '.rules.streetNrRules1')

      // {name: 'modelsViews/ExamType', path: '/modelsViews/ExamType'},
      // {name: 'modelsViews/ExamDate', path: '/modelsViews/ExamDate'},
      // {name: 'modelsViews/Tables', path: '/modelsViews/Tables'},
      // {name: 'test/my', path: '/test/my'},
      // {name: 'Page Adjustment', path: '/modelsViews/PageAdjustment'},
      // {name: 'users', path: '/users'},
      // { name: '', path: '/'},
    ],

    toLinkAdmin: [
      { name: "divider", path: "Admin" },
      { name: "Mitarbeiter", path: "/users" },
    ],

    toLinkDeveloper: [
      { name: "divider", path: "Developer" },
      { name: "logFiles", path: "/logFiles" },
      // { name: "developer", path: "/developer" },
      // { name: "doVersion", path: "/doVersion" },
      { name: "test", path: "/test" },
    ],

    tolinkTest: [{ name: "test", path: "/test" }],
  }),

  computed: {
    ...mapGetters({
      isLogin: "Login/getIsLogin",
      getUser: "Login/getUser",
      headerImage: "serverSetup/getHeaderImage",
    }),

    toLink() {
      // console.log(' tolink ***');
      if (this.isLogin) {
        let resArray = this.toLinkLogin;
        if (this.getUser.admin) {
          resArray = resArray.concat(this.toLinkAdmin);
        }
        if (this.getUser.developer) {
          // resArray.push.apply(this.toLinkLogin,this.toLinkDeveloper);
          resArray = resArray.concat(this.toLinkDeveloper);
        }

        return resArray;
      } else {
        let resArray = this.toLinkLogout;
        if (process.env.NODE_ENV !== "production") {
          resArray = resArray.concat(this.tolinkTest);
        }

        return resArray;
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.$store.dispatch('fetchModuleNames');
      this.toLinkExpand();
      this.$store.dispatch(`serverSetup/setHeaderImage`);
    },

    logout() {
      this.$store.dispatch("Login/logout");
      this.goToPage("login");
      // this.$router.push("/login");
      console.log("logoutt");
    },

    logoClick() {
      // this.$store.dispatch("Login/loginVerify").then(() => {
      //   let tmp = this.$store.getters["Login/getIsLogin"];
      //   console.log("app.vue tmp:", tmp);
      // });
      window.location.href = "https://diwan-marburg.de";
      // location.replace('http://diwan-marburg.de');
    },

    gotoDeveloper() {
      this.goToPage("developer");
      // this.$router.push("developer");
    },

    toLinkExpand() {
      // console.log(" test appt", this.$router);

      let allRouters = this.$router.options.routes;

      // console.log(" routes ", allRouters);
      for (let i = 0; i < allRouters.length; i++) {
        let keys = Object.keys(allRouters[i]);

        if (keys.indexOf("meta") >= 0) {
          //console.log(' name route : ' , allRouters[i].name);

          let meta = Object.keys(allRouters[i].meta);

          // console.log(' name route meta: ' , meta);

          // let accessArray = ['is_developer', 'is_access' , 'is_admin'];

          if (meta.indexOf("is_developer") >= 0) {
            this.toLinkDeveloper.push({
              name: allRouters[i].name,
              path: allRouters[i].path,
            });
          }

          // if (meta.indexOf("is_access") >= 0) {
          //   this.toLinkLogin.push({
          //     name: allRouters[i].name,
          //     path: allRouters[i].path,
          //   });
          // }

          // if (meta.indexOf("is_admin") >= 0) {
          //   this.toLinkAdmin.push({
          //     name: allRouters[i].name,
          //     path: allRouters[i].path,
          //   });
          // }
        }

        // console.log(' keys : ', keys);
        // if(allRouters[i].hasOwnProperty('meta'))
        //   console.log(' name route : ' , allRouters[i].name);
      }

      return null;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    //todo check each value of table in server
    //todo add edit and delet button in each row in data table
    //todo make seprate EncryptionKey for each person in server
    //todo
    //todo
    //todo
  },
};
</script>
