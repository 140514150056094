<template>
  <v-container>
    <v-row justify="space-around">
      <v-col xs="10">
        <v-card outlined elevation="10">
          <v-toolbar color="cyan" dark>
            <v-app-bar-nav-icon
              @click="listItemsShow = !listItemsShow"
            ></v-app-bar-nav-icon>
            <v-card-title>
              <v-row justify="space-between">
                <v-col>
                  <v-toolbar-title>{{ $t(myName + "." + myName) }}</v-toolbar-title>
                </v-col>
                <v-spacer></v-spacer>
                <v-col></v-col>
              </v-row>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon
              @click="tablesSetingsShow = !tablesSetingsShow"
            ></v-app-bar-nav-icon>
          </v-toolbar>

          <v-card-text>
            <v-row justify="center">
              <v-col sm="3" v-if="listItemsShow">
                <v-card class="" max-width="300" tile height="">
                  <v-toolbar color="cyan" dark>
                    <v-row justify="center" class="mt-2">
                      <v-col>
                        <mytextfield
                          v-model="search"
                          :label="$t(myName + '.search')"
                          append-icon="mdi-magnify"
                        ></mytextfield>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-list dense rounded>
                    <!-- <v-subheader>REPORTS</v-subheader> -->
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in listItems" :key="i">
                        <v-list-item-icon>
                          <!-- <v-icon v-text="item.icon"></v-icon> -->
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col v-if="myTableName !== ''" :sm="listItemsShow == true ? 9 : 12">
                <v-row>
                  <v-col :sm="tablesSetingsShow == true ? 9 : 12">
                    <mydatatable
                      :key="myTableName"
                      :name="myTableName"
                      :savedata="saveData"
                      show-add-btn
                      show-edit-btn
                      show-delete-btn
                      show-excel-btn
                    >
                      <!-- <examDateSave @change="save"></examDateSave> -->
                    </mydatatable>
                  </v-col>
                  <v-col sm="3" v-if="tablesSetingsShow">
                    <v-card class="" max-width="300" tile height="">
                      <v-toolbar color="cyan" dark>
                        {{ $t(myName + ".settings") }}
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <mytextfield
                              v-model="columnName"
                              :label="$t(myName + '.columnName')"
                            ></mytextfield>
                            <mybtn
                              @click="addColumn"
                              :label="$t(myName + '.addColumn')"
                            ></mybtn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else sm="9"></v-col>
            </v-row>
            <v-row justify="center"> </v-row>
            <!-- <v-row justify="center">
              <mybtn @click="test" label="test1"></mybtn>
            </v-row> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <mydialog v-model="dialogAddNewColumn" :title="$t(myName + '.addColumn')">
      <addNewColumn
        :setData="addNewColumnsData"
        :key="addNewColumnsData.id"
      ></addNewColumn>
    </mydialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Helper from "@/res/js/MyHelper";
// import addNewColumn from '@/src/components/DB/addNewColumn.vue';
import addNewColumn from "../addNewColumn.vue";
// import PHPServer from "@/res/services/postToPHPServer";

// const fs = require('fs');
//
// const someFileContents = fs.readFileSync('../views/');

export default {
  name: "AllTables",
  meta: {
    requiresAuth: true,
    is_developer: true,
  },
  data() {
    return {
      selectedItem: -1,
      myMeta: {
        // requiresAuth: true,
        // is_developer: true,
      },
      myName: "AllTables",
      //   allTables: [],
      refreshCaptcha: true,
      fotoSrc: "",
      myTableName: "",
      saveData: false,
      search: "",
      //   listItems: this.allTables,
      listItems: [],
      listItemsShow: true,
      tablesSetingsShow: true,
      columnName: "",
      dialogAddNewColumn: false,
      addNewColumnsData: {},
    };
  },

  components: {
    addNewColumn,
  },

  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
      //   allTables: `${this.myName}/getMyAllTables`,
      allTables: `AllTables/getMyAllTables`,
      // myTestFormatedItems: "ExamDate/formatedItems",
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch(`${this.myName}/getAllTables`);
      this.listItems = this.allTables;
    },

    getMeta() {
      console.log(" alltables ");
      let data = {
        myFunctionName: "getMeta",
        // jsonLanguage: JSON.stringify(params),
      };
      this.$store.dispatch(`${this.myName}/myFunction`, data).then((res) => {
        console.log("get meta resss: ", res.data);
        this.myMeta = res.data;
      });
    },

    addColumn() {
      this.addNewColumnsData.tableName = this.myTableName;
      if (Helper.isEmpty(this.addNewColumnsData.id)) {
        this.addNewColumnsData.id = 0;
      } else {
        this.addNewColumnsData.id = this.addNewColumnsData.id + 1;
      }

      this.dialogAddNewColumn = true;

      // if (!Helper.isEmpty(this.columnName)) {
      //   let data = {
      //     myFunctionName: "addColumn",
      //     tableName: this.myTableName,
      //     columnName: this.columnName,
      //   }
      //   this.$store.dispatch(`${this.myName}/myFunction`, data).then((res) => {
      //   console.log(" addColumn resss: ", res.data);
      // });
      // }
    },

    test() {
      console.log(" test1");
    },
  },

  watch: {
    myMeta() {
      console.log(" my Meta in wath : ", this.myMeta);
    },
    allTables() {
      for (let i = 0; i < this.allTables.length; i++) {
        this.$store.dispatch(`myRegisterModule`, this.allTables[i]);
      }
      this.listItems = this.allTables;
    },

    selectedItem() {
      if (this.selectedItem >= 0) {
        this.myTableName = this.allTables[this.selectedItem];
        this.$store.dispatch(`${this.allTables[this.selectedItem]}/getVueTable`);
        // let tableInfo = this.$store.getters[
        //   `${this.allTables[this.selectedItem]}/getFields`
        // ];
        // console.log(" vueTable : ", tableInfo);
      } else {
        this.myTableName = "";
      }
    },

    search() {
      this.selectedItem = -1;
      if (Helper.isEmpty(this.search)) {
        this.listItems = this.allTables;
      } else {
        this.listItems = this.allTables.filter((item) => {
          return item.toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
  },
};
</script>

<style scoped></style>
