<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" xs="10">
        <v-card outlined class="mx-auto container" elevation="10">
          <v-card-title>
            <v-row justify="space-between">
              <v-col>
                <v-toolbar-title>{{ $t(myName + "." + myName) }}</v-toolbar-title>
              </v-col>
              <v-spacer></v-spacer>
              <v-col> </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="">
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===userName -->
                  <mytextfield
                    v-model="editedItem.user"
                    :rules="rules.userRules"
                    :label="$t(myName + '.user')"
                    :placeholder="!phUser ? $t(myName + '.password') : ''"
                  ></mytextfield>

                  <!-- <v-text-field
                    v-model="editedItem.user"
                    :rules="rules.userRules"
                    :label="$t(myName + '.user')"
                    :placeholder="!phUser ? $t(myName + '.password') : ''"
                    required
                    clearable
                    outlined
                  ></v-text-field> -->
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===password -->
                  <mytextfield
                    v-model="editedItem.password"
                    :rules="rules.passwordRules"
                    :label="$t(myName + '.password')"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :placeholder="!phPass ? $t(myName + '.password') : ''"
                    @clickAppend="showPass = !showPass"
                    @keyupEnter="submit"
                  ></mytextfield>

                  <!-- <v-text-field
                    v-model="editedItem.password"
                    :rules="rules.passwordRules"
                    :label="$t(myName + '.password')"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :placeholder="!phPass ? $t(myName + '.password') : ''"
                    @click:append="showPass = !showPass"
                    @keyup.enter="submit"
                    required
                    clearable
                    outlined
                  ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="12" class="my-0 py-0">
                  <!--===captcha -->
                  <mycaptcha
                    :refresh="refreshCaptcha"
                    :rules="rules.captchaRuls"
                    @keyupEnter="submit"
                  ></mycaptcha>
                </v-col>
              </v-row>
            </v-form>
            <mysavebtn
              :disabled="!valid"
              @submit="submit"
              @clear="forgotPassword"
              :savetext="$t(myName + '.Login')"
              :savetooltiptext="$t(myName + '.Login')"
              :cleartext="$t(myName + '.forgotPassword')"
              :cleartooltiptext="$t(myName + '.forgotPassword')"
            ></mysavebtn>
            <!-- <mybtn
             label="reset"
             @click="clear"
            ></mybtn> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <forgotPassword v-model="dialogForgotPassword"></forgotPassword>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import forgotPassword from "../MyForgotPassword";

export default {
  name: "login",
  components: {
    forgotPassword,
  },
  data() {
    return {
      refreshCaptcha: true,
      showPass: false,
      myName: "Login",
      valid: true,
      dialogForgotPassword: false,
      phUser: false,
      phPass: false,
    };
  },
  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
    }),
    editedItem() {
      let item = this.$store.getters[`${this.myName}/getEditedItem`];
      // item.user = 'mohsendana2010@yahoo.com';
      // item.password = 'asdf';
      return item;
    },
    logind() {
      return this.$store.getters[`${this.myName}/getEditedItem`];
    },
    rules() {
      let rules = {
        userRules: [
          (v) => !!v || this.$t(this.myName + ".rules.userRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.userRules1"),
          (v) =>
            /^[ ]*[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*@[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*(\.[a-zA-Z0-9äöüÄÖÜß]{2,3})+[ ]*$/.test(
              v
            ) || this.$t(this.myName + ".rules.userRules2"),
        ],
        passwordRules: [
          (v) => !!v || this.$t(this.myName + ".rules.passwordRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.passwordRules1"),
        ],
        captchaRuls: [
          (v) => !!v || this.$t("captcha.captchaText"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.captchaText"),
        ],
      };

      return this.formActive ? rules : {};
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},

    clear() {
      this.$refs.form.reset();
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.editedItem.user = this.editedItem.user.trim();
        this.editedItem.captcha = true;
        let tempEditetItem = JSON.parse(JSON.stringify(this.editedItem));
        // this.clear();
        this.$store
          .dispatch(`${this.myName}/login`, tempEditetItem)
          .then((res) => {
            //  console.log('submit myLogin.vue:', res.data);
            if (res.data === "captchaError") {
              this.refreshCaptcha = !this.refreshCaptcha;
            } else if (res.data.isLogin) {
              // this.$router.push({path: 'menu'});
              //  let test =  this.capitalizeFirstLetter('mohsen');
              //  console.log('  test : ' , test);
              this.$toast.success(this.$t(this.myName + ".toastSubmitsuccess"), {
                timeout: 5000,
              });
              this.goToPage("menu");
              this.clear();
            } else {
              console.log(" test login falsh!");
              this.$toast.error(this.$t(this.myName + ".toastSubmitError"));
              this.editedItem.user = tempEditetItem.user;
              this.editedItem.password = "";
              this.refreshCaptcha = !this.refreshCaptcha;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    forgotPassword() {
      this.dialogForgotPassword = true;
    },
  },

  watch: {
    "editedItem.user"() {
      this.phUser = true;
    },
    "editedItem.password"() {
      this.phPass = true;
    },
  },
};
</script>

<style scoped></style>
