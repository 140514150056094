const moment = require('moment');

const myFunctions = {
  myName: 'ExamDate',
  myTableName: 'tbl_exam_date',
  myState(){
    return {
      formatedItems: [],
    }
  },
  myGetter() {
    return{
      formatedItems: state => state.formatedItems,
    }
  },
  myAction() {
   return{
     test({dispatch, state}) {
       // console.log('func name:', this.test.name);
       console.log('my state in examDate', state);
       dispatch('headerFilter');
       // return 'salam';
     },
     formatedItems({state}) {
       state.formatedItems = state.items.map(obj => {
         let rObj = {};
        //  rObj['text'] = moment(obj.writingExamDate).format("DD.MM.YYYY");
         rObj['text'] ='Prüfung: ' + moment(obj.writingExamDate).format("DD.MM.YYYY") +
         ' / Anmeldefrist: ' + moment(obj.registrationDeadline).format("DD.MM.YYYY");
         rObj['value'] = obj.writingExamDate;
         rObj['speakingExamData'] = obj.speakingExamData;
         rObj['registrationDeadline'] = obj.registrationDeadline;
         rObj['lastRegistrationDeadline'] = obj.lastRegistrationDeadline;
         rObj['examTypes'] = obj.examTypes;
         rObj['data'] = obj;
         return rObj;
       })
     },
   }
  },
  myMutation(){
    return {
      testMutation({dispatch, state}) {
        // console.log('func name:', this.test.name);
        console.log('my state in examDate', state);
        dispatch('headerFilter');
        // return 'salam';
      },
    }
  }
};

export default myFunctions;

