<template>
  <div>
    <v-card
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-card-text>
        <!-- :label="label" -->

        <v-row>
          <v-col xs="12" sm="6" class="my-0 py-0">
            <v-file-input
              ref="refFileInput"
              v-model="files"
              :label="label"
              :counter="counter"
              :multiple="multiple"
              :placeholder="placeholder"
              :show-size="showSize"
              :hint="hint"
              :rules="rules"
              :prepend-icon="prependIcon"
              :prepend-inner-icon="prependInnerIcon"
              :append-icon="appendIcon"
              :append-outer-icon="appendOuterIcon"
              :outlined="outlined"
              :clearable="clearable"
              :color="color"
              :dense="dense"
              :disabled="disabled"
              :bind="bind"
              :on="on"
            >
              <template slot="append">
                <v-row
                  class="d-flex flex-column"
                  dense
                  align="center"
                  justify="center"
                  no-gutters
                  @click="addInList"
                >
                  <v-icon
                    :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                    size="50"
                    :color="myColor"
                  >mdi-cloud-upload</v-icon>
                  <p class="text-body-2 ma-0 pa-0">Drop your file(s) here,</p>
                  <p class="text-body-2">or click to select them.</p>
                </v-row>
              </template>
            </v-file-input>
          </v-col>
          <v-col xs="12" sm="6" class="my-0 py-0">
            <v-card v-if="myValue.length > 0" outlined class="mx-auto" elevation="1" ref="drawer">
              <v-card-text style="max-height: 150px" class="text-subtitle-2">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-row no-gutters>
                      <v-col
                        v-for="(data, index) in myValue"
                        :key="index"
                        class="ma-0 pa-1"
                        cols="12"
                        xs="12"
                        sm="12"
                      >
                        <v-chip
                          :input-value="data.name"
                          close
                          @click:close="remove(index)"
                          color="deep-purple accent-4"
                          dark
                          @click="remove(index)"
                        >{{ data.name }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row justify="end">
                      <div>
                        <mytooltip text="alles löschen">
                          <mybtn
                            label="test"
                            color="red"
                            large
                            iconname="mdi-delete"
                            @click="removeAll"
                            absolute
                            top
                            right
                          ></mybtn>
                        </mytooltip>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MyInputFile",

  data() {
    return {
      myValue: this.myValue1,
      files: [],
      dragover: false,
    };
  },

  computed: {
    myColor() {
      return this.dragover == true ? "green darken-1" : "grey darken-1";
    },
  },

  props: {
    myValue1: {
      type: [Object, String, Array],
      default() {
        return [];
      },
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    counter: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: "mdi-paperclip",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    appendOuterIcon: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSize: {
      type: String,
      default: "",
    },
    bind: {},
    on: {},
  },

  model: {
    prop: "myValue1",
    event: "change",
  },

  methods: {
    change(val) {
      this.$emit("change", val);
    },

    remove(index) {
      let temp;
      if (index >= 0) {
        temp = this.myValue.splice(index, 1);
      }
      this.$emit("remove", temp);
    },

    removeAll() {
      while (this.myValue.length > 0) {
        this.remove(0);
        // this.myValue.splice(0, 1);
      }
      //   this.myValue = [];
    },

    onDrop(e) {
      this.dragover = false;
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$toast.error("Only one file can be uploaded at a time..");
      } else
        e.dataTransfer.files.forEach((element) => {
          this.$emit("input", element);
          this.myValue.push(element);
        });
    },

    addInList() {
      this.$refs.refFileInput.$refs.input.click();
    },
  },
  watch: {
    myValue1() {
      this.myValue = this.myValue1;
    },

    files() {
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          this.myValue.push(this.files[i]);
          this.$emit("input", this.files[i]);
        }
        this.files = [];
      }
    },
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>