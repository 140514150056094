const myFunctions = {
  myName: 'ExamType',
  myTableName: 'tbl_exam_type',
  myState() {
    return {
      formatedItems: [],
    }
  },
  myGetter() {
    return {
      formatedItems: state => state.formatedItems,
    }
  },
  myAction() {
    return {
      formatedItems({ state }) {
        state.formatedItems = state.items.map(obj => {
          let rObj = {};
          rObj['text'] = obj.type + " (" + obj.subtype + ")";
          rObj['value'] = obj.type + " (" + obj.subtype + ")";
          rObj['data'] = obj;
          rObj['description'] = obj.description;
          rObj['language'] = obj.language;
          return rObj;
        })

        // console.log(' formatedItems ', state.formatedItems);
      },

      test({ dispatch, state }) {
        // console.log('func name:', this.test.name);
        console.log('my state', state);
        dispatch('headerFilter');
        // return 'salam';
      }
    }
  },
  myMutation() {
    return {
      testMutation({ dispatch, state }) {
        // console.log('func name:', this.test.name);
        console.log('my state in examDate', state);
        dispatch('headerFilter');
        // return 'salam';
      },
    }
  }
};

export default myFunctions;
