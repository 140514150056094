import Modules from "./cls_modules";

class TileModule {
  constructor(myName, myTableName, myFile = null) {
    this.modules = new Modules(myName, myTableName , myFile);
    this.state = this.modules.myStates();
    this.getters = this.modules.myGetters();
    this.actions = this.modules.myActions(this.state);
    this.mutations = this.modules.myMutations(this.state);
    return {
      namespaced: true,
      state: this.state,
      getters: this.getters,
      actions: this.actions,
      mutations: this.mutations,
    }
  }

}
// export const myTileModule = tileModule;

export default TileModule;