// import PHPServer from '../../res/services/postToPHPServer'; 
const myName = 'interval'; 
 
const state = { 
    intervalId: 0, 
    count: 0, 
    doJobs: [ 
    ], 
}; 
 
const getters = { 
    getIntervalId: state => state.intervalId, 
    getCount: state => state.count, 
    getDoJobs: state => state.doJobs, 
}; 
 
const actions = {//dispatch 
    // eslint-disable-next-line  
    addDoJobs({ dispatch }, dataj) { 
 
        // console.log('do state.doJobs : ', state.doJobs); 
        // console.log('do state.doJobs dataj: ', dataj); 
        let index = state.doJobs.find(x => x.name = dataj.name); 
        // console.log('   index of ::' , index); 
        if (index == undefined) { 
            dataj.count += state.count; 
            // console.log('do Func count : ', dataj.count); 
 
            state.doJobs.push(dataj); 
        } else { 
            dispatch('updateDoJobs',dataj); 
        } 
    }, 
 
    // eslint-disable-next-line 
    updateDoJobs({ dispatch }, dataj) { 
        
        let index = state.doJobs.find(x => x.name = dataj.name); 
        // console.log('updateDoJobs:', index); 
        index.count = dataj.count + state.count; 
        // console.log('do state.doJobs in update :>> ', state.doJobs); 
        // console.log('do state.doJobs in update :>> ', index.count); 
    }, 
 
    runInterval() { 
        if (state.intervalId == 0) { 
            state.intervalId = setInterval(() => { 
                state.count++; 
                // console.log("intervalId ", state.intervalId); 
                if (state.count % 5 == 0) { 
                    // console.log("count ", state.count); 
                } 
                if (state.doJobs.length > 0) { 
                    for (let i = 0; i < state.doJobs.length; i++) { 
                        if (state.doJobs[i].count == state.count) { 
                            state.doJobs[i].doFunc(); 
                            // state.doJobs.splice(i, 1); 
                        } 
                    } 
                } 
            }, 1000); 
        } 
    }, 

    endInterval() { 
        if (state.intervalId != 0) { 
            clearInterval(state.intervalId); 
            state.intervalId = 0; 
        } 
    }, 

    showDoJobs() { 
        console.log(' doJobs ::   ' , state.doJobs);
    },
}; 
 
const mutations = {//commit 
}; 
 
export default { 
    myName, 
    namespaced: true, 
    state, 
    getters, 
    actions, 
    mutations, 
};