<template>
  <!-- <span> -->

  <v-row justify="space-between" ref="infoBox">
    <v-col align-self="center">
      <!-- <v-row align-self="center" justify="center"> -->
      <v-menu :key="btnText" :rounded="rounded" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <mybtn
            :label="btnText"
            :on="on"
            :bind="attrs"
            :color="color"
            :iconname="iconName"
            @click="matchHeight"
            :tooltiptext="tooltipText"
          ></mybtn>
        </template>
        <v-list>
          <v-list-item v-for="item in btnsInfoArray" :key="item.id" link>
            <mybtn
              :label="item.textValue"
              @click="item.method()"
              :color="item.color"
              :iconname="item.iconName"
              :tooltiptext="$t(item.tooltipText)"
            ></mybtn>
            <v-list-item-title @click="item.method()">
              {{ $t(item.textValue) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- </v-row> -->
    </v-col>
    <!-- <v-col   xs="1"> -->
    <!-- <v-col v-for="item in btnsInfoArray" :key="item.id"  xs="1">
      <mybtn
      v-text="item.textValue"
        :tooltiptext="item.textValue"
        @click="item.method()"
        :color="item.color"
        :iconname="item.iconName"
      ></mybtn>
    </v-col> -->
    <!-- </v-col> -->
  </v-row>

  <!-- <mybtn
       @click="buttonClicked('save')"
       label="save"
       :color="color"
       :iconname="iconName"
  ></mybtn>-->
  <!-- </span> -->
</template>

<script>
export default {
  name: "actionBtnRenderer",
  data() {
    return {
      myName: "",
      cellValue: null,
      iconName: "",
      color: "",
      data: null,
      btnsInfoArray: [],
      tooltipText: "",

      rounded: "lg",
      btnText: "Large",
      mounted: false,
    };
  },
  computed: {
    wrapperHeight() {
      if (!this.mounted) return;
      let height = this.$refs.infoBox?.clientWidth;
      // console.log(" height matchheight: ", this.$refs);
      // console.log(" height matchheight: ", height);
      return height;
    },
  },
  mounted() {
    this.mounted = true;
    // this.matchHeight();
  },

  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
    this.iconName = this.params.iconName;
    this.color = this.params.color;
    this.data = this.params.data;
    this.myName = this.params.myName;
    this.btnsInfoArray = this.params.btnsInfoArray;
    this.tooltipText = this.params.tooltipText;
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    // buttonClicked(val) {
    buttonClicked() {
      console.log("cellValue:", `${this.cellValue} `);
      // console.log(val);
      this.$emit(this.cellValue);
    },

    getValueToDisplay(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },

    matchHeight() {
      // if (!this.mounted) return;
      let height = this.$refs.infoBox?.clientWidth;
      this.setEditedIndex(this.data);
      // console.log(" height matchheight: ", this.$refs);
      // console.log(" height matchheight: ", height);
      return height;
    },

    setEditedIndex(data) {
      // console.log('setEditedIndex in actionbtnrenderer ', myId, this.myName);
      this.$store.dispatch(`${this.myName}/setEditedIndex`, parseInt(data.id));
      this.$store.dispatch(
        `${this.myName}/setEditedItem`,
        JSON.parse(JSON.stringify(data))
      );
    },

    test(val) {
      console.log(" tesssst ", val);
    },
  },
};
</script>

<style scoped></style>
