<template>
  <v-dialog
    v-model="myValue"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :width="width"
    :height="height"
    :persistent="persistent"
    :fullscreen="fullscreen"
    :location="location"
    :scroll-strategy="scrollStrategy"
    @click:outside="closeDialog"
  >
    <v-card
    >
      <v-card-title>
        <span class="headline">{{ title }}</span>

        <v-spacer></v-spacer>
        <mybtn
          iconname="mdi-close-circle-outline"
          v-if="closeButton"
          @click="closeDialog"
          :tooltiptext="$t('closeWindow')"
          tooltipcolor="red"
          color="red"
          large
        ></mybtn>
        <!--          back button-->
        <!--          todo back button--->
      </v-card-title>

      <v-card-text>
        <v-container>
          <slot></slot>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MyDialog",

  data() {
    return {
      myValue: this.myValue1,
    };
  },

  props: {
    myValue1: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "700px",
    },
    maxHeight: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "700px",
    },
    height: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    scrollStrategy: {
      type: String,
      default: "",
      //   'close' | 'block' | 'none' | 'reposition'
    },
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bind: {},
    on: {},
  },

  model: {
    prop: "myValue1",
    event: "change",
  },

  created() {},

  beforeDestroy() {
  },
  openDialog() { 
    console.log(' open dialoggg');
  },

  methods: {
    change(val) {
      this.$emit("change", val);
    },

    closeDialog() {
      this.myValue = false;
      this.tmpClickOutseid = false;
      this.change(this.myValue);
    },

    clickOutseid() { 
        // console.log(' clickOutseid dialogggg');
        // console.log(' closeDialog value: ' , this.myValue);
        // if(this.persistent == false ){
        //     if( this.tmpClickOutseid == true){
        //         this.closeDialog();
        //     }
        //     if(this.tmpClickOutseid == false && this.myValue == true){
        //         this.tmpClickOutseid = true;
        //     }
        // }
    },
  },
  watch: {
    myValue1() {
      this.myValue = this.myValue1;
    },
  },
};
</script>

<style scoped></style>
