<template>
  <div class="text-center ma-2">
    <v-dialog persistent v-model="myDialog">
      <v-snackbar v-model="mySnackbar" :timeout="timeout" :color="color" centered>
        <v-row align="center" justify="space-between">
          <span class="myspan">
            {{ text }}
          </span>
        </v-row>
        <v-row align="center" justify="end">
          <mybtn
            iconname="mdi-check"
            color="green"
            small
            fab
            @click="doFunction"
            class="mx-2"
          ></mybtn>
          <mybtn
            iconname="mdi-close"
            color="red"
            small
            fab
            @click="donotFunction"
            class="mx-2"
          ></mybtn>
        </v-row>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MySnackbar",
  data() {
    return {
      mySnackbar: false,
      myDialog: false,
    };
  },

  props: {
    bind: {},
    on: {},
  },

  computed: {
    ...mapGetters({
      snackbar: "MySnackbar/getSnackbar",
      text: "MySnackbar/getText",
      type: "MySnackbar/getType",
      color: "MySnackbar/getColor",
      timeout: "MySnackbar/getTimeout",
    }),
  },

  methods: {
    closeSnackbar() {
      this.$store.dispatch("MySnackbar/setShowSnackbar", false);
    },

    openAlert(show) {
      this.snackbar = show;
    },

    doFunction() {
      this.$store.dispatch("MySnackbar/setAnswer", 1);
      this.closeSnackbar();
    },

    donotFunction() {
      this.$store.dispatch("MySnackbar/setAnswer", 0);
      this.closeSnackbar();
    },
  },

  watch: {
    snackbar() {
      //   console.log("snackbar : ", this.snackbar);
      this.mySnackbar = this.snackbar;
    },

    mySnackbar() {
      //   console.log("mySnackbar : ", this.mySnackbar);
      this.myDialog = this.mySnackbar;
      if (this.mySnackbar == false) {
        this.$store.dispatch("MySnackbar/setShowSnackbar", false);
      }
    },
  },
};
</script>

<style scoped>
.myspan {
  font-size: large;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
