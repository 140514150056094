import { i18n } from "../../res/language/i18n";
import PHPServer from '../../res/services/postToPHPServer';

const myFunctions = {
    myName: 'Language',
    myTableName: 'tbl_language',
    myState() {
        return {
            formActive: true,
            language: localStorage.getItem('language'),
            // languages: LANGUAGES,
            loadedLanguages: [],
            languagesTitel: [],
            // {
            //   title: "English",
            //   value: "en"
            // },
            // {
            //   title: "Germany",
            //   value: "de"
            // },
            // {
            //   title: "Farsi",
            //   value: "fa"
            // }
            //   ],
            //   formatedItems: [],
        }
    },
    myGetter() {
        return {
            language: state => state.language,
            getFormActive: state => state.formActive,
            getLanguagesTitel(state) {
                return state.languagesTitel;
            },
        }
    },

    myAction() {
        return {

            setLanguage({ commit, state, dispatch }, lang) {
                if (state.loadedLanguages.includes(lang)) {
                    if (i18n.locale !== lang) {
                        commit("SET_LANGUAGE", lang);
                    }
                    return Promise.resolve()
                }
                dispatch('getLanguagesFile', lang);
            },

            setFormActive({ state }, dataj) {
                state.formActive = dataj;
                // console.log('formActive:', state.formActive);
            },

            getLanguagesFile({ state, dispatch, commit }, lang) {
                // console.log(' getLanguagesFile from server', " Lang : ", lang);
                dispatch("myGetLanguageFile", lang)
                    .then((res) => {
                        // console.log('getLanguagesFile res: ', res);
                        let msgs = res.data
                        state.loadedLanguages.push(lang)
                        i18n.setLocaleMessage(lang, msgs)
                        commit("SET_LANGUAGE", lang);
                    });
            },

            myGetLanguageFile({ state }, lang) {
                let data = {
                    myFunctionName: 'getLanguagesFile',
                    language: lang,
                }
                return PHPServer.myFunction(state.tableName, data);
            },

            getLanguageTitel({ state }) {
                // console.log('getLanguageTitel');
                let data = {
                    myFunctionName: 'getLanguageTitel',
                }
                return PHPServer.myFunction(state.tableName, data)
                    .then((res) => {
                        let data = res.data;
                        let langs = [];
                        if (data.length > 0) {
                            data = data[0];
                            delete data.id;
                            delete data.keyword;
                            let kyes = Object.keys(data);
                            kyes.forEach(element => {
                                if (data[element] != '') {
                                    langs.push({ title: data[element], value: element })
                                }
                            });
                        }
                        state.languagesTitel = langs;
                    });
            },

        }
    },

    myMutation() {
        return {
            SET_LANGUAGE(state, lang) {

                // localStorage.setItem("language", lang);
                state.language = lang;
                state.formActive = false;
            }
        }
    }
};

export default myFunctions;
