<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="container">
      <v-card class="mb-2 px-0">
        <v-card-title class="headline">
          <v-row justify="center">
            <div>
              {{ $t(myName + ".telcExam") }}
            </div>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!--  remarks card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t(myName + ".remarks") }} </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===archiveNumber -->
                  <mytextfield
                    v-model="editedItem.archiveNumber"
                    :label="$t(myName + '.archiveNumber')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===memberNr -->
                  <mytextfield
                    v-model="editedItem.memberNr"
                    :label="$t(myName + '.memberNr')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="12" class="my-0 py-0">
                  <!--===sheet number -->
                  <mytextfield
                    v-model="editedItem.sheetNumber"
                    :label="$t(myName + '.sheetNumber')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===paid -->
                  <v-checkbox v-model="paid" :label="$t(myName + '.paid')" required>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===paidPrice -->
                  <mytextfield
                    v-model="editedItem.paidPrice"
                    :label="$t(myName + '.paidPrice')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" class="my-0 py-0">
                  <!--remarks-->
                  <mytextarea
                    v-model="editedItem.remarks"
                    :label="$t(myName + '.remarks')"
                  ></mytextarea>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===passed -->
                  <v-checkbox v-model="passed" :label="$t(myName + '.passed')" required>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===speakingGrades -->
                  <mytextfield
                    v-model="speakingGrades"
                    :label="$t(myName + '.speakingGrades')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0"> </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="3" class="my-0 py-0">
                  <!--===readingGrades -->
                  <mytextfield
                    v-model="readingGrades"
                    :label="$t(myName + '.readingGrades')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="3" class="my-0 py-0">
                  <!--===languageStructureGrades -->
                  <mytextfield
                    v-model="languageStructureGrades"
                    :label="$t(myName + '.languageStructureGrades')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="3" class="my-0 py-0">
                  <!--===listeningGrades -->
                  <mytextfield
                    v-model="listeningGrades"
                    :label="$t(myName + '.listeningGrades')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="3" class="my-0 py-0">
                  <!--===writingGrades -->
                  <mytextfield
                    v-model="writingGrades"
                    :label="$t(myName + '.writingGrades')"
                    @keypress="isNumber"
                    @paste="onPaste"
                  ></mytextfield>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--   personalData card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline"
              >{{ $t(myName + ".personalData") }}
            </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===gender -->
                  <v-radio-group
                    v-model="editedItem.gender"
                    :mandatory="false"
                    :rules="rules.genderRules"
                    row
                  >
                    <v-radio :label="$t(myName + '.male')" value="male"></v-radio>
                    <v-radio :label="$t(myName + '.female')" value="female"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===title -->
                  <v-select
                    v-model="editedItem.title"
                    :items="titleItems"
                    :label="$t(myName + '.title')"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===firstName -->
                  <mytextfield
                    v-model="editedItem.firstName"
                    :rules="rules.firstNameRules"
                    :label="$t(myName + '.firstName')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===lastName -->
                  <mytextfield
                    v-model="editedItem.lastName"
                    :rules="rules.lastNameRules"
                    :label="$t(myName + '.lastName')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===mobile -->
                  <mytextfield
                    v-model="editedItem.mobile"
                    :label="$t(myName + '.mobile')"
                    @keypress="isNumber"
                    @paste="onPaste"
                    prepend-inner-icon="mdi-cellphone-basic"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===email -->
                  <mytextfield
                    v-model="editedItem.email"
                    :rules="rules.EmailRules"
                    :label="$t(myName + '.email')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthday -->
                  <mydatepicker
                    class="mx-0 px-0"
                    v-model="editedItem.birthday"
                    :label="$t(myName + '.birthday')"
                    min="1950-01-01"
                    :max="maxBirthday"
                    :futureallowed="false"
                    :rules="rules.BirthdayRules"
                    clearable
                    outlined
                  ></mydatepicker>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===nativeLanguage -->
                  <mytextfield
                    v-model="editedItem.nativeLanguage"
                    :label="$t(myName + '.nativeLanguage')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthCountry -->
                  <v-combobox
                    :items="states"
                    v-model="editedItem.birthCountry"
                    :rules="rules.countryRules"
                    :label="$t(myName + '.birthCountry')"
                    prepend-inner-icon="mdi-city"
                    clearable
                    outlined
                    dense
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthCity -->
                  <mytextfield
                    v-model="editedItem.birthCity"
                    :label="$t(myName + '.birthCity')"
                  ></mytextfield>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--  address card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t("address") }} </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===streetNr -->
                  <mytextfield
                    v-model="editedItem.streetNr"
                    :label="$t(myName + '.streetNr')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===co -->
                  <mytextfield
                    v-model="editedItem.co"
                    :label="$t(myName + '.co')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===postCode -->
                  <mytextfield
                    v-model="editedItem.postCode"
                    :label="$t(myName + '.postCode')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===place -->
                  <mytextfield
                    v-model="editedItem.place"
                    :label="$t(myName + '.place')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===country -->
                  <v-combobox
                    :items="states"
                    v-model="editedItem.country"
                    :label="$t(myName + '.country')"
                    prepend-inner-icon="mdi-city"
                    outlined
                    clearable
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0"> </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--  exam card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t(myName + ".exam") }} </v-card-title>
            <v-card-text>
              <v-row class="my-2 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examLocation -->
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <div class="text-h6 font-weight-bold">
                        <p>{{ $t(myName + ".examLocation") }}</p>
                      </div>
                    </v-col>
                    <v-radio-group
                      class="my-2 py-0"
                      v-model="editedItem.examLocation"
                      :mandatory="true"
                      :rules="rules.examLocationRules"
                      row
                    >
                      <v-radio label="Marburg" value="Marburg"></v-radio>
                      <v-radio label="Bonn" value="Bonn"></v-radio>
                    </v-radio-group>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0"> </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0"> </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examType -->
                  <v-combobox
                    v-model="editedItem.examType"
                    :items="formatedItemsExamType"
                    :rules="rules.examTypeRules"
                    :label="$t(myName + '.examType')"
                    item-text="text"
                    required
                    clearable
                    outlined
                    dense
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examDate -->
                  <mydatepicker
                    class="mx-0 px-0"
                    v-model="editedItem.examDate"
                    :label="$t(myName + '.examDate')"
                    min="2020-01-01"
                    :max="$moment($moment.now()).add(5, 'year').format('YYYY-MM-DD')"
                    :futureallowed="true"
                    :rules="rules.examDateRules"
                    clearable
                    outlined
                  ></mydatepicker>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===partExam -->
                  <v-checkbox
                    v-model="partExam"
                    :label="$t(myName + '.partExam')"
                    required
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-card v-if="partExam">
                <v-card-text>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <!--===partExam -->
                      <v-radio-group
                        v-model="editedItem.partExam"
                        :mandatory="false"
                        :rules="rules.partExamRules"
                        row
                      >
                        <v-radio
                          :label="$t(myName + '.orally')"
                          value="speaking"
                        ></v-radio>
                        <v-radio
                          :label="$t(myName + '.written')"
                          value="writing"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <!--===lastMemberNr -->
                      <mytextfield
                        v-model="editedItem.lastMemberNr"
                        :label="$t(myName + '.lastMemberNr')"
                        :rules="rules.lastMemberNrRules"
                      ></mytextfield>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <!--  others card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t(myName + ".others") }} </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===accommodationRequest -->
                  <v-select
                    v-model="editedItem.accommodationRequest"
                    :items="itemAccommodationRequest"
                    :label="$t(myName + '.accommodationRequest')"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===newsletterSubscribe -->
                  <v-select
                    v-model="editedItem.newsletterSubscribe"
                    :items="itemNewsletterSubscribe"
                    :label="$t(myName + '.newsletterSubscribe')"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" class="my-0 py-0">
                  <mytextarea
                    v-model="editedItem.description"
                    :label="$t(myName + '.description')"
                  ></mytextarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
    <!--      buttons-->
    <mysavebtn
      :disabled="!valid"
      @submit="submit"
      @clear="clear"
      :savetext="$t(myName + '.register')"
      :savetooltiptext="$t(myName + '.register')"
      :cleartext="$t(myName + '.reset')"
      :cleartooltiptext="$t(myName + '.reset')"
    ></mysavebtn>

    <mywarningdialog
      v-model="warningDialog"
      :text="warningText"
      @ok="warningOk"
      persistent
    ></mywarningdialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import Helper from '../../res/js/Helper'

export default {
  name: "TelcRegisterForm",
  components: {
    // date_picker,
  },
  data() {
    return {
      myName: "TelcMember",
      valid: true,
      partExam: false,
      passed: false,
      paid: false,
      titleItems: ["Prof.", "Dr.", "-"],
      itemAccommodationRequest: [
        "-",
        "WG-Zimmer",
        "Einzelappartement",
        "Einzimmerwohnung",
        "Zweizimmerwohnung",
        "Dreizimmerwohnung",
      ],
      itemNewsletterSubscribe: ["ja", "nein", "-"],

      states: [
        "Afghanistan",
        "Ägypten",
        "Albanien",
        "Algerien",
        "Andorra",
        "Angola",
        "Antarktis",
        "Antigua und Barbuda",
        "Äquatorialguinea",
        "Argentinien",
        "Armenien",
        "Aserbaidschan",
        "Äthiopien",
        "Australien",
        "Bahamas",
        "Bahrain",
        "Bangladesch",
        "Barbados",
        "Belgien",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivien",
        "Bosnien und Herzegowina",
        "Botsuana",
        "Brasilien",
        "Brunei",
        "Bulgarien",
        "Burkina Faso",
        "Birma",
        "Burundi",
        "Chile",
        "China",
        "Cookinseln",
        "Costa Rica (cta)",
        "Dänemark",
        "Deutschland",
        "Dominica",
        "Dominikanische Republik",
        "Dschibuti",
        "Ecuador",
        "Elfenbeinküste",
        "El Salvador",
        "Eritrea",
        "Estland",
        "Falklandinseln",
        "Fidschi",
        "Finnland",
        "Föderierte Staaten von Mikronesien",
        "Frankreich",
        "Französisch Guayana",
        "Gabun",
        "Gambia",
        "Georgien",
        "Ghana",
        "Grenada",
        "Griechenland",
        "Großbritannien",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Indien",
        "Indonesien",
        "Irak",
        "Iran",
        "Irland",
        "Island",
        "Israel",
        "Italien",
        "Jamaika",
        "Japan",
        "Jemen",
        "Jordanien",
        "Kambodscha",
        "Kamerun",
        "Kanada",
        "Kap Verde",
        "Kasachstan",
        "Katar",
        "Kenia",
        "Kirgisistan",
        "Kiribati",
        "Kolumbien",
        "Komoren",
        "Kongo (Demokratische Republik)",
        "Kongo (Republik) Kosovo",
        "Kroatien",
        "Kuba",
        "Kuwait",
        "Laos",
        "Lesotho",
        "Lettland",
        "Libanon",
        "Liberia",
        "Libyen",
        "Liechtenstein",
        "Litauen",
        "Luxemburg",
        "Madagaskar",
        "Malawi",
        "Malaysia",
        "Malediven",
        "Mali",
        "Malta",
        "Marokko",
        "Marshallinseln",
        "Mauretanien",
        "Mauritius",
        "Mazedonien",
        "Mexiko",
        "(Föderierte Staaten von) Mikronesien",
        "Moldawien",
        "Monaco",
        "Mongolei",
        "Montenegro",
        "Mosambik",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Neuseeland",
        "Nicaragua",
        "Niederlande",
        "Niger",
        "Nigeria",
        "Nordkorea",
        "Nordzypern",
        "Norwegen",
        "Oman",
        "Österreich",
        "Pakistan",
        "Palau",
        "Palästina",
        "Panama",
        "Papua-Neuguinea",
        "Paraguay",
        "Peru",
        "Philippinen",
        "Polen",
        "Portugal",
        "Ruanda",
        "Rumänien",
        "Russland",
        "Saint Kitts und Nevis",
        "Saint Lucia",
        "Saint Vincent und die Grenadinen",
        "Salomonen",
        "Sambia",
        "Samoa",
        "San Marino",
        "São Tomé und Príncipe",
        "Saudi-Arabien",
        "Senegal",
        "Serbien",
        "Seychellen",
        "Sierra Leone",
        "Singapur",
        "Simbabwe",
        "Slowakei",
        "Slowenien",
        "Somalia",
        "Spanien",
        "Sri Lanka",
        "Südafrika",
        "Sudan",
        "Südsudan",
        "Südkorea",
        "Surinam",
        "Svalbard",
        "Swasiland",
        "Schweden",
        "Schweiz",
        "Syrien",
        "Tadschikistan",
        "Taiwan",
        "Tansania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad und Tobago",
        "Tschad",
        "Tschechien",
        "Tunesien",
        "Türkei",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "Ungarn",
        "Uruguay",
        "Usbekistan",
        "Vanuatu",
        "Vatikan",
        "Venezuela",
        "Vereinigte Arabische Emirate",
        "Vereinigtes Königreich",
        "Vereinigte Staaten von Amerika",
        "Vietnam",
        "Weißrussland",
        "Westsahara",
        "Zentralafrikanische Republik",
        "Zypern",
      ],

      warningDialog: false,
      warningMode: "ok", //ok, error
      warningText: "",
      examDescription: "",
      examDescriptionDisabled: false,
      itemExamDate: [],
      examDateDescriptionDisabled: false,
      examDateDescription: [],

      readingGrades: 0,
      languageStructureGrades: 0,
      listeningGrades: 0,
      writingGrades: 0,
      speakingGrades: 0,
      // showAlertAfterSubmit: false,
    };
  },
  computed: {
    ...mapGetters({
      examTypes: "ExamType/getItems",
      examDates: "ExamDate/getItems",
      formActive: "Language/getFormActive",
    }),

    editedIndex() {
      return this.$store.getters[`${this.myName}/getEditedIndex`];
    },

    editedItem() {
      let temp = this.$store.getters[`${this.myName}/getEditedItem`];
      // console.log(' edited Item: ', temp);
      return temp;
    },

    rules() {
      let rules = {
        genderRules: [(v) => !!v || this.$t(this.myName + ".rules.genderRules")],
        firstNameRules: [
          (v) => !!v || this.$t(this.myName + ".rules.firstNameRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.firstNameRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.firstNameRules2"),
        ],
        lastNameRules: [
          (v) => !!v || this.$t(this.myName + ".rules.lastNameRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.lastNameRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.lastNameRules2"),
        ],
        EmailRules: [
          (v) => !!v || this.$t(this.myName + ".rules.EmailRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.EmailRules1"),
          (v) =>
            /^[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*@[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*(\.[a-zA-Z0-9äöüÄÖÜß]{2,3})+$/.test(
              v
            ) || this.$t(this.myName + ".rules.EmailRules2"),
        ],
        BirthdayRules: [(v) => !!v || this.$t(this.myName + ".rules.BirthdayRules")],
        examDateRules: [
          (v) => !!v || this.$t(this.myName + ".rules.examDateRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.examDateRules1"),
          (v) => (v && v.length <= 10) || this.$t(this.myName + ".rules.examDateRules2"),
        ],
        examTypeRules: [(v) => !!v || this.$t(this.myName + ".rules.examTypeRules")],
        lastMemberNrRules: [
          (v) => !!v || this.$t(this.myName + ".rules.lastMemberNrRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.lastMemberNrRules1"),
          (v) =>
            (v && v.length <= 50) || this.$t(this.myName + ".rules.lastMemberNrRules2"),
        ],
        partExamRules: [(v) => !!v || this.$t(this.myName + ".rules.partExamRules")],
      };
      return this.formActive ? rules : {};
    },

    formatedItemsExamType() {
      let temp = this.$store.getters[`ExamType/formatedItems`];
      // console.log(' formatedItemsExamType Item: ', temp);
      return temp;
    },

    formatedItemExamDate() {
      return this.$store.getters[`ExamDate/formatedItems`];
    },

    maxBirthday() {
      return this.$moment(new Date()).subtract(3, "years").format("YYYY-MM-DD");
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.examTypes.length === 0) {
        this.$store.dispatch("ExamType/selectItems");
      }
      if (this.examDates.length === 0) {
        this.$store.dispatch("ExamDate/selectItems");
      }
      // if (Helper.isEmpty(this.editedItem)) {
      if (this.isEmpty(this.editedItem)) {
        // this.$router.push({path: 'telcmember'});
        this.goToPage("telcmember");
      } else {
        if (this.editedItem.passed == "1") {
          this.passed = true;
        }
        if (this.editedItem.paid == "1") {
          this.paid = true;
        }
        let gradesArray = this.editedItem.grades.split(";");
        if (gradesArray.length == 5) {
          this.readingGrades = gradesArray[0].substring(gradesArray[0].indexOf(":") + 1);
          this.languageStructureGrades = gradesArray[1].substring(
            gradesArray[1].indexOf(":") + 1
          );
          this.listeningGrades = gradesArray[2].substring(
            gradesArray[2].indexOf(":") + 1
          );
          this.writingGrades = gradesArray[3].substring(gradesArray[3].indexOf(":") + 1);
          this.speakingGrades = gradesArray[4].substring(gradesArray[4].indexOf(":") + 1);
        }
      }
    },

    clear() {
      this.$refs.form.reset();
      // this.showAlert(false);
    },

    close() {
      this.$emit("change");
    },

    submit() {
      // this.showAlertAfterSubmit = true;
      console.log(" editedItem.examType", this.editedItem);
      console.log("type of editedItem.examType", typeof this.editedItem.examType);
      if (this.$refs.form.validate()) {
        if (this.editedIndex >= 0) {
          this.editedItem.id = this.editedIndex;
        }
        this.editedItem.grades =
          "reading:" +
          this.readingGrades +
          " ;languageStructure:" +
          this.languageStructureGrades +
          ";listening:" +
          this.listeningGrades +
          ";writing:" +
          this.writingGrades +
          ";speaking:" +
          this.speakingGrades;

        if (typeof this.editedItem.examType == "object") {
          this.editedItem.examType = this.editedItem.examType.value;
        }
        // this.showAlertAfterSubmit = false;
        this.valid = false;
        this.$store
          .dispatch(`${this.myName}/updateItem`, this.editedItem)
          .then((res) => {
            console.log(" res . ..", res);
            this.$store.dispatch(`${this.myName}/selectItems`);
            // if (res.data > 0) {
            //   this.warningMode = "ok";
            //   this.warningModeChange();
            // } else {
            //   this.warningMode = "error";
            //   this.warningModeChange();
            // }

            this.$router.back();
            // this.goToPage('telcmember');
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$toast.error(this.$t(this.myName + ".alertMessage1"), {
          timeout: 10000,
        });
        let inputs = this.$refs.form.inputs;
        for (let i = 0; i < inputs.length; i++) {
          if (!inputs[i].valid) {
            if (typeof inputs[i].onFocus == "function") {
              inputs[i].onFocus();
            } else {
              window.scrollTo(0, inputs[i].$el.offsetTop);
            }
            break;
          }
        }
      }
    },
    warningModeChange() {
      if (this.warningMode === "ok") {
        if (this.editedItem.id > -1) {
          this.warningText = this.$t(this.myName + ".warningDialogUpdate");
        } else {
          this.warningText = this.$t(this.myName + ".warningDialogtext");
        }
      } else {
        this.warningText = this.$t(this.myName + ".warningDialogtextErorr");
      }
      this.warningDialog = true;
    },

    warningOk() {
      if (this.warningMode === "ok") {
        if (this.editedIndex > -1) {
          // this.$router.push({path: 'telcmember'})
          this.$router.back();
        } else {
          // location.replace('http://diwan-marburg.de');
        }
      } else {
        //this.clear();
      }
    },

    // showAlert(show) {
    //   let alertObj = {
    //     text: this.$t(this.myName + '.alertMessage1'),
    //     color: "red",
    //     timeout: -1,
    //     alertShow: show,
    //   };
    //   this.$store.dispatch('MyAlert/setAlert', alertObj);
    // },

    onPaste(evt) {
      let temp = evt.clipboardData.getData("text");

      let re = /^\+?[0-9]*$/gi;
      if (!temp.match(re)) {
        evt.preventDefault();
      }
    },

    // isNumber(evt) {
    //   evt = (evt) ? evt : window.event;
    //   var charCode = (evt.which) ? evt.which : evt.keyCode;
    //   if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 43) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
  },

  watch: {
    partExam() {
      if (this.partExam === true) {
        this.editedItem.partExam = "speaking";
      } else {
        this.editedItem.partExam = "";
      }
    },

    editedIndex() {
      if (this.editedIndex === -1) {
        this.clear();
      }
    },
    // valid() {
    //   if (this.valid === false && this.showAlertAfterSubmit) {
    //     this.showAlert(true);
    //   } else {
    //     this.showAlert(false);
    //   }
    // },

    // "editedItem.examDate" () {
    //   console.log(' editedItem.examDate',this.editedItem.examDate);
    // },

    // "editedItem.birthday"() {editedIndex
    //   // console.log(' birthday:',this.editedItem.birthday);
    // },

    passed() {
      if (this.passed) {
        this.editedItem.passed = "1";
      } else {
        this.editedItem.passed = "0";
      }
    },

    paid() {
      if (this.paid) {
        this.editedItem.paid = "1";
      } else {
        this.editedItem.paid = "0";
      }
    },

    // "editedItem.passed"() {
    //   console.log('telcMemberSave passed:', this.editedItem.passed);
    // },
    editedItem() {
      console.log("telcMemberSave editedItem :", this.editedItem);
      if (this.editedItem.passed == "1") {
        this.passed = true;
      }
      if (this.editedItem.paid == "1") {
        this.paid = true;
      }
    },
  },
};
</script>

<style scoped></style>
