<template>
  <mydatatable
    :name="myName"
    :savedata="saveData"
    show-edit-btn
    show-delete-btn
    show-excel-btn
    show-group-delete-btn
    show-group-edit-btn
    newpage="/"
    updatepage="telcmembersave"
    :btn-action="myActionBtn"
    :btn-main-action="myActionMainBtn"
    :select-items-fun-name="selectItemsFunName"
  ></mydatatable>
</template>

<script>
export default {
  name: "TelcMember",
  path: "/:id",
  meta: {
    requiresAuth: true,
    is_access: true,
  },
  data: () => ({
    myName: "TelcMember",
    saveData: false,
    selectItemsFunName: "",
  }),

  computed: {
    myActionBtn() {
      let item = [
        {
          iconName: "mdi-email-outline",
          color: "teal lighten-1",
          textValue: this.myName + ".sendInvitationEmailBtn",
          method: this.sendInvitationEmail(),
        },
        // {
        //   iconName: "mdi-email-outline",
        //   color: "teal lighten-1",
        //   textValue: "test toast",
        //   method: this.testAlert(),
        //   method:
        //      "() => {this.$store.dispatch(`${this.myName}/sendInvitationEmail`, params.data.id);}",

        //   method: () => {
        //      console.log(" my test btn  ");
        // },
        // },

        // {
        //   iconName: "mdi-email-outline",
        //   color: "teal lighten-1",
        //   textValue: "test snackbar",
        //   method: this.testSnackbar(),
        // },
      ];
      return item;
    },

    myActionMainBtn() {
      let item = [
        {
          iconName: "mdi-email-outline",
          color: "teal lighten-1",
          textValue: this.myName + ".sendInvitationEmailBtn",
          method: this.sendGroupInvitationEmail(),
        },
      ];
      return item;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.key += 1;
      // console.log(" $router.params.id ", this.$router.currentRoute.params.id );
      if (this.$router.currentRoute.params.id == "marburg") {
        this.selectItemsFunName = "selectMarburg";
      } else if (this.$router.currentRoute.params.id == "bonn") {
        this.selectItemsFunName = "selectBonn";
      } else {
        this.selectItemsFunName = "getVueTable";
      }
      this.$store.dispatch(`${this.myName}/${this.selectItemsFunName}`);
    },

    save() {
      this.saveData = !this.saveData;
    },

    sendInvitationEmail() {
      let myFun = `async () => {
        let confirm = await this.myConfirm(this.$t(this.myName +'.sendInvitationEmail'));
        if (confirm) {
          this.$store.dispatch(this.myName+'/sendInvitationEmail', params.data.id)
          .then(() => {
              this.refreshItems();
            });
        }
      }`;
      return myFun;
    },

    sendGroupInvitationEmail() {
      let myFun = `async () => {
        let countSelected = this.selectedItem.length;
        console.log(" countSelected : ", countSelected);
        if(countSelected > 0){
          let confirm = await this.myConfirm(this.$t(this.myName +'.sendInvitationEmail'));
          if (confirm) {
            let ids = this.selectedItem.map((a) => a.id);
            console.log(' send Email an alle', ids);
            this.$store.dispatch(this.myName+'/sendInvitationEmail', JSON.stringify(ids))
            .then(() => {
              this.refreshItems();
            });
          }
        } else {
          let alert = {
            text: this.$t(this.myName +'.noSelectedItem'),
            type: "error",
            color: "red",
            timeout: 5000,
            alertShow: true,
          };
          this.$store.dispatch("MyAlert/setAlert", alert);
        }
      }`;
      return myFun;
    },

    // testAlert() {
    //   var func = () => {
    //     this.$store.dispatch("MyAlert/setShowAlert", false);
    //     let alert = {
    //       text: "my test",
    //       type: "error",
    //       color: "red",
    //       timeout: 5000,
    //       alertShow: true,
    //     };
    //     this.$store.dispatch("MyAlert/setAlert", alert);
    //   };
    //   return func;
    // },

    // testSnackbar() {
    //   let strFun = "async () => {";
    //   strFun +=
    //     'let confirm = await this.myConfirm("Aya mikhahid in " + params.data.id + " ra pak konid?");' +
    //     " console.log(' tesSnan: ' , confirm);" +
    //     "};";

    //   return strFun;
    // },
  },
};
</script>

<style scoped></style>
