/*jshint esversion: 6 */
// import scrollLock from "scroll-lock";
// import langMessage from "../language/locales/en.js";
import { i18n } from "../language/i18n";
// import Injector from "vue-inject";
import store from "../../store/index.js";
import router from "../../res/router";
// const moment = require('moment');

const Helper = {

    getClick() {
        return new Promise(acc => {
            function handleClick() {
                document.removeEventListener('click', handleClick);
                acc();
            }
            document.addEventListener('click', handleClick);
        });
    },


    async eventControl(fun = '') {
        if (fun == '') {
            for (let i = 0; i < 4; i++) {
                console.log("waiting for a click", i);
                await this.getClick();
                console.log("click received", i);
            }
        } else {
            if (typeof fun == 'function') {
                // fun();
                while (fun()) {
                    await this.getClick();
                }
                return new Promise((resolve) => {
                    resolve('true');
                })
            }
        }
        console.log("done");
    },

    async myConfirm(text = "my test") {
        store.dispatch("MySnackbar/setAnswer", -1);
        store.dispatch("MySnackbar/setShowSnackbar", false);
        // store.dispatch("MySnackbar/setShowSnackbar", true);
        let snackbarObj = {
            text: text,
            type: "error",
            color: "light-blue lighten-5",
            timeout: -1,
            snackbar: true,
        };
        store.dispatch("MySnackbar/setSnackbar", snackbarObj);
        let fun = () => {
            let answer = store.getters["MySnackbar/getAnswer"];
            if (answer >= 0) {
                return false;
            } else {
                return true;
            }
        }

        return await this.eventControl(fun)
            .then(() => {
                let answer = store.getters["MySnackbar/getAnswer"];
                if (answer == 1) {
                    return true;
                } else if (answer == 0) {
                    return false;
                } else {
                    return false;
                }
            });
    },


    leerAllTablesItems() {
        let storeActions = Object.keys(store._actions);
        let allSetItems = storeActions.filter((word) => word.includes("setItems"));
        for (let i = 0; i < allSetItems.length; i++) {
            let temp = [];
            store.dispatch(allSetItems[i], temp);
        }
    },

    goToPage(path) {
        if (typeof path == "string") {
            path = "/" + path;
            if (router.history.current.path !== path) {
                router.push(path);
            }
        } else if (typeof path == "object") {
            router.push(path);
            // router.push({ name: 'user', params: { username: 'erina' } })
        }
    },

    setIntervalJob(intrvName, intrvCount, intrvFunction) {
        store.dispatch("interval/runInterval");
        let intervalJob = {
            name: intrvName,
            count: intrvCount,
            doFunc: intrvFunction,
        };
        store.dispatch("interval/addDoJobs", intervalJob);
    },

    isJson(text) {
        try {
            return JSON.parse(text);
        } catch {
            return false;
        }
    },

    isEmpty(value) {
        if (value === "" || value === "") {
            return true;
        }
        if (value === null) {
            return true;
        }
        if (value === undefined) {
            return true;
        }
        if (typeof value === "object") {
            if (value.length === 0) {
                return true;
            }
            if (Object.keys(value).length === 0) {
                return true;
            }
        }
        return false;
    },

    consoleLog(msg) {
        // let user =  vuex.Store;
        let log = localStorage.getItem("log");
        let showLog = false;
        if (this.isDeveloper()) {
            showLog = true;
        }
        if (!this.isEmpty(log)) {
            log = log.toLowerCase();
            if (log === "true") {
                showLog = true;
            }
        }

        if (showLog) {
            console.log(msg);
        }
    },

    getBrowserAddressbarInfo(str = "") {
        let addressbarInfo = window.location.href;
        if (str != "") {
            addressbarInfo = addressbarInfo.substring(
                addressbarInfo.indexOf(str) + str.length + 2
            );
        } else {
            addressbarInfo = addressbarInfo.substring(addressbarInfo.indexOf("?") + 1);
        }
        let getAddresbarInfoArry = addressbarInfo.split("&");
        let headerInfo = {};
        for (let i = 0; i < getAddresbarInfoArry.length; i++) {
            let temp = getAddresbarInfoArry[i].split("=");
            headerInfo[temp[0]] = temp[1];
        }
        return headerInfo;
    },

    copyToClipboard(val) {
        const el = document.createElement("textarea");
        el.value = val;
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);
    },

    isDeveloper() {
        let userDeveloper = store.getters["Login/getUser"];
        if (!this.isEmpty(userDeveloper.developer)) {
            // console.log(' isDeveloper' , userDeveloper.developer);
            if (userDeveloper.developer) {
                return true;
            }
        }
        // if (process.env.NODE_ENV === "development" ) {
        //   return true;
        // }
        return false;
    },

    makeTableHeader(tableName, headerField) {
        let header = [];

        for (let i = 0; i < headerField.length; i++) {
            header.push({
                text: i18n.t(`${tableName}.${headerField[i]}`),
                align: "start",
                sortable: true,
                value: headerField[i],
            });
        }
        header.push({
            text: i18n.t("actions"),
            value: "actions",
            sortable: false,
            width: "2%",
        });
        return header;
    },

    isNumber(evt, specialCharacter = "") {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (specialCharacter.length > 0) {
            let scArray = JSON.parse(JSON.stringify(specialCharacter));
            console.log("gettype :", typeof scArray);
            for (let i = 0; i < scArray.length; i++) {
                if (charCode == scArray[i].charCodeAt(0)) {
                    return true;
                }
            }
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        } else {
            return true;
        }
    },

    makedefaultItem(headerField) {
        let defaultItem = {};
        for (let i = 0; i < headerField.length; i++) {
            defaultItem[headerField[i]] = "";
        }
        return defaultItem;
    },

    searchInArrayOfObject(arr, searchKey, includes = false) {
        if (includes) {
            return arr.filter((obj) =>
                Object.keys(obj).some((key) => obj[key].includes(searchKey))
            );
        } else {
            return arr.filter((obj) => Object.keys(obj).some((key) => obj[key] == searchKey));
        }
    },
    //   deleteLocalStorage(){
    //   localStorage.setItem('firstName', '');
    //   localStorage.setItem('lastName', '');
    //   localStorage.setItem('token', '');
    //   localStorage.setItem('isLogin', 'false');
    // }
    //
    //   setLocalStorage(data) {
    //   localStorage.setItem('firstName', data.firstName);
    //   localStorage.setItem('lastName', data.lastName);
    //   localStorage.setItem('token', data.token);
    //   localStorage.setItem('isLogin', String(data.isLogin));
    // }

    cloneObject(obj) {
        let copy;
        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;
        copy = JSON.stringify(obj);
        return JSON.parse(copy);
    },

    loadjscssfile(filename, filetype) {
        var fileref = "";
        if (filetype == "js") {
            //if filename is a external JavaScript file
            fileref = document.createElement("script");
            fileref.setAttribute("type", "text/javascript");
            fileref.setAttribute("src", filename);
            fileref.setAttribute("async", false);
        } else if (filetype == "css") {
            //if filename is an external CSS file
            fileref = document.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", filename);
        }
        if (fileref != "") document.getElementsByTagName("head")[0].appendChild(fileref);
    },

    removejscssfile(filename, filetype) {
        var targetelement = filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
        var targetattr = filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
        var allsuspects = document.getElementsByTagName(targetelement);
        for (var i = allsuspects.length; i >= 0; i--) {
            //search backwards within nodelist for matching elements to remove
            if (
                allsuspects[i] &&
                allsuspects[i].getAttribute(targetattr) != null &&
                allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
            )
                allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
        }
    },

    addZeroBehind10(num) {
        if (parseInt(num) < 10) {
            num = "0" + num;
        }
        return num;
    },

    //=AlertHandler==========================
    showAlert(app, alertMsg, alertType) {
        app.showAlertDialog = true;
        app.alertType = alertType; //success, info, warning or error
        app.alertMsg = alertMsg;
        if (alertMsg == "Session expired!") {
            setTimeout(function () {
                window.location.href = window.location.origin;
            }, 3000);
        }
    },

    // getCurrentDayOfWeek(day) {
    //     if (day == 1) return langMessage.monday;
    //     else if (day == 2) return langMessage.tuesday;
    //     else if (day == 3) return langMessage.wednesday;
    //     else if (day == 4) return langMessage.thursday;
    //     else if (day == 5) return langMessage.friday;
    //     else if (day == 6) return langMessage.saturday;
    //     else if (day == 0) return langMessage.sunday;
    //     else return "False Number";
    // },

    getCurrentMonthName(monthNumber) {
        //month = 6
        var months = [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
            "False Number",
        ];
        if (monthNumber < 12 && monthNumber >= 0) {
            return months[monthNumber];
        } else {
            return months[12];
        }
    },

    convDate2Day_2(date, separate = "-") {
        if (!date) return null;

        const [year, month, day] = date.split(separate);
        var days = this.convDate2Day(parseInt(year), parseInt(month), parseInt(day));
        return days; // return count of Days
    },

    dateComparison(firstDate, lastDate) {
        var day1 = this.convDate2Day_2(firstDate);
        var day2 = this.convDate2Day_2(lastDate);
        return day2 - day1;
    },

    convDate2Day(year, month, day) {
        var temp = 0;
        year -= 1;
        temp += year * 365;
        temp += parseInt(year / 4);
        year += 1;
        if (month > 0 && month < 13) {
            switch (month) {
                case 1:
                    temp += 0;
                    break;
                case 2:
                    temp += 31;
                    break;
                case 3:
                    temp += 59;
                    break;
                case 4:
                    temp += 90;
                    break;
                case 5:
                    temp += 120;
                    break;
                case 6:
                    temp += 151;
                    break;
                case 7:
                    temp += 181;
                    break;
                case 8:
                    temp += 212;
                    break;
                case 9:
                    temp += 243;
                    break;
                case 10:
                    temp += 273;
                    break;
                case 11:
                    temp += 304;
                    break;
                case 12:
                    temp += 334;
                    break;
            }
        } else {
            return 0;
        }
        if (day > 0 && day < 32) {
            temp += day;
        } else {
            return 0;
        }
        if (year % 4 == 0 && month >= 3) {
            temp += 1;
        }
        return temp;
    },

    setCurrentDateYearMonthDayAsString() {
        // return year + "-" + month + "-" + day;
        var date = new Date();
        return this.setDateYearMonthDayAsString(date);
    },

    setDateYearMonthDayAsString(date) {
        // return year + "-" + month + "-" + day;
        var day = this.addZeroBehind10(date.getDate());
        var month = this.addZeroBehind10(date.getMonth() + 1);
        var year = date.getFullYear();
        var newDate = year + "-" + month + "-" + day;
        return newDate;
    },


    getReadableDateStringFromDate(date) {
        // return day + "." + month + "." + year;
        var day = this.addZeroBehind10(date.getDate());
        var month = this.addZeroBehind10(date.getMonth() + 1);
        var year = date.getFullYear();
        var currentDate = day + "." + month + "." + year;
        return currentDate;
    },

    parseDateStringReturnDate(dateString) {
        var year = dateString.substring(0, 4);
        var month = dateString.substring(5, 7) - 1;
        var day = dateString.substring(8, 10);
        var hour = dateString.substring(11, 13);
        var minute = dateString.substring(14, 16);
        var second = dateString.substring(17, 19);
        var offset = dateString.substring(20, 25);
        var offsetInHours = parseInt(offset) / 100;
        var offsetInMs = offsetInHours * 1000 * 60 * 60;
        return new Date(Date.UTC(year, month, day, hour, minute, second) - offsetInMs);
    },

    parseDate(date) {
        var year = date.substring(0, 4);
        var month = date.substring(5, 7) - 1;
        var day = date.substring(8, 10);
        var hour = date.substring(11, 13);
        var minute = date.substring(14, 16);
        var second = date.substring(17, 19);
        var offset = date.substring(20, 25);
        var offsetInHours = parseInt(offset) / 100;
        var offsetInMs = offsetInHours * 1000 * 60 * 60;
        return new Date(Date.UTC(year, month, day, hour, minute, second) - offsetInMs);
    },

    setCurrentTimeHoursMinuteAsString() {
        //return  hours + ":" + minutes;
        var date = new Date();
        return this.setTimeHoursMinuteAsString(date);
    },

    setTimeHoursMinuteAsString(date) {
        //return  hours + ":" + minutes;
        var hours = this.addZeroBehind10(date.getHours());
        var minutes = this.addZeroBehind10(date.getMinutes());
        var time = hours + ":" + minutes;
        return time;
    },

    convDay2Date2(days) {
        let num = days * 24 * 60 * 60 * 1000;
        return num;
    },

    convDay2Date(days) {
        var kabisyear = false;
        var year = parseInt(days / 365.25);
        days -= parseInt(days / 1461); //(4 * 365 ) + 1 = 1461
        days -= year * 365;
        year += 1;
        if (year % 4 === 0 && days > 59) {
            kabisyear = true;
        }
        var month = 0;
        if (kabisyear) {
            if (days <= 31) month = 1;
            else if (days <= 60) {
                month = 2;
                days -= 31;
            } else if (days <= 91) {
                month = 3;
                days -= 60;
            } else if (days <= 121) {
                month = 4;
                days -= 91;
            } else if (days <= 152) {
                month = 5;
                days -= 121;
            } else if (days <= 182) {
                month = 6;
                days -= 152;
            } else if (days <= 213) {
                month = 7;
                days -= 182;
            } else if (days <= 244) {
                month = 8;
                days -= 213;
            } else if (days <= 274) {
                month = 9;
                days -= 244;
            } else if (days <= 305) {
                month = 10;
                days -= 274;
            } else if (days <= 335) {
                month = 11;
                days -= 305;
            } else if (days <= 366) {
                month = 12;
                days -= 335;
            }
        } else {
            if (days <= 31) month = 1;
            else if (days <= 59) {
                month = 2;
                days -= 31;
            } else if (days <= 90) {
                month = 3;
                days -= 59;
            } else if (days <= 120) {
                month = 4;
                days -= 90;
            } else if (days <= 151) {
                month = 5;
                days -= 120;
            } else if (days <= 181) {
                month = 6;
                days -= 151;
            } else if (days <= 212) {
                month = 7;
                days -= 181;
            } else if (days <= 243) {
                month = 8;
                days -= 212;
            } else if (days <= 273) {
                month = 9;
                days -= 243;
            } else if (days <= 304) {
                month = 10;
                days -= 273;
            } else if (days <= 334) {
                month = 11;
                days -= 304;
            } else if (days <= 365) {
                month = 12;
                days -= 334;
            }
        }

        days = this.addZeroBehind10(days);
        month = this.addZeroBehind10(month);
        return year + "-" + month + "-" + days;
    },

    countOfDaysInMonth(date) {
        //data 2020-01-01
        let standardDateformat = new Date(date);
        let tempDate = this.setDateYearMonthDayAsString(standardDateformat);
        let year = tempDate.substring(0, 4);
        let month = tempDate.substring(5, 2);
        return this.countOfMonth(parseInt(year), parseInt(month));
    },

    countOfMonth(year, month) {
        var temp = 0;
        if (month > 0 && month < 13) {
            switch (month) {
                case 1:
                    temp = 31;
                    break;
                case 2:
                    if (year % 4 === 0) temp = 29;
                    else temp = 28;
                    break;
                case 3:
                    temp = 31;
                    break;
                case 4:
                    temp = 30;
                    break;
                case 5:
                    temp = 31;
                    break;
                case 6:
                    temp = 30;
                    break;
                case 7:
                    temp = 31;
                    break;
                case 8:
                    temp = 31;
                    break;
                case 9:
                    temp = 30;
                    break;
                case 10:
                    temp = 31;
                    break;
                case 11:
                    temp = 30;
                    break;
                case 12:
                    temp = 31;
                    break;
            }
        }
        return temp;
    },

    addDays2Date(date, countday) {
        if (!date) return null;

        const [year, month, day] = date.split("-");
        var days = this.convDate2Day(parseInt(year), parseInt(month), parseInt(day));
        days += countday;
        return this.convDay2Date(days);
    },

    addMonth2Date(date, countMonth) {
        if (!date) return null;

        const [year, month, day] = date.split("-");
        var days = this.convDate2Day(parseInt(year), parseInt(month), parseInt(day));
        var plusDay = 0;
        if (countMonth > 0) {
            plusDay = this.countOfMonth(parseInt(year), parseInt(month));
        } else {
            if (parseInt(month) === 1) {
                plusDay = this.countOfMonth(parseInt(year) - 1, 12);
            } else {
                plusDay = this.countOfMonth(parseInt(year), parseInt(month) - 1);
            }
            plusDay *= -1;
        }
        days += plusDay;
        return this.convDay2Date(days);
    },

    formatDate(date = "", separate = ".") {
        if (date == "") {
            date = new Date().toISOString().split("T")[0];
        }
        if (!date) return null;

        const [year, month, day] = date.split("-");
        if (typeof day === "undefined") {
            return month + separate + year;
        } else {
            return day + separate + month + separate + year;
        }
    },

    isNumberKey(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46);
    },

    convTime2Minit2(hour, minit) {
        if (isNaN(hour)) hour = 0;
        if (isNaN(minit)) minit = 0;
        return hour * 60 + minit;
    },

    convTime2Minit(time) {
        if (time == null) time = "00:00";
        var res = time.split(":");
        var allMinit = this.convTime2Minit2(parseInt(res[0]), parseInt(res[1]));
        return allMinit;
    },

    //   changeAnimationClass(oldclass, newclass, id, callback) {
    //   $("#" + id)
    //     .removeClass(oldclass)
    //     .addClass(newclass);
    //   $("#" + id).on("animationend", function() {
    //     callback();
    //   });
    // }
    convMinit2Time(minit, separate = ":") {
        if (minit < 0) {
            minit *= -1;
            minit = minit % 1440; //24 * 60 = 1440
            minit = 1440 - minit;
        }
        var h = parseInt(minit / 60);
        var m = minit - h * 60;
        h = this.addZeroBehind10(h);
        m = this.addZeroBehind10(m);
        return h + separate + m;
    },

    addMinit2Time(time, minit, limitTimeMin, limitTimeMax) {
        var allMinit = this.convTime2Minit(time);
        var Lmin = this.convTime2Minit(limitTimeMin);
        var Lmax = this.convTime2Minit(limitTimeMax);
        if (isNaN(allMinit)) allMinit = 0;
        else allMinit += minit;

        if (Lmax != 0 && Lmax - Lmin >= 0) {
            if (allMinit >= Lmin && allMinit <= Lmax) return this.convMinit2Time(allMinit);
            else {
                if (allMinit < Lmin) {
                    allMinit = Lmin;
                    return this.convMinit2Time(allMinit);
                } else if (allMinit > Lmax) {
                    allMinit = Lmax;
                    return this.convMinit2Time(allMinit);
                }
            }
        } else if (Lmin > 0 && allMinit < Lmin) {
            allMinit = Lmin;
            return this.convMinit2Time(allMinit);
        } else {
            return this.convMinit2Time(allMinit);
        }
    },

    difBtwTimes(time1, time2) {
        //differenceBetweenTimes
        var allMinit1 = this.convTime2Minit(time1);
        var allMinit2 = this.convTime2Minit(time2);
        if (allMinit1 <= allMinit2) {
            return this.convMinit2Time(allMinit2 - allMinit1);
        } else {
            return this.convMinit2Time(allMinit1 - allMinit2);
        }
    },

    add2Times(time1, time2) {
        var allMinit1 = this.convTime2Minit(time1);
        var allMinit2 = this.convTime2Minit(time2);

        return this.convMinit2Time(allMinit2 + allMinit1);
    },

    test() {
        console.log(" helpertesttest");
    },

    convertNumber2Date(num) {
        // var date = new Date(1546108200 * 1000);
        if (num < 999999999) {
            num *= 1000;
        }
        var date = new Date(num);
        return date;
    },

    convertDate2Number(date = "") {
        let time = 1000;
        if (date == "") {
            time = new Date().getTime();
        } else {
            time = new Date(date).getTime();
        }

        if (time > 1000) {
            return time / 1000;
        } else {
            return 0;
        }
    },

    convertNumber2DateTest() {
        console.log(" convertNumber2Date 1546108200");
        var date = new Date(1546108200 * 1000);

        console.log(date.toISOString().split("T")[0]); // yyyy-mm-dd return 2018-12-29
        let time = new Date(date).getTime();
        console.log(" time : ", time); //return  time :  1546108200000

        let date2 = new Date("2018-12-29");
        console.log("   data2 : ", this.getDateFormat(date2));
    },
    getDateFormat(date = "", format = "yyyy-mm-dd") {
        if (date == "") {
            date = new Date();
        } else {
            date = new Date(date);
        }
        if (!date) return null;

        let result = "";
        if (format == "yyyy-mm-dd") {
            result = date.toISOString().split("T")[0];
        } else if (format == "dd-mm-yyyy") {
            result = this.setDate(date, "-", format);
        }
        return result;
    },

    getTimeFormat(date = "", separate = ":", format = "HH:mm") {
        if (date == "") {
            date = new Date();
        } else {
            date = new Date(date);
        }
        if (!date) return null;
        let result = date.toISOString().split("T")[1];
        if (format == "HH:mm") {
            result = result.substring(0, 5);
        } else if (format == "dd-mm-yyyy") {
            result = this.setDate(date, separate, format);
        }
        return result;
    },

    setCurrentDate() {
        // return year + "-" + month + "-" + day;
        var date = new Date();
        return this.setDate(date);
    },

    setDate(date, separate = "-", format = "yyyy-mm-dd") {
        console.log(' data : ', date);
        console.log('type data : ', typeof data);
        // return year + "-" + month + "-" + day;
        let day = this.addZeroBehind10(date.getDate());
        let month = this.addZeroBehind10(date.getMonth() + 1);
        let year = date.getFullYear();
        let newDate = "";
        if (format == "yyyy-mm-dd") {
            newDate = year + separate + month + separate + day;
        } else if (format == "dd-mm-yyyy") {
            newDate = day + separate + month + separate + year;
        }

        return newDate;
    },

};

export default Helper;
