import PHPServer from '../../res/services/postToPHPServer';

const myFunctions = {
    myName: 'AllTables',
    myTableName: 'tbl_AllTables',
    myState() {
        return {
            myAllTables: [],
        }
    },
    myGetter() {
        return {
            getMyAllTables: state => state.myAllTables,
            
        }
    },
    myAction() {
        return {
            getAllTables({state}) { 
                let data = {
                    myFunctionName: 'getMyAllTables',
                }
                return PHPServer.myFunction(state.tableName, data)
                .then((res) => {
                    let data = res.data;
                    // console.log('getAllTables :: ' , data);
                    if (data.length > 0) {
                        state.myAllTables = data;
                    }
                });
            },

        }
    },

    myMutation() {
        return {
            
        }
    }
};

export default myFunctions;
