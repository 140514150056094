<template>
  <v-container>
    <v-card class="mb-2 px-0" elevation="10">
      <v-card-title class="headline">
        <v-row justify="center">
          {{ $t("Menu.menu") }}
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-row justify="center">
            <v-col v-for="n in pages" :key="n.id" cols="auto">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? 24 : 6"
                    height="100"
                    width="200"
                    color="light-blue lighten-1"
                    @click="onBtnClick(n.path)"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                      v-text="n.name"
                    ></v-row>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Menu",
  meta: {
    requiresAuth: true,
    is_access: true,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
    }),
    pages() {
      let pages = [
        { id: 1, name: this.$t("TelcMember.TelcMember"), path: "telcmember/all" },
        // { id: 2, name: this.$t("TelcMember.TelcMember"), path: { name: 'TelcMember', params: { id: 'erina' } }},
        { id: 3, name: this.$t("TelcMember.TelcMemberMarburg"), path: "telcmember/marburg" },
        { id: 4, name: this.$t("TelcMember.TelcMemberBonn"), path: "telcmember/bonn" },
        { id: 5, name: this.$t("ExamDate.ExamDate"), path: "examdate" },
        { id: 6, name: this.$t("ExamType.ExamType"), path: "examtype" },
      ];

      return this.formActive ? pages : [];
    },
  },
  methods: {
    onBtnClick(path) {
      this.goToPage(path);
      // this.$router.push({path: path})
    },
  },
};
</script>

<style scoped></style>
