import PHPServer from '../../res/services/postToPHPServer';

const myName = 'serverSetup';

const state = {
    headerImage: '',
};

const getters = {
    getHeaderImage: state => state.headerImage,

};

const actions = {//dispatch 
    // eslint-disable-next-line  
    setHeaderImage() {
        if (state.headerImage == '') {
            let data = {
                myFunctionName: 'getHeaderImage',
            }
            return PHPServer.myFunction('', data)
                // return PHPServer.selectItems(state.tableName)
                .then((res) => {
                    // console.log('setHeaderImage: ', res);
                    let items = res.data;
                    if (items.length > 0) {
                        state.headerImage = items
                    }
                    else {
                        state.items = items;
                    }
                })
        }

    },

};

const mutations = {//commit 
};

export default {
    myName,
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};