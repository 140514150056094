<template>
  <mydatatable
    :name="myName"
    :savedata="saveData"
    show-add-btn
    show-edit-btn
    show-delete-btn
    show-excel-btn
  >
    <examDateSave @change="save"></examDateSave>
  </mydatatable>
</template>

<script>
import examDateSave from "../examDate/ExamDateSave";

export default {
  name: "ExamDate",
  meta: {
    requiresAuth: true,
    is_access: true,
  },
  components: {
    examDateSave,
  },
  data() {
    return {
      myName: "ExamDate",
      saveData: false,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch(`${this.myName}/getVueTable`);
    },
    save() {
      this.saveData = !this.saveData;
    },
  },
};
</script>

<style scoped></style>
