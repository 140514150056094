<template>
  <!--  <v-container>-->
  <div @click="btnClick" v-bind="bind" v-on="on" style="cursor: pointer">
    <div v-if="tooltiptext !== ''">
      <v-tooltip bottom :color="tooltipcolor">
        <template slot="activator" slot-scope="{ on }">
          <v-btn
            ref="ref"
            v-on="on"
            :disabled="disabled"
            :color="color"
            :icon="iconname !== ''"
            :x-small="xsmall"
            :small="small"
            :large="large"
            :x-large="xlarge"
            :fab="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :absolute="absolute"
          >
            <div v-if="iconname === ''">{{ label }}</div>
            <div v-else>
              <v-icon
                :color="color"
                :x-small="xsmall"
                :small="small"
                :large="large"
                :x-large="xlarge"
                >{{ iconname }}</v-icon
              >
            </div>
          </v-btn>
        </template>
        <span class="black--text">{{ tooltiptext }}</span>
      </v-tooltip>
    </div>
    <div v-if="tooltiptext === ''">
      <v-btn
        ref="ref"
        :disabled="disabled"
        :color="color"
        :icon="iconname !== ''"
        :x-small="xsmall"
        :small="small"
        :large="large"
        :x-large="xlarge"
        :fab="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :absolute="absolute"
      >
        <div v-if="iconname === ''">{{ label }}</div>
        <div v-else>
          <v-icon :color="color">{{ iconname }}</v-icon>
        </div>
      </v-btn>
    </div>
  </div>
  <!--  </v-container>-->
</template>

<script>
export default {
  name: "MyBtn",
  data() {
    return {
      clickCount: 0,
    };
  },

  props: {
    bind: {},
    on: {},
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    tooltipcolor: {
      type: String,
      default: "primary",
    },
    tooltiptext: {
      type: String,
      default: "",
    },
    iconname: {
      type: String,
      default: "",
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xlarge: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    btnClick() {
      if (!this.disabled) {
        this.clickCount++;
        this.doClick();
        this.timeout = setTimeout(() => {
          this.clickCount = 0;
        }, 1000);
      }
    },

    doClick() {
      if (this.clickCount == 1) {
        this.$emit("click");
      } else if (this.clickCount == 2) {
        this.$emit("doubleclick");
      }
    },
  },

  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
    iconname() {
      console.log(" iconname ::>>:: ", this.iconname);
    },
  },
};
</script>

<style scoped></style>
