<template>
  <v-container>
    <div v-if="paidFor">
      <h1>nice!</h1>
    </div>

    <div ref="paypal"></div>
    <!-- <div id="paypal-button-container"></div> -->
  </v-container>
</template>

<script>
export default {
  name: "myPayPalBtn",
  data() {
    return {
      loaded: false,
      paidFor: false,
      product: {
        price: 5,
        description: "tole Artikel",
      },
    };
  },

  mounted() {
    const script = document.createElement("script");
    const clientId =
      "AfpHKnLeIfET7Ji4XMOiDzPpF2brlH8csiP1bgFKgVsuzX73DMsIoI5a65YkmzP-HIU6C1aknLtOFszY";
    // const currency = "USD";
    const currency = "EUR";
    script.src =
      "https://www.paypal.com/sdk/js?client-id=" + clientId + "&currency=" + currency;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);

    // Render the PayPal button
    // paypal
    //   .Buttons({
    //     // Customize the payment options, such as the amount, currency, etc.
    //     createOrder: function (data, actions) {
    //       return actions.order.create({
    //         purchase_units: [
    //           {
    //             amount: {
    //               value: "10.00",
    //             },
    //           },
    //         ],
    //       });
    //     },
    //     // On successful payment
    //     onApprove: function (data, actions) {
    //       console.log(" paypal on Approve data:", data);
    //       console.log(" paypal on Approve actions:", actions);
    //       // Capture the transaction details here
    //       // For example, you can show a success message to the user
    //     },
    //     // On cancel or error
    //     onCancel: function (data) {
    //       console.log(" paypal on cancel data:", data);
    //       // Handle the cancel or error event here
    //     },
    //   })
    //   .render("#paypal-button-container");
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      let vm = this;
      window.paypal
        .Buttons({
          // Customize the payment options, such as the amount, currency, etc.
          createOrder(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: vm.product.description,
                  amount: {
                    value: vm.product.price,
                    // value: '10.00',
                  },
                },
              ],
            });
          },
          // On successful payment
          onApprove(data, actions) {
            console.log(" paypal on Approve data:", data);
            console.log(" paypal on Approve actions:", actions);
            // Capture the transaction details here
            // For example, you can show a success message to the user
          },
          // On cancel or error
          onCancel: function (data) {
            console.log(" paypal on cancel data:", data);
            // Handle the cancel or error event here
          },
        })
        .render(this.$refs.paypal);
    },
  },
};

// Debug ID : f718293760c8d

// data
// billingToken
// : 
// null
// facilitatorAccessToken
// : 
// "A21AAJKvViPnuPpndgTxn3rHugDN2yiVW6wO5PhKSnBmYQe-1kDG71rl1j-JZUoAHeBreEmEZ6Kp9XF0ieR0DIk8G_fiXYwDQ"
// orderID
// : 
// "38857216YS184431M"
// payerID
// : 
// "G52HU8YA2E37W"
// paymentID
// : 
// null
// paymentSource
// : 
// "paypal"

// actions
// order
// : 
// authorize
// : 
// ƒ r()
// capture
// : 
// ƒ r()
// get
// : 
// ƒ r()
// patch
// : 
// ƒ r()
// [[Prototype]]
// : 
// Object
// payment
// : 
// null
// redirect
// : 
// ƒ r()
// fireAndForget
// : 
// ƒ r()
// origin
// : 
// "https://www.sandbox.paypal.com"
// __id__
// : 
// "uid_42b6363801_mtk6mjy6ntg"
// __name__
// : 
// "l"
// __origin__
// : 
// "https://www.sandbox.paypal.com"
// __source__
// : 
// global {0: global, 1: global, window: global, self: global, location: {…}, closed: false, frames: global, …}
// length
// : 
// 0
// name
// : 
// "r"
// prototype
// : 
// {constructor: ƒ}
// arguments
// : 
// (...)
// caller
// : 
// (...)
// [[FunctionLocation]]
// : 
// js?client-id=AfpHKnL…FszY&currency=EUR:3
// [[Prototype]]
// : 
// ƒ ()
// [[Scopes]]
// : 
// Scopes[6]
// restart
// : 
// ƒ r()
// fireAndForget
// : 
// ƒ r()
// origin
// : 
// "https://www.sandbox.paypal.com"
// __id__
// : 
// "uid_3492e6f337_mtk6mjy6ntg"
// __name__
// : 
// "restart::memoized"
// __origin__
// : 
// "https://www.sandbox.paypal.com"
// __source__
// : 
// global {0: global, 1: global, window: global, self: global, location: {…}, closed: false, frames: global, …}



// https://www.sandbox.paypal.com/checkoutweb/signup?
// sessionID=uid_74da8bd3fa_mtu6mzq6mzk
// &buttonSessionID=uid_f2c432e693_mtu6ndc6ndu
// &stickinessID=uid_e2bcb113d3_mty6mtk6mtg
// &smokeHash=
// &fundingSource=paypal
// &buyerCountry=DE
// &locale.x=de_DE
// &commit=true
// &clientID=AfpHKnLeIfET7Ji4XMOiDzPpF2brlH8csiP1bgFKgVsuzX73DMsIoI5a65YkmzP-HIU6C1aknLtOFszY
// &env=sandbox
// &sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWwuY29tL3Nkay9qcz9jbGllbnQtaWQ9QWZwSEtuTGVJZkVUN0ppNFhNT2lEelBwRjJicmxIOGNzaVAxYmdGS2dWc3V6WDczRE1zSW9JNWE
// 2NVlrbXpQLUhJVTZDMWFrbkx0T0ZzelkmY3VycmVuY3k9VVNEIiwiYXR0cnMiOnsiZGF0YS11aWQiOiJ1aWRfZHhyZnFrcmRqcnJibnJ5aXNlamxqZnJkY2NscHpmIn19
// &xcomponent=1
// &version=5.0.390
// &token=4S064622AV254211T
// &ssrt=1690904869773
// &rcache=2
// &useraction=CONTINUE
// &cookieBannerVariant=hidden
// &country.x=DE
// &ulPage=noMatch
// &abTestThrottle=xoon
// &fromSignupLite=true
// &hermesLoginRedirect=true
// &prompt=login
// &locale.x=de_DE
// &country.x=DE
</script>
