<template>
  <div>
    <v-textarea
      ref="textField"
      v-model="myValue"
      :rules="rules"
      :label="rules == '' ? label : label + '*'"
      :hint="hint"
      :placeholder="placeholder"
      :prepend-inner-icon="prependInnerIcon"
      :outlined="outlined"
      :clearable="clearable"
      :color="color"
      :autocomplete="autocomplete"
      :dense="dense"
      :disabled="disabled"
      :bind="bind"
      :on="on"
      @change="change(myValue)"
      @input="change(myValue)"
      @keypress="keypress"
      @paste="paste"
    >
    </v-textarea>
  </div>
</template>

<script>
  export default {
    name: "MyTextarea",

    data() {
      return {
        myValue: this.myValue1,

      };
    },

    props: {
      myValue1: {
        type: String,
        default: "",

      },
      rules: {
        type: Array,
        default() {
          return [];
        },
      },
      label: {
        type: String,
        default: "",
      },
      hint: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      prependInnerIcon: {
        type: String,
        default: "",
      },
      outlined: {
        type: Boolean,
        default: true,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      color: {
        type: String,
        default: "primary",
      },
      autocomplete: {
        type: String,
        default: "",
      },
      dense: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      bind: {},
      on: {},
    },

    model: {
      prop: "myValue1",
      event: "change",
    },

    methods: {
      change(val) {
        this.$emit("change", val);
      },

      keypress() {
        this.$emit("keypress");
      },

      paste(evt) {
        this.$emit("paste", evt);
      },

    },
    watch: {
      myValue1(){
        this.myValue = this.myValue1;
      }
    }
  };
</script>

<style scoped>
</style>
