<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="10">
        <v-card outlined class="mx-auto" elevation="10">
          <v-card-title>
            <v-row justify="space-between">
              <v-col>
                <v-toolbar-title>{{ $t(myName + "." + myName) }}</v-toolbar-title>
              </v-col>
              <v-spacer></v-spacer>
              <v-col> </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <mybtn @click="doversion" label="doversion"> </mybtn>
              <mybtn @click="test2" label="test2"> </mybtn>

              <!--  <examTypeTable></examTypeTable>-->
              <v-col cols="12"> </v-col>
              <v-row>
                <!--                <mycaptcha-->
                <!--                  :refresh="refreshCaptcha"-->
                <!--                ></mycaptcha>-->

                <!--      <img src="../../../server/fotos/ostern.jpg" height="564" width="696"/>-->
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PHPServer from "../../res/services/postToPHPServer";

// const fs = require('fs');
//
// const someFileContents = fs.readFileSync('../views/');

export default {
  name: "DoVersion",
  meta: {
    requiresAuth: true,
    is_developer: true,
  },
  data() {
    return {
      myName: "doVersion",
      refreshCaptcha: true,
      fotoSrc: "",
    };
  },
  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
      token: "Login/getToken",
      myTestFormatedItems: "ExamDate/formatedItems",
    }),
  },
  // provide() {
  //   const foo = {};
  //   Object.defineProperty(foo, 'info', {
  //     enumerable: true,
  //     get: () => this.info,
  //     set(v) {
  //       this.info = v;
  //     }
  //   });
  //   return {
  //     foo,
  //   }
  // },
  methods: {
    doversion() {
      // console.log(' Login/getToken',this.token);

      const formData = new FormData();
      formData.append("command", "doVersion");
      PHPServer.send(formData).then((res) => {
        // state.items = res.data;

        console.log("doversion: ", res);
      });

      // this.$toast.error("I'm a toast!", {
      //   timeout : 5000
      // });
    },
    test2() {
      const formData = new FormData();
      formData.append("command", "test2");
      PHPServer.send(formData).then((res) => {
        // state.items = res.data;

        console.log("test res: ", res);
      });
    },

    // getImgUrl(pet,format) {
    //   var images = require.context('../../../server/fotos/', false);
    //   this.fotoSrc = images('./' + pet + '.' + format)
    // }
    showAlert() {
      let alertObj = {
        text: "Hello, I'm a snackbar in store",
        color: "red",
        timeout: -1,
        alertShow: true,
      };
      this.$store.dispatch("MyAlert/setAlert", alertObj);
    },
  },
};
</script>

<style scoped></style>
