<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="10">
        <v-card outlined class="mx-auto" elevation="10">
          <v-card-title>
            <v-row justify="space-between" no-gutters>
              <v-col>
                <v-toolbar-title> Developer </v-toolbar-title>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row justify="space-between" no-gutters>
              <v-col>
                <v-tabs>
                  <v-tab @click="callGetLog('getErrorLog')">error Log</v-tab>
                  <v-tab @click="callGetLog('getLogMessage')"
                  >massage Log</v-tab
                  >
                </v-tabs>
              </v-col>
              <v-col cols="3">
                <mytextfield
                  v-model="countOfLine"
                  label="Anzahl der Zeilen"
                  @keypress="isNumber"
                  @paste="onPaste"
                ></mytextfield>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-card
                  outlined
                  class="mx-auto"
                  elevation="1"
                  ref="drawer"
                  max-height="500px"
                >
                  <v-card-text
                    style="max-height: 500px"
                    class="text-subtitle-2"
                  >
                    <div class="pre-formatted black--text" @mouseup="mouseup">
                      <!-- @click="test" -->
                      <span v-html="logMessage"></span>
                      <!-- {{ logMessage }} -->
                    </div>
                  </v-card-text>
                </v-card>
                <mybtn @click="getLog" label="Aktualisierung Log"> </mybtn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import PHPServer from "../../res/services/postToPHPServer";
  // import Helper from "../../res/js/Helper";

  export default {
    name: "Developer",
    meta: {
      requiresAuth: true,
      is_developer: true
    },
    data() {
      return {
        myName: "Developer",
        sourceMessage: "",
        sourceMessageErrorLog: "",
        sourceMessageMessageLog: "",
        logMessage: "",
        message: "",
        countOfLine: "100",

        selectedTextErrorLog: "",
        selectedTextMessageLog: "",

        ErrorOrMessage: true,
        command: "",
        stopInterval: false,
      };
    },
    computed: {
      ...mapGetters({}),
    },

    created() {
      this.initialize();
    },

    // beforeMount() {
    //   console.log("** beforeMount **");
    // },
    // // called when the bound element's parent component
    // // and all its children are mounted.
    // mounted() {
    //   console.log("** mounted **");
    // },
    // // called before the parent component is updated
    // beforeUpdate() {
    //   console.log("** beforeUpdate **");
    // },
    // // called after the parent component and
    // // all of its children have updated
    // updated() {
    //   console.log("** updated **");
    // },
    // // called before the parent component is unmounted
    // beforeUnmount() {
    //   console.log("** beforeUnmount **");
    // },
    // unmounted() {
    //   console.log("** unmounted **");
    // },

    methods: {
      initialize() {
        // console.log("** initialize **");
        this.callGetLog("getErrorLog");
        // this.callinterval();
      },

      callGetLog(cammand) {
        this.command = cammand;
        this.getLog();
      },

      getLog() {
        const formData = new FormData();
        formData.append("command", this.command);
        // if (!Helper.isEmpty(this.countOfLine)) {
        if (!this.isEmpty(this.countOfLine)) {
          formData.append("countOfLine", this.countOfLine);
          //  console.log('  count of line', this.countOfLine);
        }
        // formData.append("lengthOfData", this.sourceMessage.length);

        PHPServer.send(formData).then((res) => {
          let data = res.data;
          if (data.length > 0) {
            if (this.command === "getErrorLog") {
              this.sourceMessageErrorLog = data;
            } else {
              this.sourceMessageMessageLog = data;
            }
            this.sourceMessage = data;
            this.highlightedMessage();
          }
        });
      },

      callinterval() {
        setInterval(() => {
          if (!this.stopInterval) {
            this.getLog();
            console.log(" test setInterval");
          } else {
            console.log(" test ");
          }
        }, 3000);
      },



      mouseup() {
        if (this.command === "getErrorLog") {
          this.selectedTextErrorLog = window.getSelection().toString().trim();
        } else {
          this.selectedTextMessageLog = window.getSelection().toString().trim();
        }
        this.highlightedMessage();
      },

      highlightedMessage() {
        let str = "";
        if (this.command === "getErrorLog") {
          str = this.selectedTextErrorLog;
        } else {
          str = this.selectedTextMessageLog;
        }
        let strLen = str.length;
        let highlightedText =
          '<span style="background-color: rgb(247, 218, 100);">' +
          str +
          "</span>";
        if (strLen > 0) {
          let index = 0;
          let tempIndex = 0;
          let tempArray = [];
          while (tempIndex >= 0) {
            tempIndex = this.sourceMessage.indexOf(str, index);
            if (tempIndex >= 0) {
              tempArray.push(tempIndex);
              index = tempIndex + strLen;
            }
          }
          this.logMessage = "";
          for (let i = 0; i < tempArray.length; i++) {
            if (i === 0) {
              this.logMessage +=
                this.sourceMessage.substring(0, tempArray[i]) + highlightedText;
            } else {
              this.logMessage +=
                this.sourceMessage.substring(
                  tempArray[i - 1] + strLen,
                  tempArray[i]
                ) + highlightedText;
            }
            if (i === tempArray.length - 1) {
              this.logMessage += this.sourceMessage.substring(
                tempArray[i] + strLen,
                this.sourceMessage.length
              );
            }
          }
        } else {
          this.logMessage = this.sourceMessage;
        }
      },

      // isNumber(evt) {
      //   // return Helper.isNumber(evt);
      //   return this.isNumber(evt);
      // },

      onPaste(evt) {
        let temp = evt.clipboardData.getData("text");

        let re = /^\+?[0-9]*$/gi;
        if (!temp.match(re)) {
          evt.preventDefault();
        }
      },
    },
  };
</script>

<style scoped>
  html {
    overflow: hidden !important;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
  }

  .v-card__text {
    flex-grow: 1;
    overflow: auto;
  }
  .pre-formatted {
    white-space: pre;
  }
</style>