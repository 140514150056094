import PHPServer from "../../res/services/postToPHPServer";
// import Helper from "../../res/js/MyHelper";

// function importAll(r) {
//   return r.keys().map(r);
// }
// const fn = importAll(require.context('../myModules', false, /\.(js)$/));

class Modules {
  //mystate;

  constructor(myName, myTableName, myFile) {
    // this.carname = brand;
    this.myName = myName;
    this.myTableName = myTableName;

    this.myFn = myFile;

    // for (let i = 0; i < this.myFn.length; i++) {
      // if (myName == this.myFn[i].default.myName()) {
      if (this.myFn !== null) {
        // this.myTmp = this.myFn[i].default;
        this.myTmp = this.myFn;
        // console.log(' mymodules myTmp :: ', this.myTmp);
        this.myFnAction = this.myTmp.myAction();
        this.myFnMutation = this.myTmp.myMutation();
        this.myFnState = this.myTmp.myState();
        this.myFnGetter = this.myTmp.myGetter();
      //   break;
      // }
    }
  }

  myStates() {
    this.state = {
      name: this.myName,
      tableName: this.myTableName,
      items: [],
      // headers: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      fields: [],
      headerFilter: [],
      tabelInfo: [],
      headerId: false,
    };
    for (var key in this.myFnState) {
      this.state[key] = this.myFnState[key];
    }
    return this.state;
  }

  myGetters() {
    this.getters = {
      getItems: state => state.items,
      getEditedItem: state => state.editedItem,
      getDefaultItem: state => state.defaultItem,
      getEditedIndex: state => state.editedIndex,
      // getHeaders: state => state.headers,
      getFields: state => state.fields,
      getHeaderFilter: state => state.headerFilter,
      getTabelInfo: state => state.tabelInfo,
    };

    this.addMethods(this.getters, this.myFnGetter);
    return this.getters;
  }

  myActions(state) {
    this.actions = {//dispatch
      setEditedItem({ state }, dataj) {
        state.editedItem = dataj;
      },

      setEditedIndex({ state }, dataj) {
        state.editedIndex = dataj;
      },

      setItems({ state }, dataj) {
        state.items = dataj;
      },

      saveItem({ state }, dataj) {
        dataj.myFunctionName = 'save';
        return PHPServer.myFunction(state.tableName, dataj);
      },

      deleteItem({ state }, dataj) {
        let data = {
          myFunctionName: 'delete',
          id: dataj,
        }
        return PHPServer.myFunction(state.tableName, data);
      },

      selectItems({ dispatch }) {
        // console.log(' select items ' , state.tableName);
        let data = {
          myFunctionName: 'select',
        }
        return PHPServer.myFunction(state.tableName, data)
          // return PHPServer.selectItems(state.tableName)
          .then((res) => {
            // console.log('cls_modules  SelectItems: ', res);
            let items = res.data;
            if (items.length > 0) {
              state.fields = Object.keys(items[0]);
              for (let i = 0; i < items.length; i++) {
                items[i].row = i + 1;
              }
              state.items = items;
              // if (state.headerFilter.length === 0) {
              //   dispatch('headerFilter');
              // }
              if ('formatedItems' in state) {
                // console.log(' formatedItems ');
                dispatch('formatedItems');
              }
            }
            else {
              state.items = items;
            }
          })
      },

      getVueTable() {
        let data = {
          myFunctionName: 'getVueTable',
        }
        return PHPServer.myFunction(state.tableName, data)
          .then((res) => {
            // console.log('cls_modules  SelectItems: ', res);
            let items = res.data.data;
            if (items.length > 0) {
              state.fields = Object.keys(items[0]);
              // console.log('getVueTable  state.fields: ', state.fields);
              for (let i = 0; i < items.length; i++) {
                items[i].row = i + 1;
              }
              state.items = items;
              //set headerFilter 
              state.tableInfo = res.data.header;
              let headerFilter = [];
              for (let i = 0; i < state.fields.length; i++) {
                let temp = state.tableInfo.find(x => x.Field == state.fields[i]);
                let strType = temp.Type;
                if (strType == 'date') {
                  headerFilter[i] = '2';
                } else if (strType.indexOf('char') >= 0 || strType.indexOf('text') >= 0 || strType.indexOf('enum') >= 0) {
                  headerFilter[i] = '1';
                } else {
                  headerFilter[i] = '0';
                }
              }
              state.headerFilter = headerFilter;
            }
            else {
              state.items = items;
            }
          })
      },

      headerFilter() {
        let data = {
          myFunctionName: 'headerFilter',
        }
        return PHPServer.myFunction(state.tableName, data)
          // return PHPServer.headerFilter(state.tableName)
          .then(res => {
            console.log(' cls_modules headerFilter:', res);
            state.headerFilter = res.data;
          })
      },

      myFunction({ state }, dataj) {
        console.log(' my fun');
        return PHPServer.myFunction(state.tableName, dataj);
      },
    };
    this.addMethods(this.actions, this.myFnAction);
    return this.actions;
  }

  // myMutations(state) {
  myMutations() {
    this.mutation = {
      // test() {
      //   let tmpStr = 'salam mohsen jun';
      //   console.log('temp test in mymutations:', tmpStr, state);
      //   return tmpStr;
      // },
    };

    this.addMethods(this.mutation, this.myFnMutation);
    return this.mutation;
  }

  addMethods(methods, object) {

    for (var name in object) {
      methods[name] = object[name];
    }
  }
}


export default Modules;


