import Vue from 'vue'
import Router from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router'
import store from "../../store";
// import helper from '../../res/js/Helper.js'
Vue.use(Router);
function importAll(r) {
  // console.log(' import all : ' , r.keys());
  return r.keys().map(r);
}

function getAllFiles() {
  let res = tmpRoutes;
  let flArray = [
    // { method: ()=>{return importAll(require.context('../../components/views', false, /.*\.(vue)$/));} },
    { method: () => { return importAll(require.context('../../components/', true, /views\/.*\.(vue)$/)); } },
    { method: () => { return importAll(require.context('../../components/managment', false, /\.(vue)$/)); } },
  ];

  for (let i = 0; i < flArray.length; i++) {
    res = getFiles(flArray[i].method(), res);
  }
  // console.log(' res ' , res );
  return res;
}

function getFiles(myFl, res) {
  // let res = [];
  // console.log(' Fl in router : ', myFl);

  for (let i = 0; i < myFl.length; i++) {

    // if(myFl[i].default.name == "AllTables"){
    //   console.log(' data file in router : ', myFl[i].default.data());
    //   let myData = myFl[i].default.data();
    //   console.log('myData ', myData);
    // } 

    if (JSON.stringify(myFl[i]) !== '{}') {
      if (myFl[i].default.name != undefined) {
        if (myFl[i].default.name.length > 0) {
          let tmpMeta = {};
          if (myFl[i].default.meta != undefined) {
            tmpMeta = myFl[i].default.meta;
          }
          let tmpPath = '';
          if (myFl[i].default.path != undefined) {
            tmpPath = myFl[i].default.path;
          }
          res.push({
            path: '/' + myFl[i].default.name.toLowerCase() + tmpPath,
            name: myFl[i].default.name,
            component: myFl[i].default,
            meta: tmpMeta
          })
        }
      }
    }
  }
  return res;
}



import telcRegisterForm from '../../components/views/TelcRegisterForm'
// import modelsView from '../../components/views/ModelsView'
// import login from '../../components/users/MyLogin'
import newPassword from '../../components/users/MyNewPassword'
import telcMemberSave from '../../components/telcMember/TelcMemberSave'

const tmpRoutes = [
  { path: '*', name: 'Register', component: telcRegisterForm },
  { path: '/', name: 'Register', component: telcRegisterForm },
  { path: '/register', name: 'Register', component: telcRegisterForm },
  {
    path: '/telcmembersave', name: 'telcMemberSave', component: telcMemberSave,
    meta: {
      requiresAuth: true,
      is_access: true
    }
  },

  // { path: '/login', name: 'Login', component: login },
  { path: '/newpassword', name: 'NewPassword', component: newPassword },

  // {
  //   path: '/modelsViews/:id', name: 'ModelsViews', component: modelsView,
  //   meta: {
  //     requiresAuth: true,
  //     is_admin: true
  //   }
  // },

];



const myRoutes = getAllFiles();
// create and export router
// const router = createRouter({
const router = new Router({
  // history: createWebHistory(process.env.BASE_URL),
  routes: myRoutes,

});

router.beforeEach((to, from, next) => {
  console.log(' from', from.path);
  console.log(' to', to.path);
  if (to.path === '/' || to.path.toLowerCase() === '/login') {
    // helper.deleteLocalStorage();
    // console.log(' to login seite ::');
    store.dispatch('Login/logout');
  }
  // console.log(' meta.requireAuth', to.meta.requiresAuth);
  if (typeof to.meta.requiresAuth !== 'undefined') {
    if (to.meta.requiresAuth) {
      //login verify
      store.dispatch('Login/loginVerify')
        .then(() => {
          // if isLogin -> next / not isLogin -> go to page login
          let user = store.getters['Login/getUser'];
          if (to.meta.is_access) {
            return user.access ? next() : next("/login");
          }
          if (to.meta.is_admin) {
            return user.admin ? next() : next("/login");
          }
          if (to.meta.is_developer) {
            return user.developer ? next() : next("/login");
          }
          if (localStorage.getItem("isLogin") === "true") {
            return next();
          } else {
            return next("/login");
          }
        }
        );
    }
  }
  return next();
});

/**
 * special handling for login / logout path
 * eslint-disable-next-line consistent-return
 * @param to
 * @param from
 * @param next
 *
 * @return callback next()
 */
router.beforeEach((to, from, next) => {
  // if (auth.isLogin && to.name === "login") {
  //   return next("/");
  // } else if (!auth.isLogin && to.name !== "login") {
  //   return next("/login");
  // } else if (auth.isLogin && to.path === "/logout") {
  //   auth.logout();
  // }
  return next();
});

export default router;
