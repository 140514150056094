<template>
  <mydatatable
    :name="myName"
    :savedata="saveData"
    show-add-btn
    show-edit-btn
    show-delete-btn
  >
    <savedialog  @change="save"></savedialog>
  </mydatatable>
</template>

<script>
  import savedialog from '../users/usersSave'
  export default {
    name: "Users",
    meta: {
      requiresAuth: true,
      is_admin: true
    },

    components: {
      savedialog,
    },
    data: () => ({
      myName: "Users",
      saveData: false,
    }),

    created() {
      this.initialize()
    },

    methods: {
      initialize() {
      this.$store.dispatch(`${this.myName}/getVueTable`);
      },
      save() {
        this.saveData = !this.saveData;
      },
    },
  }
</script>

<style scoped>

</style>