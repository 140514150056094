<template>
  <v-container>
    <v-row align="start" class="my-0 pa-0">
      <v-col cols="12" xs="12" sm="6" class="my-0 pa-0">
        <v-row align="center" class="my-1 pa-0">
          <v-col cols="12" xs="8" sm="8">
            <!-- <div style="min-height: 4px"></div> -->
            <v-img
              contain
              max-height="70"
              max-width="280"
              class="pb-1"
              :src="captchaImage"
            >
              <v-progress-linear v-model="valueProgressLinear"></v-progress-linear>
            </v-img>
          </v-col>
          <v-col cols="2" xs="2">
            <mybtn
              :tooltiptext="$t('captcha.refresh')"
              @click="refreshCode"
              iconname="mdi-refresh"
              large
              fab
            ></mybtn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="6" align="center" class="mt-3 pr-0 pl-3 py-0">
        <!-- <v-row align="end" class="my-1"> -->
        <mytextfield
          v-model="captchaCode"
          :rules="rules"
          :label="$t('captcha.captchaText')"
          @keyupEnter="keyupEnter"
          :dense="false"
        ></mytextfield>

        <!-- <v-text-field
            v-model="captchaCode"
            :rules="rules"
            :label="$t('captcha.captchaText') + '*'"
            required
            clearable
            outlined
          ></v-text-field> -->
        <!-- </v-row> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Helper from "../../res/js/MyHelper.js";

export default {
  name: "MyCaptcha",
  data() {
    return {
      captchaCode: "",
      valueProgressLinear: 0,
      interval: 0,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      captchaImage: "captcha/getCaptchaImage",
      captchaEncrypt: "captcha/getCaptchaEncrypt",
      formActive: "Language/getFormActive",
    }),

    // captchaCodeRuls() {
    //   return this.formActive
    //   ? [v => !!v.trim() || this.$t('captcha.captchaText')] : []
    // },
  },

  props: {
    refresh: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default() {
        return this.formActive
          ? [(v) => !!v.trim() || this.$t("captcha.captchaText")]
          : [];
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.refreshCode();
    },

    refreshCode(refreshTime = 150) {
      // let refreshTime = 10;
      // console.log(' refresch captcha ');
      this.captchaCode = "";
      this.$store.dispatch("captcha/getCaptcha");
      this.valueProgressLinear = 0;
      this.endInterval();
      this.progressLinear(refreshTime);
      let doFunc = () => {
        //this.$store.dispatch("captcha/getCaptcha");
        this.refreshCode();
      };
      Helper.setIntervalJob("captcha", refreshTime, doFunc);
    },

    progressLinear(progressTime) {
      // console.log(' interval : ' , this.interval);
      this.valueProgressLinear = 0;

      if (this.interval == 0) {
        this.interval = setInterval(() => {
          if (this.valueProgressLinear >= 100) {
            this.valueProgressLinear = 0;
            // this. endInterval();
          }
          this.valueProgressLinear += 100 / progressTime;
        }, 1000);
      }

      // for (let i = 0; i < progressTime; i++) {
      //   this.valueProgressLinear += (100 / progressTime);
      //   setTimeout(() => {}, 1000);
      //   if(this.valueProgressLinear >= 100){
      //       this.refreshCode();
      //     }
      // }

      // if (this.interval == 0) {

      //   this.interval = setInterval(() => {
      //     this.count++;
      //     console.log("intervalId ", this.interval);
      //     if (this.count % 2 == 0) {
      //       console.log("count interval in capcha", this.count);
      //     }
      //     if(this.valueProgressLinear >= 100){
      //       this.refreshCode();
      //     }

      //     this.valueProgressLinear += (100 / progressTime);
      //     console.log(' valueProgressLinear :: ' , this.valueProgressLinear);

      //   }, 1000);
      // }
    },

    keyupEnter(evt) {
      this.$emit("keyupEnter", evt);
    },

    endInterval() {
      if (this.interval != 0) {
        clearInterval(this.interval);
        this.interval = 0;
      }
    },

    test() {
      let item = {
        captchaCode: this.captchaCode,
        captchaEncrypt: this.captchaEncrypt,
      };
      this.$store.dispatch("captcha/verifyCaptcha", item).then((res) => {
        console.log("capcha verify:", res);
      });
    },
  },
  watch: {
    refresh() {
      this.refreshCode();
    },
    captchaCode() {
      this.$store.dispatch("captcha/setCaptchaCode", this.captchaCode);
    },
  },
};
</script>

<style scoped></style>
