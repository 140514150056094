<template>
  <v-autocomplete
    ref="ref"
    v-model="myValue"
    :items="items"
    :item-text="itemText"
    :rules="rules"
    :label="label"
    :hint="hint"
    :placeholder="placeholder"
    :prepend-inner-icon="prependInnerIcon"
    :outlined="outlined"
    :clearable="clearable"
    :color="color"
    :autocomplete="autocomplete"
    :dense="dense"
    :hide-no-data="hideNoData"
    :disabled="disabled"
    :search-input.sync="searchInput"
    :bind="bind"
    :on="on"
    @change="change"
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: "MyAutocomplete",
  data() {
    return {
      myValue: this.myValue1,
      searchInput: "",
    };
  },

  props: {
    myValue1: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemText: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    autocomplete: {
      type: String,
      default: "f",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    hideNoData: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bind: {},
    on: {},
  },

  model: {
    prop: "myValue1",
    event: "change",
  },

  methods: {
    change() {
      this.$emit("change", this.myValue);
    },
  },
  watch: {
    myValue1(){
      this.myValue = this.myValue1;
    }
  }
};
</script>

<style scoped>
</style>