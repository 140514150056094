<template>
  <v-container>
    <v-form ref="form" v-model="formValid" lazy-validation class="container">
      <v-row class="my-0 py-0">
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!-- <div>{{ setData.tableName }}</div> -->
          <!-- tableName -->
          <mytextfield
            v-model="setData.tableName"
            :rules="rules.tableName"
            :label="$t(myName + '.tableName')"
            disabled
          ></mytextfield>
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===columnName -->
          <mytextfield
            v-model="columnName"
            :rules="rules.columnName"
            :label="$t(myName + '.columnName')"
          ></mytextfield>
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===typeModel -->
          <v-select
            v-model="typeModel"
            :items="typeModelItems"
            item-text="name"
            :rules="rules.typeModel"
            :label="$t(myName + '.typeModelGroup')"
            required
            clearable
            outlined
            dense
          >
          </v-select>

        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===type -->
          <v-select
            v-model="type"
            :items="typeModel"
            :rules="rules.typeModel"
            :label="$t(myName + '.typeModel')"
            required
            clearable
            outlined
            dense
          >
          </v-select>
          
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===lengthOrValue -->
          <mytextfield
            v-model="lengthOrValue"
            :rules="rules.lengthOrValue"
            :label="$t(myName + '.lengthValue')"
          >
          </mytextfield>
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===defaultValue -->
          <mytextfield
            v-model="defaultValue"
            :label="$t(myName + '.defaultValue')"
          >
          </mytextfield>
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===fields -->
          <v-select
            v-model="field"
            :items="fieldsItems"
            :rules="rules.typeModel"
            :label="$t(myName + '.field') + '*'"
            required
            clearable
            outlined
            dense
          >
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
          <!--===null or not null -->
          <v-radio-group
            class="my-2 py-0"
            v-model="nullnNotNull"
            :mandatory="true"
            :rules="rules.examLocationRules"
            row
          >
            <v-radio label="not Null" value="NOT NULL"></v-radio>
            <v-radio label="Null" value="NULL"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0">
        <v-col cols="12" xs="12" class="my-0 py-0">
          <!--description-->
          <!-- <v-textarea
            v-model="editedItem[fields[4]]"
            :label="$t(myName +'.' +fields[4])"
            clearable
            outlined
          ></v-textarea> -->
        </v-col>
      </v-row>
      <!--buttons-->
      <mysavebtn :disabled="!formValid" @submit="submit" @clear="clear"></mysavebtn>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddNewColumn",
  data() {
    return {
      myName: "AllTables",
      formValid: true,
      columnName: '',
      fieldsItems: [],
      field: '',
      typeModelItems: [
        {
          name: "number",
          value: [
            "INT",
            "TINYINT",
            "BIGINT",
            "DECIMAL",
            "FLOAT",
            "DOUBLE",
            "REAL",
            "BIT",
            "BOOLEAN",
          ],
        },
        {
          name: "Date and Time",
          value: ["DATE", "DATETIME", "TIMESTAMP", "TIME", "YEAR"],
        },
        {
          name: "character",
          value: ["CHAR", "VARCHAR", "TEXT", "TINYTEXT", "MEDIOMTEXT", "LONGTEXT"],
        },
        { name: "Binary", value: ["BINARY", "VARBINARY"] },
        { name: "Blob", value: ["BLOB", "TINYBLOB", "MEDIUMBLOB", "LONGBLOB"] },
        { name: "Enum and Set", value: ["ENUM", "SET"] },
      ],
      typeModel: [],
      type: '',
      lengthOrValue:'',
      defaultValue: '',
      nullnNotNull: 'NOT NULL',
    };
  },

  props: {
    setData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
    }),
    rules() {
      let rules = {
        columnName: [(v) => !!v || this.$t(this.myName + ".rules.columnName")],
      };

      return this.formActive ? rules : {};
    },
  },

  created() {
    this.initialized();
  },

  methods: {
    initialized() {
      this.fieldsItems = this.$store.getters[`${this.setData.tableName}/getFields`];
    },

    clear() {
      this.$refs.form.reset();
    },

    submit() {
      if (this.$refs.form.validate()) {
        // this.showAlertAfterSubmit = false;
        this.formValid = false;

        let data = {
          myFunctionName: "addColumn",
          tableName: this.setData.tableName,
          columnName: this.columnName,

          type: this.type,
          lengthOrValue: this.lengthOrValue,
          defaultValue: this.defaultValue,
          nullnNotNull: this.nullnNotNull,
          afterField: this.field,
        };
        console.log(' data :: ' , data);
        this.$store
          .dispatch(`${this.myName}/myFunction`, data)
          .then((res) => {
            console.log(" addColumn resss: ", res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$toast.error(this.$t(this.myName + ".alertMessage1"), {
          timeout: 10000,
        });
      }
    },

  },

  watch: {},
};
</script>
