<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="10">
        <v-card outlined class="mx-auto" elevation="10">
          <v-card-title>
            <v-row justify="space-between">
              <v-col>
                <v-toolbar-title>{{ $t(myName + "." + myName) }}</v-toolbar-title>
              </v-col>
              <v-spacer></v-spacer>
              <v-col></v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <mybtn @click="test" label="test1"></mybtn>
              <mybtn @click="test2" label="test2"></mybtn>

              <!--  <examTypeTable></examTypeTable>-->
              <v-col cols="12"></v-col>
              <v-row>
                <!--                <mycaptcha-->
                <!--                  :refresh="refreshCaptcha"-->
                <!--                ></mycaptcha>-->

                <!--      <img src="../../../server/fotos/ostern.jpg" height="564" width="696"/>-->
              </v-row>
              <v-row>
                <mybtn @click="test3" label="test3"></mybtn>
                <mybtn @click="test4" label="test4"></mybtn>
                <mybtn @click="test5" label="test5"></mybtn>
                <!-- myPayPalBtn -->
                <!-- <mypaypalbtn></mypaypalbtn> -->
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PHPServer from "../../res/services/postToPHPServer";

// const fs = require('fs');
//
// const someFileContents = fs.readFileSync('../views/');

export default {
  name: "test",
  meta: {
    // requiresAuth: true,
    // is_developer: true
  },
  data() {
    return {
      myName: "test",
      refreshCaptcha: true,
      fotoSrc: "",
    };
  },
  computed: {
    ...mapGetters({
      formActive: "Language/getFormActive",
      token: "Login/getToken",
      // myTestFormatedItems: "ExamDate/formatedItems",
    }),
  },
  // provide() {
  //   const foo = {};
  //   Object.defineProperty(foo, 'info', {
  //     enumerable: true,
  //     get: () => this.info,
  //     set(v) {
  //       this.info = v;
  //     }
  //   });
  //   return {
  //     foo,
  //   }
  // },
  methods: {
    test() {
      // console.log(' Login/getToken',this.token);
      console.log(" test1");
      // console.log(" store :: " , this.$store);
      // console.log(" store :: " , this.$store._actions);

      // let storeActions = Object.keys(this.$store._actions);

      // let allSetItems = storeActions.filter(word => word.includes('setItems'));

      // for(let i = 0; i < allSetItems.length; i++){
      //   let temp = [];
      //   this.$store.dispatch(allSetItems[i], temp);
      // }

      let data = {
        myFunctionName: "test",
      };
      return PHPServer.myFunction("", data).then((res) => {
        console.log("test res: ", res);
      });

      // this.$toast.error("I'm a toast!  dfd ", {
      //   timeout : 5000
      // });
    },

    test2() {
      console.log(" test2");

      console.log(" days to date", this.getTimeFormat());

      // console.log('   func name : ', arguments.callee.name );
      // console.log("   func name : ", this.test2.name);

      // // console.log(" store :: " , this.$store);

      // const formData = new FormData();
      // formData.append("command", "test2");
      // PHPServer.send(formData).then((res) => {
      //   // state.items = res.data;

      //   console.log("test res: ", res);
      // });
    },

    test3() {
      console.log(" test 3");
      console.log(" run interval");

      this.$store.dispatch("interval/runInterval");
    },
    test4() {
      console.log(" test 4");
      console.log(" stop interval");

      this.$store.dispatch("interval/endInterval");
    },
    test5() {
      console.log(" test 5");
      this.$store.dispatch("interval/showDoJobs");
    },

    // getImgUrl(pet,format) {
    //   var images = require.context('../../../server/fotos/', false);
    //   this.fotoSrc = images('./' + pet + '.' + format)
    // }
    showAlert() {
      let snackbarObj = {
        text: "Hello, I'm a snackbar in storeasdfasdf",
        color: "red",
        timeout: -1,
        alertShow: true,
      };
      this.$store.dispatch("MyAlert/setAlert", snackbarObj);
    },
  },
};
</script>

<style scoped></style>
