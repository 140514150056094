const myName = 'MyAlert';

const state = {
  name: 'MyAlert',
  text: "Hello, I'm a snackbar in store",
  type: '',
  color: "primary",
  timeout: 10000,
  alertShow: false,
};

const getters = {
  getText: state => state.text,
  getType: state => state.type,
  getColor: state => state.color,
  getTimeout: state => state.timeout,
  getAlertShow: state => state.alertShow,
};

const actions = {//dispatch
  setShowAlert({ state }, dataj = true) {
    state.alertShow = dataj;
  },
  setAlert({ state }, dataj) {
    state.text = dataj.text;
    state.type = dataj.type;
    state.color = dataj.color;
    state.timeout = dataj.timeout;
    state.alertShow = dataj.alertShow;
  },
};

const mutations = {//commit
};

export default {
  myName,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

