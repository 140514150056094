import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import globalComponents from "/src/res/js/GlobalComponents.js";
import router from './res/router/index';
import store from "./store";
import { i18n } from "./res/language/i18n.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Injector from "vue-inject";
// import {myHelperr} from "/src/res/js/Helper.js";
import Helper from "/src/res/js/MyHelper.js";

// import { loadPaypalSdk } from '@paypal/paypal-js';
// import { PayPalScriptProvider  } from '@paypal/paypal-js';

// Vue.use(PayPalScriptProvider, {
//   // Replace 'YOUR-PAYPAL-CLIENT-ID' with your actual PayPal client ID
//   "client-id": "YOUR-PAYPAL-CLIENT-ID",
// });

// loadPaypalSdk({
//   // Replace 'YOUR-PAYPAL-CLIENT-ID' with your actual PayPal client ID
//   'client-id': 'YOUR-PAYPAL-CLIENT-ID',
//   currency: 'USD',
// }).then((paypal) => {
//   console.log(' loadPaypalSDK :' , paypal);
//   // The PayPal SDK is loaded and ready to be used
//   // You can now use it to create buttons and integrate payment functionality
// });


for(let i = 0; i < globalComponents.length; i++){
  Vue.component(globalComponents[i].name, globalComponents[i].value);
}

const options = {
  // You can set your default options here
  timeout: 0
};

Vue.use(Toast, options);
Vue.use(Injector);

const moment = require('moment');
require('moment/locale/de');

Vue.use(require('vue-moment'), {
  moment
});

Vue.config.productionTip = false;

Vue.mixin({

  methods: Helper,
  // methods: Helper,
  // methods: {
  //    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
  //     goToPage(path){ 
  //     path = '/' + path; 
  //     console.log(' path :' , path);
  //     // if (router.history.current.path !== path){  
  //     //   router.push(path); 
  //     // } 
  //   },
  // }
})

new Vue({
  vuetify,
  router,
  i18n,
  store,
  // components: globalComponents,
  // globalComponents,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  render: h => h(App)
}).$mount('#app');
