<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="container">
      <v-card class="mb-2 px-0">
        <v-card-title class="headline">
          <v-row justify="center">
            <div>{{ $t(myName + ".telcExam") }}</div>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!--  exam card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t(myName + ".exam") }}</v-card-title>
            <v-card-text>
              <v-row class="my-2 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examLocation -->
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <div class="text-h6 font-weight-bold">
                        <p>{{ $t(myName + ".examLocation") }}</p>
                      </div>
                    </v-col>
                    <v-radio-group
                      class="my-2 py-0"
                      v-model="editedItem.examLocation"
                      :mandatory="false"
                      :rules="rules.examLocationRules"
                      row
                      @change="changeExamLocation()"
                    >
                      <v-radio label="Marburg" value="Marburg"></v-radio>
                      <v-radio label="Bonn" value="Bonn"></v-radio>
                    </v-radio-group>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0"> </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0"> </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examType -->
                  <v-select
                    :disabled="!(typeof editedItem.examLocation === 'string')"
                    v-model="editedItem.examType"
                    :items="formatedItemsExamType"
                    :rules="rules.examTypeRules"
                    :label="$t(myName + '.examType') + '*'"
                    @change="changeExamType"
                    required
                    clearable
                    outlined
                    dense
                  >
                    <template slot="append-outer">
                      <v-menu button :disabled="!examDescriptionDisabled">
                        <template v-slot:activator="{ on, attrs }">
                          <mybtn
                            :disabled="!examDescriptionDisabled"
                            :bind="attrs"
                            :on="on"
                            iconname="mdi-information"
                            fab
                            small
                          ></mybtn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            {{ $t(myName + ".examInfo") }}
                          </v-card-title>
                          <v-card-text>{{ examDescription }}</v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examDate -->
                  <v-select
                    :disabled="!(typeof editedItem.examType === 'string')"
                    v-model="editedItem.examDate"
                    :items="itemExamDate"
                    :rules="rules.examDateRules"
                    :label="$t(myName + '.examDate') + '*'"
                    @change="changeExamDate"
                    required
                    clearable
                    outlined
                    dense
                  >
                    <template slot="append-outer">
                      <v-menu right :disabled="!examDateDescriptionDisabled">
                        <template v-slot:activator="{ on, attrs }">
                          <mybtn
                            :disabled="!examDateDescriptionDisabled"
                            :bind="attrs"
                            :on="on"
                            iconname="mdi-information"
                            small
                            fab
                          ></mybtn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            {{ $t("TelcMember.examDateInfo") }}
                          </v-card-title>
                          <v-card-text>
                            <v-row no-gutters>
                              {{ $t("TelcMember.dateWritingExam") }} :
                              <p class="font-weight-black">
                                {{
                                  $moment(examDateDescription.value).format("DD.MM.YYYY")
                                }}
                              </p>
                            </v-row>
                            <v-row no-gutters>
                              {{ $t("TelcMember.dateSpeakingExam") }}:
                              <p class="font-weight-black">
                                {{
                                  $moment(examDateDescription.speakingExamData).format(
                                    "DD.MM.YYYY"
                                  )
                                }}
                              </p>
                            </v-row>
                            <v-row no-gutters>
                              {{ $t("TelcMember.dateRegistrationDeadlineExam") }}:
                              <p class="font-weight-black">
                                {{
                                  $moment(
                                    examDateDescription.registrationDeadline
                                  ).format("DD.MM.YYYY")
                                }}
                              </p>
                            </v-row>
                            <!-- <v-row no-gutters>
                              {{ $t("TelcMember.dateLastRegistrationDeadline") }}:
                              <p class="font-weight-black">
                                {{
                                  $moment(
                                    examDateDescription.lastRegistrationDeadline
                                  ).format("DD.MM.YYYY")
                                }}
                              </p>
                            </v-row> -->
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row class="my-0 py-0" no-gutters>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===examPrice -->
                  <div class="text-h6 font-weight-bold">
                    <p>{{ examPrice }}</p>
                  </div>
                  <!-- <p class="font-weight-black">
                  {{examPrice}}
                  </p>-->
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===partExam -->
                  <v-checkbox
                    v-model="partExam"
                    :label="$t(myName + '.partExam')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-card v-if="partExam">
                <v-card-title class="headline">{{
                  $t(myName + ".partExamExplain")
                }}</v-card-title>
                <v-card-text>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <!--===partExam -->
                      <v-radio-group
                        class="my-2 py-0"
                        v-model="editedItem.partExam"
                        :mandatory="false"
                        :rules="rules.partExamRules"
                        row
                      >
                        <v-radio
                          :label="$t(myName + '.orally')"
                          value="speaking"
                        ></v-radio>
                        <v-radio
                          :label="$t(myName + '.written')"
                          value="writing"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                      <!--===lastMemberNr -->
                      <mytextfield
                        class="my-0 py-0"
                        v-model="editedItem.lastMemberNr"
                        :label="$t(myName + '.lastMemberNr')"
                        :rules="rules.lastMemberNrRules"
                      ></mytextfield>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <!--   personalData card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{
              $t(myName + ".personalData")
            }}</v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===gender -->
                  <v-radio-group
                    v-model="editedItem.gender"
                    :mandatory="false"
                    :rules="rules.genderRules"
                    row
                  >
                    <v-radio :label="$t(myName + '.male')" value="male"></v-radio>
                    <v-radio :label="$t(myName + '.female')" value="female"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===title -->
                  <v-select
                    v-model="editedItem.title"
                    :items="titleItems"
                    :label="$t(myName + '.title')"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===firstName -->
                  <mytextfield
                    v-model="editedItem.firstName"
                    :rules="rules.firstNameRules"
                    :label="$t(myName + '.firstName')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===lastName -->
                  <mytextfield
                    v-model="editedItem.lastName"
                    :rules="rules.lastNameRules"
                    :label="$t(myName + '.lastName')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===mobile -->
                  <mytextfield
                    v-model="editedItem.mobile"
                    :rules="rules.mobileRules"
                    :label="$t(myName + '.mobile')"
                    @keypress="isNumber"
                    @paste="onPaste"
                    prepend-inner-icon="mdi-cellphone-basic"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===email -->
                  <mytextfield
                    v-model="editedItem.email"
                    :rules="rules.EmailRules"
                    :label="$t(myName + '.email')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthday -->
                  <mydatepicker
                    class="mx-0 px-0"
                    v-model="editedItem.birthday"
                    :label="$t(myName + '.birthday')"
                    min="1950-01-01"
                    :max="maxBirthday"
                    :rules="rules.BirthdayRules"
                    clearable
                    outlined
                  ></mydatepicker>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===nativeLanguage -->
                  <mytextfield
                    v-model="editedItem.nativeLanguage"
                    :rules="rules.nativeLanguageRules"
                    :label="$t(myName + '.nativeLanguage')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthCountry -->
                  <v-combobox
                    :items="states"
                    v-model="editedItem.birthCountry"
                    :rules="rules.countryRules"
                    :label="$t(myName + '.birthCountry') + '*'"
                    prepend-inner-icon="mdi-city"
                    clearable
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===birthCity -->
                  <mytextfield
                    v-model="editedItem.birthCity"
                    :rules="rules.placeRules"
                    :label="$t(myName + '.birthCity')"
                  ></mytextfield>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--  address card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t("address") }}</v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===streetNr -->
                  <mytextfield
                    v-model="editedItem.streetNr"
                    :rules="rules.streetNrRules"
                    :label="$t(myName + '.streetNr')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===co -->
                  <mytextfield
                    v-model="editedItem.co"
                    :label="$t(myName + '.co')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===postCode -->
                  <mytextfield
                    v-model="editedItem.postCode"
                    :rules="rules.postCodeRules"
                    :label="$t(myName + '.postCode')"
                  ></mytextfield>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===place -->
                  <mytextfield
                    v-model="editedItem.place"
                    :rules="rules.placeRules"
                    :label="$t(myName + '.place')"
                  ></mytextfield>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===country -->
                  <v-combobox
                    :items="states"
                    v-model="editedItem.country"
                    :rules="rules.countryRules"
                    :label="$t(myName + '.country') + '*'"
                    prepend-inner-icon="mdi-city"
                    outlined
                    clearable
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0"></v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!--  others card-->
          <v-card class="mb-2 px-0" elevation="10">
            <v-card-title class="headline">{{ $t(myName + ".others") }}</v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===accommodationRequest -->
                  <v-select
                    v-model="editedItem.accommodationRequest"
                    :items="itemAccommodationRequest"
                    :label="$t(myName + '.accommodationRequest')"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6" class="my-0 py-0">
                  <!--===newsletterSubscribe -->
                  <v-select
                    v-model="editedItem.newsletterSubscribe"
                    :items="itemNewsletterSubscribe"
                    :label="$t(myName + '.newsletterSubscribe')"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-checkbox
                v-model="checkboxAGB"
                :rules="rules.checkboxAGB_Rules"
                required
                dense
              >
                <span slot="label">
                  {{ $t(myName + ".AGB1") }}
                  <a
                    href="https://www.diwan-marburg.de/allgemeine-geschaeftsbedingungen/"
                    onclick="window.open(this.href,'_blank', 'resizable,scrollbars').focus(); return false;"
                    >{{ $t(myName + ".AGB") }}</a
                  >
                  {{ $t(myName + ".AGB2") }}
                </span>
              </v-checkbox>
              <v-checkbox
                v-model="checkboxDSE"
                :rules="rules.checkboxDSE_Rules"
                required
                dense
              >
                <span slot="label">
                  {{ $t(myName + ".privacyPolicy1") }}
                  <a
                    href="https://www.diwan-marburg.de/datenschutzerklaerung/"
                    onclick="window.open(this.href,'_blank', 'resizable,scrollbars').focus(); return false;"
                    >{{ $t(myName + ".privacyPolicy") }}</a
                  >
                  {{ $t(myName + ".privacyPolicy2") }}
                </span>
              </v-checkbox>
              <v-card class="mb-2 px-0">
                <v-card-text>
                  <v-row class="my-0 py-0">
                    <v-col cols="12" xs="12" sm="12" class="my-0 py-0">
                      <!--===captcha -->
                      <mycaptcha
                        :refresh="refreshCaptcha"
                        :rules="rules.captchaRuls"
                      ></mycaptcha>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row class="my-0 py-0">
                <v-col cols="12" xs="12" class="my-0 py-0">
                  <mytextarea
                    v-model="editedItem.description"
                    :label="$t(myName + '.description')"
                  ></mytextarea>
                </v-col>
              </v-row>
              <v-row>
                <!--      buttons-->
                <mysavebtn
                  :disabled="!valid"
                  @submit="submit"
                  @clear="clear"
                  :savetext="$t(myName + '.register')"
                  :savetooltiptext="$t(myName + '.register')"
                  :cleartext="$t(myName + '.reset')"
                  :cleartooltiptext="$t(myName + '.reset')"
                ></mysavebtn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>

    <v-row justify="center">
      <v-dialog v-model="dialogExamOrt" persistent max-width="500">
        <v-card height="200">
          <v-card-title class="text-h5">
            {{ $t(myName + ".examLocationTextCard") }}
          </v-card-title>
          <v-card-text>
            <v-row class="mt-3 mx-3">
              <v-radio-group
                class="my-2 py-0"
                v-model="editedItem.examLocation"
                :mandatory="false"
                :rules="rules.examLocationRules"
                row
                @change="changeExamLocation()"
              >
                <v-radio label="Marburg" value="Marburg"></v-radio>
                <v-radio label="Bonn" value="Bonn"></v-radio>
              </v-radio-group>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <mybtn
              ref="submitExamLocation"
              :disabled="!editedItem.examLocation"
              color="green darken-1"
              :label="$t('agree')"
              @click="dialogExamOrt = false"
              @keydown="test()"
            ></mybtn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <mywarningdialog
      v-model="warningDialog"
      :text="warningText"
      @ok="warningOk"
      persistent
    ></mywarningdialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TelcRegisterForm",
  components: {
    // date_picker,
  },
  data() {
    return {
      refreshCaptcha: true,
      myName: "TelcMember",
      valid: true,
      checkboxAGB: false,
      checkboxDSE: false,
      partExam: false,
      titleItems: ["Prof.", "Dr.", "-"],
      itemAccommodationRequest: [
        "-",
        "WG-Zimmer",
        "Einzelappartement",
        "Einzimmerwohnung",
        "Zweizimmerwohnung",
        "Dreizimmerwohnung",
      ],
      itemNewsletterSubscribe: ["ja", "nein", "-"],

      states: [
        "Afghanistan",
        "Ägypten",
        "Albanien",
        "Algerien",
        "Andorra",
        "Angola",
        "Antarktis",
        "Antigua und Barbuda",
        "Äquatorialguinea",
        "Argentinien",
        "Armenien",
        "Aserbaidschan",
        "Äthiopien",
        "Australien",
        "Bahamas",
        "Bahrain",
        "Bangladesch",
        "Barbados",
        "Belgien",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivien",
        "Bosnien und Herzegowina",
        "Botsuana",
        "Brasilien",
        "Brunei",
        "Bulgarien",
        "Burkina Faso",
        "Birma",
        "Burundi",
        "Chile",
        "China",
        "Cookinseln",
        "Costa Rica (cta)",
        "Dänemark",
        "Deutschland",
        "Dominica",
        "Dominikanische Republik",
        "Dschibuti",
        "Ecuador",
        "Elfenbeinküste",
        "El Salvador",
        "Eritrea",
        "Estland",
        "Falklandinseln",
        "Fidschi",
        "Finnland",
        "Föderierte Staaten von Mikronesien",
        "Frankreich",
        "Französisch Guayana",
        "Gabun",
        "Gambia",
        "Georgien",
        "Ghana",
        "Grenada",
        "Griechenland",
        "Großbritannien",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Indien",
        "Indonesien",
        "Irak",
        "Iran",
        "Irland",
        "Island",
        "Israel",
        "Italien",
        "Jamaika",
        "Japan",
        "Jemen",
        "Jordanien",
        "Kambodscha",
        "Kamerun",
        "Kanada",
        "Kap Verde",
        "Kasachstan",
        "Katar",
        "Kenia",
        "Kirgisistan",
        "Kiribati",
        "Kolumbien",
        "Komoren",
        "Kongo (Demokratische Republik)",
        "Kongo (Republik) Kosovo",
        "Kroatien",
        "Kuba",
        "Kuwait",
        "Laos",
        "Lesotho",
        "Lettland",
        "Libanon",
        "Liberia",
        "Libyen",
        "Liechtenstein",
        "Litauen",
        "Luxemburg",
        "Madagaskar",
        "Malawi",
        "Malaysia",
        "Malediven",
        "Mali",
        "Malta",
        "Marokko",
        "Marshallinseln",
        "Mauretanien",
        "Mauritius",
        "Mazedonien",
        "Mexiko",
        "(Föderierte Staaten von) Mikronesien",
        "Moldawien",
        "Monaco",
        "Mongolei",
        "Montenegro",
        "Mosambik",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Neuseeland",
        "Nicaragua",
        "Niederlande",
        "Niger",
        "Nigeria",
        "Nordkorea",
        "Nordzypern",
        "Nowegen",
        "Oman",
        "Österreich",
        "Pakistan",
        "Palau",
        "Palästina",
        "Panama",
        "Papua-Neuguinea",
        "Paraguay",
        "Peru",
        "Philippinen",
        "Polen",
        "Portugal",
        "Ruanda",
        "Rumänien",
        "Russland",
        "Saint Kitts und Nevis",
        "Saint Lucia",
        "Saint Vincent und die Grenadinen",
        "Salomonen",
        "Sambia",
        "Samoa",
        "San Marino",
        "São Tomé und Príncipe",
        "Saudi-Arabien",
        "Senegal",
        "Serbien",
        "Seychellen",
        "Sierra Leone",
        "Singapur",
        "Simbabwe",
        "Slowakei",
        "Slowenien",
        "Somalia",
        "Spanien",
        "Sri Lanka",
        "Südafrika",
        "Sudan",
        "Südsudan",
        "Südkorea",
        "Surinam",
        "Svalbard",
        "Swasiland",
        "Schweden",
        "Schweiz",
        "Syrien",
        "Tadschikistan",
        "Taiwan",
        "Tansania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad und Tobago",
        "Tschad",
        "Tschechien",
        "Tunesien",
        "Türkei",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "Ungarn",
        "Uruguay",
        "Usbekistan",
        "Vanuatu",
        "Vatikan",
        "Venezuela",
        "Vereinigte Arabische Emirate",
        "Vereinigtes Königreich",
        "Vereinigte Staaten von Amerika",
        "Vietnam",
        "Weißrussland",
        "Westsahara",
        "Zentralafrikanische Republik",
        "Zypern",
      ],

      warningDialog: false,
      warningMode: "ok", //ok, error
      warningText: "",
      examDescription: "",
      examDescriptionDisabled: false,
      itemExamDate: [],
      examDateDescriptionDisabled: false,
      examDateDescription: [],
      examPrice: "",

      dialogExamOrt: false,

      // showAlertAfterSubmit: false,
    };
  },
  computed: {
    ...mapGetters({
      examTypes: "ExamType/getItems",
      examDates: "ExamDate/getItems",
      formActive: "Language/getFormActive",
    }),

    editedIndex() {
      return this.$store.getters[`${this.myName}/getEditedIndex`];
    },

    editedItem() {
      return this.$store.getters[`${this.myName}/getEditedItem`];
    },

    rules() {
      let rules = {
        genderRules: [(v) => !!v || this.$t(this.myName + ".rules.genderRules")],
        examLocationRules: [
          (v) => !!v || this.$t(this.myName + ".rules.examLocationRules"),
        ],
        firstNameRules: [
          (v) => !!v || this.$t(this.myName + ".rules.firstNameRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.firstNameRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.firstNameRules2"),
        ],
        lastNameRules: [
          (v) => !!v || this.$t(this.myName + ".rules.lastNameRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.lastNameRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.lastNameRules2"),
        ],
        EmailRules: [
          (v) => !!v || this.$t(this.myName + ".rules.EmailRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.EmailRules1"),
          (v) =>
            /^[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*@[a-zA-Z0-9äöüÄÖÜß]+([.\-_]?[a-zA-Z0-9äöüÄÖÜß]+)*(\.[a-zA-Z0-9äöüÄÖÜß]{2,3})+$/.test(
              v
            ) || this.$t(this.myName + ".rules.EmailRules2"),
        ],
        BirthdayRules: [(v) => !!v || this.$t(this.myName + ".rules.BirthdayRules")],
        mobileRules: [
          (v) => !!v || this.$t(this.myName + ".rules.mobileRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.mobileRules1"),
          (v) => /^\+?[0-9]*$/.test(v) || this.$t(this.myName + ".rules.mobileRules3"),
          (v) => (v && v.length <= 20) || this.$t(this.myName + ".rules.mobileRules2"),
        ],
        streetNrRules: [
          (v) => !!v || this.$t(this.myName + ".rules.streetNrRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.streetNrRules1"),
          (v) => (v && v.length <= 70) || this.$t(this.myName + ".rules.streetNrRules2"),
          // v => /^[a-zA-ZäöüÄÖÜß]+([.-]?[a-zA-ZäöüÄÖÜß]+)*[/.,]?\s{1,3}\d{1,5}(\s{1,2})?$/.test(v) || this.$t(this.myName +'.rules.streetNrRules3'),
        ],
        postCodeRules: [
          (v) => !!v || this.$t(this.myName + ".rules.postCodeRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.postCodeRules1"),
          (v) => (v && v.length <= 10) || this.$t(this.myName + ".rules.postCodeRules2"),
        ],
        placeRules: [
          (v) => !!v || this.$t(this.myName + ".rules.placeRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.placeRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.placeRules2"),
        ],
        countryRules: [
          (v) => !!v || this.$t(this.myName + ".rules.countryRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.countryRules1"),
          (v) => (v && v.length <= 50) || this.$t(this.myName + ".rules.countryRules2"),
        ],
        examDateRules: [
          (v) => !!v || this.$t(this.myName + ".rules.examDateRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.examDateRules1"),
          (v) => (v && v.length <= 10) || this.$t(this.myName + ".rules.examDateRules2"),
        ],
        nativeLanguageRules: [
          (v) => !!v || this.$t(this.myName + ".rules.nativeLanguageRules"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.nativeLanguageRules"),
        ],
        examTypeRules: [(v) => !!v || this.$t(this.myName + ".rules.examTypeRules")],
        lastMemberNrRules: [
          (v) => !!v || this.$t(this.myName + ".rules.lastMemberNrRules1"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.lastMemberNrRules1"),
          (v) =>
            (v && v.length <= 50) || this.$t(this.myName + ".rules.lastMemberNrRules2"),
        ],
        partExamRules: [(v) => !!v || this.$t(this.myName + ".rules.partExamRules")],
        checkboxAGB_Rules: [
          (v) => !!v || this.$t(this.myName + ".rules.checkboxAGB_Rules"),
        ],
        checkboxDSE_Rules: [
          (v) => !!v || this.$t(this.myName + ".rules.checkboxDSE_Rules"),
        ],
        captchaRuls: [
          (v) => !!v || this.$t("captcha.captchaText"),
          (v) => !/^\s*$/.test(v) || this.$t(this.myName + ".rules.captchaText"),
        ],
      };
      return this.formActive ? rules : {};
    },

    formatedItemsExamType() {
      let temp = this.$store.getters[`ExamType/formatedItems`];
      // let temp1 = temp.slice().sort((a, b) => a.text.localeCompare(b.text));
      let temp1 = temp.slice().sort((a, b) => b.text.localeCompare(a.text));
      // console.log(" ExamType", temp1);
      return temp1;
    },

    formatedItemExamDate() {
      return this.$store.getters[`ExamDate/formatedItems`];
    },

    maxBirthday() {
      return this.$moment(new Date()).subtract(3, "years").format("YYYY-MM-DD");
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.dialogExamOrt = true;
      this.editedItem.examLocation = "Marburg";
      if (this.examTypes.length === 0) {
        this.$store.dispatch("ExamType/selectItems");
      }
      if (this.examDates.length === 0) {
        this.$store.dispatch("ExamDate/selectItems");
      }
    },

    clear() {
      this.$refs.form.reset();
      // this.showAlert(false);
    },

    close() {
      this.$emit("change");
    },

    changeExamLocation() {
      this.examPrice = "";
      this.editedItem.examType = null;
      this.editedItem.examDate = null;
      // console.log(' refs ', this.$refs);
      this.$refs.submitExamLocation.$refs.ref.$el.onfocus = true;
    },

    submit() {
      // this.showAlertAfterSubmit = true;
      if (this.$refs.form.validate()) {
        this.editedItem.captcha = true;
        // this.showAlertAfterSubmit = false;
        this.valid = false;
        this.controlValues();
        this.$store
          .dispatch(`${this.myName}/saveForm`, this.editedItem)
          .then((res) => {
            // console.log(' res in telcRegisterForm in submit:' , res.data);
            if (res.data === "captchaError") {
              this.refreshCaptcha = !this.refreshCaptcha;
            } else if (res.data.indexOf("success")) {
              this.warningMode = "ok";
              this.warningModeChange();
            } else {
              // console.log(' log', res);
              this.warningMode = "error";
              this.warningModeChange();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$toast.error(this.$t(this.myName + ".alertMessage1"), {
          timeout: 10000,
        });
      }
    },

    controlValues() {
      if (this.editedItem.title == "-") {
        this.editedItem.title = "";
      }
    },

    warningModeChange() {
      if (this.warningMode === "ok") {
        if (this.editedItem.id > -1) {
          this.warningText = this.$t(this.myName + ".warningDialogUpdate");
        } else {
          this.warningText = this.$t(this.myName + ".warningDialogtext");
        }
      } else {
        this.warningText = this.$t(this.myName + ".warningDialogtextErorr");
      }
      this.warningDialog = true;
    },

    warningOk() {
      if (this.warningMode === "ok") {
        location.replace("http://diwan-marburg.de");
      }
      // else {
      //   this.clear();
      // }
    },

    changeExamType(val) {
      // console.log("changeExamType val  ", val);
      this.itemExamDate = [];
      if (typeof val !== "undefined") {
        let temp = this.formatedItemsExamType.find((obj) => {
          return obj.text === val;
        });
        // console.log("changeExamType temp  ", temp);
        // console.log("changeExamType gettype temp  ", typeof temp);

        if (typeof temp === "object") {
          let price = 0;
          if (this.editedItem.examLocation == "Marburg") {
            price = temp.data.priceMarburg;
          } else if (this.editedItem.examLocation == "Bonn") {
            price = temp.data.priceBonn;
          }

          if (price == 0) {
            this.examPrice = this.$t(this.myName + ".examPrice0Text");
          } else {
            this.examPrice = this.$t(this.myName + ".examPriceText") + price + "€";
          }
          this.examDescription = temp.description;
          this.examDescriptionDisabled = true;
        } else {
          this.examPrice = "";
          this.examDescriptionDisabled = false;
        }
        let new_date = this.$moment(this.$moment(), "YYYY-MM-DD").subtract(1, "days");
        this.itemExamDate = this.formatedItemExamDate.filter((x) => {
          let resultJson = this.isJson(x.examTypes);
          // console.log("each itemExam resultJson: ", resultJson);
          if (resultJson != false) {
            let tmpExamType = resultJson;
            let tmpFindExamType =
              typeof tmpExamType.find((y) => y === val) === "string" ? true : false;
            // console.log('x.data.examLocation :::  ', x.data.examLocation , ' ' , this.editedItem.examLocation );
            if (
              this.$moment(x.registrationDeadline).isAfter(new_date, "day") &&
              x.data.examLocation === this.editedItem.examLocation &&
              tmpFindExamType
            ) {
              return x;
            }
          }
        });
      } else {
        this.itemExamDate = [];
        this.examDescription = "";
        this.examDescriptionDisabled = false;
        this.examDateDescriptionDisabled = false;
        this.examPrice = "";
      }
      if (this.editedIndex === -1) {
        this.editedItem.examDate = "";
      }
    },

    changeExamDate(val) {
      if (typeof val !== "undefined") {
        this.examDateDescriptionDisabled = true;
        let temp = this.formatedItemExamDate.find((obj) => {
          return obj.value === val;
        });
        this.examDateDescription = temp;
      } else {
        this.examDateDescriptionDisabled = false;
      }
    },

    // showAlert(show) {
    //   let snackbarObj = {
    //     text: this.$t(this.myName + '.alertMessage1'),
    //     color: "red",
    //     timeout: -1,
    //     alertShow: show,
    //   };
    //   this.$store.dispatch('MyAlert/setAlert', snackbarObj);
    // },

    onPaste(evt) {
      let temp = evt.clipboardData.getData("text");

      let re = /^\+?[0-9]*$/gi;
      if (!temp.match(re)) {
        evt.preventDefault();
      }
    },

    // isNumber(evt) {
    //   evt = evt ? evt : window.event;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    //   if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 43) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
    test() {
      console.log(" test ");
    },
  },

  watch: {
    partExam() {
      if (this.partExam === true) {
        this.editedItem.partExam = "speaking";
      } else {
        this.editedItem.partExam = "";
      }
    },

    editedIndex() {
      if (this.editedIndex === -1) {
        this.clear();
      }
    },

    // valid() {
    //   if (this.valid == false && this.showAlertAfterSubmit) {
    //     this.showAlert(true);
    //   } else {
    //     this.showAlert(false);
    //   }
    // },

    // "editedItem.examDate" () {
    //   console.log(' editedItem.examDate',this.editedItem.examDate);
    // },

    // "editedItem.birthday"() {editedIndex
    //   // console.log(' birthday:',this.editedItem.birthday);
    // },
    // "editedItem.examLocation"() {
    //   console.log('examLocation : ', this.editedItem.examLocation);
    // },
  },
};
</script>

<style scoped></style>
