// import Vue from "vue";
function importAll(r) {
    return r.keys().map(r);
}
const fl = importAll(require.context('../../../src/components/globalComponents', false, /\.(vue)$/));

// import MyAlert from "./components/globalComponents/MyAlert";
const tmpComponents = [
    // {name: 'myalert', value: MyAlert},
];

function getFiles() {
    let res = tmpComponents;
    for (let i = 0; i < fl.length; i++) {
        if (JSON.stringify(fl[i]) !== '{ }') {
            if (fl[i].default.name != undefined) {
                if (fl[i].default.name.length > 0) {
                    res.push({ name: fl[i].default.name.toLowerCase(), value: fl[i].default })
                }
            }
        }
    }
    return res;
}

const myComponents = getFiles();

export default myComponents;
