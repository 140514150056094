<template>
  <v-container>
    <v-select
      v-model="selected"
      :items="languages"
      item-text="title"
      item-value="value"
      :label="label"
      outlined
      @change="changeLanguage(selected)"
    ></v-select>
  </v-container>
</template>

<script>
// :label="$t('language_picker_helper')"
import { mapGetters } from "vuex";
export default {
  name: "LanguagePicker",
  data() {
    return {
      selected: "",
      title: "",
      label: "",
    };
  },
  computed: {
    ...mapGetters({
      languages: "Language/getLanguagesTitel",
      formActive: "Language/getFormActive",
    }),
    // lebel() {
    //   let languageTiltel = this.$t("languageTitel");
    //   return languageTiltel;
    // },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // console.log(" Language picker ::::::::");
      this.getLanguageTitel();
      let lang = localStorage.getItem("language");

      if (typeof lang != "string") {
        lang = "de";
      }
      this.changeLanguage(lang);
    },

    getLanguageTitel() {
      this.$store.dispatch("Language/getLanguageTitel");
    },

    changeLanguage(lang) {
      // console.log(' lang in piecker : ' , lang);

      localStorage.setItem("language", lang);
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      this.$store.dispatch("Language/setLanguage", lang);
      // this.label = this.$t("languageTitel");
    },
  },

  watch: {
    formActive() {
      if (!this.formActive) {
        this.$nextTick(() => {
          this.$store.dispatch("Language/setFormActive", true);
          this.label = this.$t("languageTitel");
        });
      }
    },
  },
};
</script>

<style scoped></style>

<!-- add in app.vue -->
<!--import LanguagePicker from "./res/language/component/LanguagePicker";-->

<!--<LanguagePicker class="mr-1" style="maxWidth: 150px;"/>-->

<!--components: {-->
<!--LanguagePicker-->
