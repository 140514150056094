<template>
    <v-container>
     <v-footer color="primary" app>
      <v-row justify="space-between" no-gutters>
        <v-col>
          <span class="white--text">&copy; Diwan-Marburg Akademie GmbH</span>
        </v-col>
        
      </v-row>
    </v-footer>
</v-container>
</template>

<script>
 export default {
    name: "myFooter",
 }

</script>