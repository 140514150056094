import PHPServer from '../../res/services/postToPHPServer';
import router from '../../res/router';
import Helper from "../../res/js/MyHelper";

const myName = 'Login';

const state = {
  name: 'Login',
  items: [],
  editedItem: {},
  userObject: "",
  token: "",
  isLogin: false,
  state: '',
};

const getters = {
  getItems: state => state.items,
  getEditedItem: state => state.editedItem,
  getUserObject: state => state.userObject,
  getToken: state => state.token,
  getIsLogin: state => state.isLogin,
  getUser: state => state.userObject,

};

const actions = {//dispatch
  login({ dispatch }, dataj) {
    // const formData = new FormData();
    // formData.append('command', "login");
    // formData.append('user', dataj.user);
    // formData.append('password', dataj.password);
    const formData = PHPServer.fillFormatData("login", dataj);
    return new Promise((resolve, reject) => {
      PHPServer.send(formData)
        .then(res => {
          // console.log('res login', res.data.token);
          dispatch('setLocalStorage', res.data);
          resolve(res);
        }).catch(err => {
          reject(err);
        })
    });
  },

  loginVerify({ dispatch }) {
    const formData = new FormData();
    formData.append('command', "loginVerify");
    return new Promise((resolve, reject) => {
      PHPServer.send(formData)
        .then(res => {
          // console.log(' res loginVerify in login store', res.data);
          dispatch('setLocalStorage', res.data);
          if (!res.data.isLogin) {
            router.push({ path: "register" });
            reject(false);
          } else {
            resolve(true);
          }
        });
    });
  },

  logout({ dispatch }) {

    Helper.leerAllTablesItems();

    let data = {
      userObject: "",
      token: "",
      isLogin: "",
      access: "",
      expirationTime: "",
      expirationPeriod: "",
      // newTime:'', 
    };
    dispatch('setLocalStorage', data);
  },

  setLocalStorage({ state }, data) {
    // localStorage.clear();
    // console.log(' setLocalStorage : ', data);
    if (typeof data != 'string') {
      for (let x in data) {
        if (x === 'userObject') {
          if (typeof data[x] == 'string') {
            localStorage.setItem(x, data[x]);
            try {
              let tempX = JSON.parse(data[x]);
              state[x] = (tempX);
            } catch {
              state[x] = "";
              // console.log(exeption);
            }
          }
        } else {
          localStorage.setItem(x, data[x]);
          state[x] = (data[x]);
        }
      }
    }
  },

  forgotPassword({ state }, dataj) {
    state.state = '';
    // console.log(' loginStore forgotPassword ', dataj);
    const formData = PHPServer.fillFormatData('forgotPassword', dataj);
    return PHPServer.send(formData);
  },

  newPassword({ state }, dataj) {
    state.state = '';
    const formData = PHPServer.fillFormatData('newPassword', dataj);
    return PHPServer.send(formData);
  },

};

const mutations = {//commit

};

export default {
  myName,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};


