<template>
<div>
  <v-row no-gutters>
    <!-- <v-col> -->
      <mybtn @click="onClick()" iconname="mdi-pencil" small></mybtn>
      <!-- <span style="padding-left: 4px;"> -->
    <!-- </v-col> -->
    <!-- <v-col> -->
      {{displayValue}}
      <!-- </v-col> -->
  </v-row>
  </div>
</template>
<script>
export default {
  name: "cellRenderer",

  data: () => ({
    myName: "cellRenderer",
    displayValue: "",
  }),

  created() {
    this.initialize();
  },
  beforeMount() {
    this.displayValue = this.params.value;
  },

  methods: {
    initialize() {},
    onClick() {
      this.params.api.startEditingCell({
        rowIndex: this.params.rowIndex,
        colKey: this.params.column.getId(),
      });
    },
  },
};
</script>
