const myName = "MySnackbar";

const state = {
    name: "MySnackbar",
    text: "Hello, I'm a snackbar in store",
    type: "",
    color: "primary",
    timeout: -1,
    snackbar: false,
    answer: -1,
};

const getters = {
    getText: (state) => state.text,
    getType: (state) => state.type,
    getColor: (state) => state.color,
    getTimeout: (state) => state.timeout,
    getSnackbar: (state) => state.snackbar,
    getAnswer: (state) => state.answer,
};

const actions = {
    //dispatch
    setShowSnackbar({ state }, dataj = true) {
        state.snackbar = dataj;
    },

    setSnackbar({ state }, dataj) {
        state.text = dataj.text;
        state.type = dataj.type;
        state.color = dataj.color;
        state.timeout = dataj.timeout;
        state.snackbar = dataj.snackbar;
    },

    setAnswer({ state }, dataj = -1) {
        state.answer = dataj;
    }

};

const mutations = {
    //commit
};

export default {
    myName,
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
