<template>
  <mydatatable
    :name="myName"
    :savedata="saveData"
    show-add-btn
    show-edit-btn
    show-delete-btn
    show-excel-btn
    show-group-delete-btn
  >
    <examTypeSave @change="save"></examTypeSave>
  </mydatatable>
</template>

<script>
import examTypeSave from "../examType/ExamTypeSave";

export default {
  name: "ExamType",
  meta: {
    requiresAuth: true,
    is_access: true,
  },
  components: {
    examTypeSave,
  },
  data: () => ({
    myName: "ExamType",
    saveData: false,
  }),

  computed: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch(`${this.myName}/getVueTable`);
    },
    save() {
      this.saveData = !this.saveData;
    },
    // myBtnAction(val) {
    //   console.log(' val ' , val);
    //   let btn = [];
    //   let temp = {
    //     iconName: "mdi-pencil",
    //     color: "green accent-3",
    //     textValue: "edit",
    //     method: () => {
    //       let id =  this.$store.getters[`${this.myName}/getEditedIndex`];
    //       console.log("  test myBtnaction", id);
    //     },
    //   }
    //   btn.push(temp);

    //   return btn;
    // },
  },

  watch: {},
};
</script>

<style scoped></style>
