// eslint-disable 
/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import cls_tileModule from "./utils/cls_tileModule";
import PHPServer from '../res/services/postToPHPServer';
import Helper from '../res/js/MyHelper';


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},

  mutations: {},

  actions: {
    myRegisterModule({state},tableName) { 

      // console.log(' table name : ' , tableName);
      // let key = tableName.substring(4);
      let key = tableName;
      let value = new cls_tileModule(key, '_' + tableName);
      registerModule(key, value);
    },
    
  },
  // modules: myModules,
  modules: {},

  getters: {
    status: state => state.status
  }
});

function registerModule(key, value) {
  
  let tempAllModules = Object.keys(store.state);
  if (tempAllModules.length > 0) {
    let findModule = tempAllModules.indexOf(key);
    if (findModule == -1) {
      store.registerModule(key, value)
    }
  } else {
    store.registerModule(key, value)
  }
  // console.log('module of store ' , Object.keys(store.state));
}

function importAll(r) {
  return r.keys().map(r);
}

const flmyModules = importAll(require.context('./myModules', false, /\.(js)$/));

let allModules = {
  // language
}

let mytables = [
  { name: "TriggerExamType", tablesName: "tbl_exam_type_trigger" },
  { name: "Users", tablesName: "tbl_users" },
  { name: "Session", tablesName: "tbl_session" },
  { name: "StartEndWork", tablesName: "tbl_start_end_work" },
  // { name: "Tables", tablesName: "tbl_tables" },
];

function getTablesFromTileModule(res = []) {
  for (let i = 0; i < mytables.length; i++) {
    let key = mytables[i].name;
    let value = new cls_tileModule(mytables[i].name, '_' + mytables[i].tablesName);
    registerModule(key, value)
    res[key] = value;
  }
  for (let i = 0; i < flmyModules.length; i++) {
    if (JSON.stringify(flmyModules[i]) !== '{}') {
      let tempFile = flmyModules[i].default;
      if (tempFile.myName != undefined) {
        if (tempFile.myName.length > 0) {
          let tempFile = flmyModules[i].default;
          let key = tempFile.myName;
          let value = new cls_tileModule(tempFile.myName, '_' + tempFile.myTableName, tempFile);
          registerModule(key, value)
          mytables.push({ name: key, tablesName: tempFile.myTableName });
          res[key] = value;
        }
      }
    }
  }
  return res;
}

getTablesFromTileModule();

const fl = importAll(require.context('./modules', false, /\.(js)$/));

function getFiles() {
  let res = [];
  for (let i = 0; i < fl.length; i++) {
    if (JSON.stringify(fl[i]) !== '{}') {
      let item = fl[i].default;
      if (item.myName != undefined) {
        if (item.myName.length > 0) {
          let key = item.myName;
          let value = item;
          registerModule(key, value)
          res[key] = value;
        }
      }
    }
  }
}

getFiles();

async function getAllTablesFromServer() {
  let data = {
    myFunctionName: 'getMyAllTables',
  }
  let tempRes = await PHPServer.myFunction('_tbl_AllTables', data);
  let myData = tempRes.data;
  for (let i = 0; i < myData.length; i++) {
    let mySearch = Helper.searchInArrayOfObject(mytables, myData[i]);
    if (mySearch.length == 0) {
      let key = myData[i].substring(4);
      let value = new cls_tileModule(key, '_' + myData[i]);
      registerModule(key, value)
    }
  }
  return mytables;
}

// getAllTablesFromServer();


export default store;
