<template>
   <span>
     <mybtn
       @click="buttonClicked('save')"
       :label="$t('save')"
       :color="color"
       :iconname="iconName"
     ></mybtn>
    </span>
</template>

<script>
  export default {
    name: "totalValueRenderer",
    data() {
      return {
        cellValue: null,
        iconName: '',
        color: "",
      }
    },
    beforeMount() {
      // this.params contains the cell & row information and is made available to this component at creation time
      // see ICellRendererParams below for more details
      this.cellValue = this.getValueToDisplay(this.params);
      this.iconName = this.params.iconName;
      this.color = this.params.color;
    },
    methods: {
      // gets called whenever the user gets the cell to refresh
      refresh(params) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
      },

      // buttonClicked(val) {
      buttonClicked() {
        // console.log('cellValue:',`${this.cellValue} `);
        // console.log(val);
        this.$emit(this.cellValue);
      },

      getValueToDisplay(params) {
        return params.valueFormatted ? params.valueFormatted : params.value;
      },
    },
  }
</script>

<style scoped>

</style>