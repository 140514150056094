<template>
  <div>
    <v-text-field
      ref="textField"
      v-model="myValue"
      :rules="rules"
      :label="rules == '' ? label : label + '*'"
      :hint="hint"
      :placeholder="placeholder"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-inner-icon="prependInnerIcon"
      :outlined="outlined"
      :clearable="clearable"
      :color="color"
      :autocomplete="autocomplete"
      :dense="dense"
      :disabled="disabled"
      :bind="bind"
      :on="on"
      :type="type"
      @change="change(myValue)"
      @input="change(myValue)"
      @keypress="keypress"
      @paste="paste"
      @click:append="clickAppend"
      @keyup.enter="keyupEnter"
      @click:append-outer="clickAppendOuter"
    >
    </v-text-field>
  </div>
</template>

<script>
// import Helper from '../../res/js/Helper';
export default {
  // name: "mytextfield",
  name: "MyTextField",

  data() {
    return {
      myValue: this.myValue1,
    };
  },

  props: {
    myValue1: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    appendOuterIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ifNumber: {
      type: [Boolean, String],
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    bind: {},
    on: {},
  },

  model: {
    prop: "myValue1",
    event: "change",
  },

  methods: {
    change(val) {
      this.$emit("change", val);
    },

    keypress() {
      if (this.ifNumber) {
        if (typeof this.ifNumber == "boolean") this.funNumber();
        else this.funNumber(this.ifNumber);
      }
      this.$emit("keypress");
    },

    paste(evt) {
      this.$emit("paste", evt);
    },

    funNumber(sc, evt) {
      // return Helper.isNumber(evt, sc);
      return this.isNumber(evt, sc);
    },

    clickAppend(evt) {
      this.$emit("clickAppend", evt);
    },

    clickAppendOuter(evt) {
      this.$emit("clickAppendOuter", evt);
    },

    keyupEnter(evt) {
      this.$emit("keyupEnter", evt);
    },
  },
  watch: {
    myValue1() {
      this.myValue = this.myValue1;
    },
  },
};
</script>

<style scoped></style>
